import { Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMessagingContext } from './Notification';

const classes  = {
  alert: {
    zIndex: 2000,
    position: 'fixed',
    top: '0',
    transition: 'fade',
    right:'0',
    width: 300
  }
};

export const AlertComponent = () => {
  const { alertState: { title, message, severity, onClose }, resetAlert } = useMessagingContext();
  // TODO some weird CSS preventing this from showing correctly but mechanisms are in place
  const [show, setShow] = useState(false);
  useEffect(() => {
    let shouldShow = false;
    if (title || message) {
      shouldShow = true;
      setTimeout(() => {
        resetAlert();
        setShow(false);
        onClose?.();
      }, 3000);
    }
    setShow(shouldShow);
  }, [title, message]);

  const alertTitle={
    fontSize: "1em",
    fontWeight: "bold"
  }
  return (
    <>
      {' '}
      {show && (
        <Alert 
          onClose={() => { 
            resetAlert(); 
            onClose?.(); 
          }}
          sx={classes.alert}
          severity={severity}
          variant="filled"
        >
          <AlertTitle style={alertTitle}>{title}</AlertTitle>
          {message}
        </Alert>
      )}
    </>
  )
}
