import React, { PropsWithChildren } from 'react';
import { Link } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';

interface props {
  href: string;
}

export const LinkInternal = ({ children, href, ...rest }: PropsWithChildren<props>) => (
  <Link color='primary' underline='always' component={RRLink} to={href} {...rest}>
    {children}
  </Link>
);
