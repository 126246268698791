import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';

export const Info = () => {
  const [value, setValue] = useState ('funder');

  function onChange (e, newValue) {
    setValue (newValue);
  }

  return (
    <Box maxWidth={1024} p='10px 4px' mx='auto'>
      <Tabs
        value={value}
        indicatorColor='primary'
        onChange={onChange}
        aria-label='How Justfund works for funders and organizations'
      >
        <Tab value='funder' label='For Funders' id='tabFunder' aria-controls='tabPanelFunder' />
        <Tab value='group' label='For Organizations' id='tabGroup' aria-controls='tabPanelGroup' />
      </Tabs>
      { value === 'funder' && <TabPanelFunder /> }
      { value === 'group' && <TabPanelGroup /> }
    </Box>
  );
};

const TabPanelFunder = () => (
  <Box id='tabPanelFunder' role='tabpanel' aria-labelledby='tabFunder' m={1} mt={2}>
    <Typography variant='h4'>How It Works for Funders</Typography>
    <Typography variant='body1' paragraph>
      JustFund allows funders to leverage their decisions and move resources more quickly to the field, while
      facilitating greater trust, transparency, and accountability across networks and between funders and
      organizers. For funders interested in resourcing groups on the front lines, finding innovative projects is
      hard. JustFund connects foundations and donors with critical projects around the country and provides funders
      with personal endorsements from other funding colleagues, as well as direct access to organizations, allowing
      funders to move resources confidently.
    </Typography>
    <Typography variant='h5'>Connect with Other Funders</Typography>
    <Typography variant='body1' paragraph>
      Funders can set their notification settings to receive emails on a daily or “as it comes” basis to follow the
      activity on proposals they are following, funding, or have endorsed. The community activity feature in every
      proposal allows funders to ask questions to each other and the organization. This feature connects funders,
      allowing them to find new colleagues who are interested in resourcing the same focus areas or projects!
      Funders can also create profile pages to promote their work, share information about their fund, identify
      potential collaborative partnerships, and help leverage their support to obtain additional funds for the
      organizations they fund.
    </Typography>
    <Typography variant='h5'>Find New, Exciting Proposals</Typography>
    <Typography variant='body1' paragraph>
      Whether you are an established foundation looking for new, exciting projects that fit in your existing
      portfolios, looking to build a rapid response fund, or you are a family foundation with limited staffing,
      JustFund will help you quickly find groups to resource, filtering down the pool of proposals to your specific
      interests.
    </Typography>
    <Typography variant='h5'>Influence Others to Move Resources</Typography>
    <Typography variant='body1' paragraph>
      JustFund is a great way for funders to share funding decisions with other foundations and donors to help
      facilitate additional resources for groups they are currently funding or have funded in the past. The
      community activity feature allows funders to start a dialogue between an organization and other funders using
      the portal. Funders can learn more about the organization’s work or share background with a funding colleague
      to help them decide to move resources to a particular group.
    </Typography>
    <Typography variant='h5'>Learn from the Expertise of Multiple Collaborative Funds</Typography>
    <Typography variant='body1' paragraph>
      The Emergent Fund, Defending the Dream Fund, Threshold Foundation, Solidaire, and others accept proposals for
      their various funds through the portal. All funders have access to the proposals these funds receive, the
      decisions they make, and the ability to learn from their experience, knowledge, and expertise on various
      issues and strategies.
    </Typography>
  </Box>
);

const TabPanelGroup = () => (
  <Box id='tabPanelGroup' role='tabpanel' aria-labelledby='tabGroup' m={1} mt={2}>
    <Typography variant='h4' m='20px 0 10px 0'>How it Works for Organizations</Typography>
    <Typography variant='body1' paragraph>
      JustFund provides a new proposal model for philanthropy that simplifies the application process for
      organizations. Groups can submit a single proposal and interact with a host of funders, providing those
      working on the front lines of social change greater access to networks and resources.
    </Typography>
    <Typography variant='h5'>Respect Organizations and their Leadership’s Time</Typography>
    <Typography variant='body1' paragraph>
      The portal operates using one “Common Proposal” that organizations fill out one time. The Common Proposal
      focuses on the core questions that funders need to make thoughtful funding decisions and respects
      organizations and their leadership’s time, reducing the number of different proposal forms they are expected
      to complete. Organizations can use the same proposal to apply to as many grant opportunities as they wish in
      JustFund.
    </Typography>
    <Typography variant='h5'>Increase Organization’s Visibility and Reach</Typography>
    <Typography variant='body1' paragraph>
      Proposals are not only viewed by the funds to which they apply, but are available to all funders on the
      portal. This saves time for organizations and increases the reach of each submitted proposal to new funders
      and networks. JustFund also extends the “shelf life” of each proposal, further expanding its reach.
    </Typography>
    <Typography variant='h5'>Collect Key Information in Order to Move Resources Quickly</Typography>
    <Typography variant='body1' paragraph>
      Organizations can create profiles that save their key information, allowing funders to quickly issue grants
      when they are ready to do so. Funders can review an organization’s EIN, point of contact, and information
      about where a check should go, including if it should be sent to a fiscal sponsor. This allows for resources
      to be moved quickly, avoiding the typical back and forth.
    </Typography>
    <Typography variant='h5'>Share updates and Connect with Funders</Typography>
    <Typography variant='body1' paragraph>
      Organizations can also use the community activity feature in their proposal to connect with existing or
      prospective funders. They can ask/answer questions, thank funders for their support, and post key updates
      about their proposal, including campaign/project progress, op-eds, and articles. This helps facilitate
      greater transparency and connectivity!
    </Typography>
  </Box>
);
