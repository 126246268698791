import React, { createContext, useContext, useState } from 'react';
import { useCancellableEffect } from '../hooks/useCancellableEffect';
import { IEntityFollow, IFollow } from '../interfaces/IFollow';
import { IUser } from '../interfaces/IUser.interface';
import { UserService } from '../services/api-service/user-api.service';

const FollowsContext = createContext({
  follows: [] as IFollow[],
  loading: false,
  followEntity: (_: IEntityFollow) => {},
  unfollowEntity: (_: IEntityFollow) => {},
  hydrate: (signal?: AbortSignal) => Promise.resolve(false)
});

interface IFollowsProviderProps {
  children: React.ReactNode | null;
  user?: IUser;
}

export const useFollowsContext = () => useContext(FollowsContext);

export function FollowsProvider({ children, user }: IFollowsProviderProps) {
  const [follows, setFollows] = useState<IFollow[]>([]);
  const [loading, setLoading] = useState(false);

  const hydrate = (signal?: AbortSignal) => {
    return UserService.getFollowing({ signal }).then((response) => {
      setFollows(response);
      return true;
    });
  };

  useCancellableEffect(
    (signal) => {
      if (user?.id) {
        setLoading(true);
        hydrate(signal)
          .catch((err) => {
            if (err.message !== 'canceled') return err;
          })
          .finally(() => setLoading(false));
      }
    },
    [user?.id]
  );

  const unfollowEntity = async (entity: IEntityFollow) => {
    setLoading(true);
    await UserService.unfollowEntity(entity);
    setFollows(
      follows.filter(
        (follow) =>
          !(
            follow.entity_id === entity.entity_id &&
            follow.entity_type === entity.entity_type
          )
      )
    );
    setLoading(false);
  };

  const followEntity = async (entity: IEntityFollow) => {
    setLoading(true);
    const follow = await UserService.followEntity(entity);
    setFollows([...follows, follow]);
    setLoading(false);
  };

  return (
    <FollowsContext.Provider
      value={{ follows, hydrate, followEntity, unfollowEntity, loading }}
    >
      {children}
    </FollowsContext.Provider>
  );
}
