import React from 'react';
import { Box, Link } from '@mui/material';
import { LinkExternal, LinkInternal } from '../../../shared/components/legacy-ui';

export const TermsOfUse = () => (
  <Box maxWidth={1024} mx='auto' pt='20px'>
    <h1>Terms of Use</h1>
    <p>Last Modified: September 1, 2020</p>

    <p>&nbsp;</p>

    <h2>1. Acceptance of the Terms of Use</h2>

    <p>&nbsp;</p>

    <p>Welcome to the website of <b>JustFund.us</b>,
      a project of Proteus Fund (“<b>Proteus</b>”, “<b>we</b>”, “<b>our</b>”
      or “<b>us</b>”). Proteus Fund,
      Inc. is a Massachusetts not for profit corporation with tax-exempt status under
      Section 501(c)(3) of the United States Internal Revenue Code. The following
      terms and conditions, together with any documents they expressly incorporate by
      reference (collectively, these “<b>Terms of Use</b>”), govern your access to
      and use of this portal, including any content, functionality and services
      offered on or through www.justfund.us and any applicable subdomains thereof,
      and through any applications, mobile applications, functionalities, content,
      materials, or other online services provided by us (the “<b>Portal</b>”).
    </p>

    <p>&nbsp;</p>

    <p>The Portal connects private
      foundations, donors, and collaborative funds (“<b>Funders</b>”) with critical
      projects around the United States (“<b>Proposals</b>”) (the “<b>Service</b>”).
      Proposals may be submitted by: 1) organizations that have been determined be to
      tax-exempt under an applicable provision of the Internal Revenue Code, as
      amended (the “<b>Code</b>”); 2) organizations that have not been determined be
      to tax-exempt under the Code, but may be fiscally sponsored by an organization
      that has been determined to be tax-exempt under the Code; or 3) projects that
      may otherwise qualify for charitable and/or educational funding. PROTEUS DOES
      NOT VERIFY THE EXEMPT STATUS OR GOOD STANDING OF PROPOSALS AND MAKES NO
      REPRESENTATION AS TO THE DEDUCTIBILITY OF CONTRIBUTIONS IN SUPPORT OF A
      PROPOSAL. FUNDERS MUST COMPLETE THEIR OWN DUE DILIGENCE WHEN DETERMINING
      WHETHER OR NOT TO PROCEED WITH FUNDING A PARTICULAR PROPOSAL. JUSTFUND.US DOES
      NOT TRANSMIT CONTRIBUTIONS BETWEEN FUNDERS AND PROPOSALS, AND FUNDERS MUST MAKE
      THEIR OWN INDEPENDENT ARRANGEMENTS TO FUND PROPOSALS. YOU AGREE THAT PROTEUS
      SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FROM THE FAILURE OF A FUNDER
      TO FUND A PROPOSAL FOR ANY REASON OR NO REASON AT ALL.
    </p>

    <p>&nbsp;</p>

    <p>Please read the Terms of Use
      carefully before you start to use the Portal.{' '}
    <b>By accessing or using the
        Service provided by or through the Portal, whether or not you become a member
        of the Service, you accept and agree to be bound and abide by these Terms of Use and
        our <LinkInternal href='/justfund/privacy'>Privacy Policy</LinkInternal> incorporated
        herein by reference.
    </b>{' '}
      If you do not want to agree to these Terms of Use or
      our Privacy Policy, you must not access or use the Portal.
    </p>

    <p>&nbsp;</p>

    <p>For purposes of this Agreement,
      the term “<b>Member</b>” means a Funder or Organizational Leader, which
      provides information to this Portal or to participate in the Service in any
      manner, whether such person uses the Service as a free member or as a paid
      Member of the Service.
    </p>

    <p>&nbsp;</p>

    <p>This Portal is offered and
      available to users who are 18 years of age or older and reside in the United
      States. By using this Portal, you represent and warrant that you are of legal
      age to form a binding contract with Proteus and meet all of the foregoing
      eligibility requirements. If you do not meet all of these requirements, you
      must not access or use the Portal.
    </p>

    <p>&nbsp;</p>

    <h2>2. Changes to the Terms of Use</h2>

    <p>&nbsp;</p>

    <p>We may revise and update these
      Terms of Use from time to time in our sole discretion. All changes are
      effective immediately when we post them, and apply to all access to and use of
      the Portal thereafter. Your continued use of the Portal following the posting
      of revised Terms of Use means that you accept and agree to the changes. You are
      encouraged to check this page each time you access this Portal so you are aware
      of any changes, as they are binding on you.
    </p>

    <p>&nbsp;</p>

    <h2>3. Accessing the Portal and Account Security</h2>

    <p>&nbsp;</p>

    <p>We reserve the right to withdraw
      or amend this Portal, and any service or material we provide on the Portal, in
      our sole discretion without notice. We will not be liable if for any reason all
      or any part of the Portal is unavailable at any time or for any period. From
      time to time, we may restrict access to some parts of the Portal or the entire
      Portal to users, including registered users.
    </p>

    <p>&nbsp;</p>

    <p>You are responsible for:</p>

    <ul>
      <li>Making all arrangements necessary for you to have access to the Portal.</li>
      <li>
        Ensuring that all persons who access the Portal through your
        internet connection are aware of these Terms of Use and comply with them.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Access to certain areas of the
      Portal is restricted. Proteus reserves the right to restrict access to areas
      of the Portal, or indeed the entire Portal, at its discretion. To access the
      Portal or some of the resources it offers, you may be asked to provide certain
      registration details or other information. It is a condition of your use of
      the Portal that all the information you provide on the Portal is correct,
      current and complete. You agree that all information you provide to register
      with this Portal or otherwise, including but not limited to through the use of
      any interactive features on the Portal, is governed by
      our <LinkInternal href='/justfund/privacy'>Privacy Policy</LinkInternal>,
      and you consent to all actions
      we take with respect to your information consistent with our Privacy Policy.
    </p>

    <p>&nbsp;</p>

    <p>
      If you choose, or are provided
      with, a username, password, or any other piece of information as part of our
      security procedures, you must treat such information as confidential, and you
      must not disclose it to any other person or entity. You also acknowledge that
      your account is personal to you and agree not to provide any other person with
      access to this Portal or portions of it using your username, password, or other
      security information. You agree to notify us immediately of any unauthorized
      access to or use of your username or password or any other breach of security.
      You also agree to ensure that you exit from your account at the end of each
      session. You should use particular caution when accessing your account from a
      public or shared computer so that others are not able to view or record your
      password or other personal information.
    </p>

    <p>&nbsp;</p>

    <p>
      We have the right to disable any
      username, password, or other identifier, whether chosen by you or provided by
      us, at any time in our sole discretion for any or no reason, including if, in
      our opinion, you have violated any provision of these Terms of Use.
    </p>

    <p>&nbsp;</p>

    <h2>4. Available Services</h2>

    <p>&nbsp;</p>

    <p>
      As a non-paying Member, you may
      access the Portal and use some, but not all, of the features and services
      available within the Portal. The features and services that may be available
      to you may be changed or eliminated at any time in Proteus’ sole and absolute
      discretion. Additional features and services may be made available to you upon
      becoming a paying Member to the Service. If you are not a paying Member, you
      acknowledge and agree that you will not be able to use all the features and
      services available within the Service. A Member profile may remain posted on
      the Portal even if that Member is not actively using the Service, but Proteus
      may remove any Member profile at any time in its sole and absolute discretion,
      with or without cause.
    </p>

    <p>&nbsp;</p>

    <p>
      So long as you maintain a
      fully-paid membership for the Services, Proteus will make the Services
      available to you pursuant to this Agreement on an as-available basis. The
      Services may be unavailable as a result of planned or emergency downtime and
      any unavailability caused by circumstances beyond Proteus’ reasonable
      control, including, including by way of example and without limitation, an act
      of God, act of government, flood, fire, earthquake, civil unrest, act of
      terror, strike or other labor problem, Internet service provider failure or
      delay or denial of service attack. Proteus reserves the right at any time to
      modify or discontinue the Service or any part thereof, temporarily or
      permanently, with or without notice. You agree that Proteus shall not be
      liable to you or to any third party for any modification, suspension or
      discontinuance of the Service. Proteus reserves the right at any time in its
      sole discretion to block users, including without limitation users from certain
      IP addresses, from accessing the Service.
    </p>

    <p>&nbsp;</p>

    <h2>5. Membership</h2>

    <p>&nbsp;</p>

    <p>Proteus may offer services on a
      membership fee basis according to the pricing and payment schedule available
      through the Portal (the “<b>Membership Fees</b>”). You agree to pay the Membership Fees
      and any other charges incurred in connection with your Account (including any
      and all applicable taxes) at the rates in effect when the charges were
      incurred. If your membership includes access to areas containing premium
      content or services, your access to such areas may be subject to additional
      fees, terms and conditions, which will be separately disclosed in such areas.
    </p>

    <p>&nbsp;</p>

    <p>Proteus bills all charges
      automatically to your credit card, PayPal account or other payment method that
      Proteus offers and you select. Each membership term shall be for one month,
      which term shall automatically renew unless you terminate your subscription
      prior to the beginning of the end of the then-current term. All payments are
      due in advance of the subscription term. Membership Fees will be billed
      beginning on or about the day of the month in which Proteus activates your
      Account (the “<b>Membership Date</b>”) and on or about the monthly anniversary
      of each Membership Date thereafter for the term of this Agreement and any
      renewal thereof until you cancel your membership. If you cancel your
      membership, you may use your membership until the end of your then-current
      membership term and your membership will not be renewed after your then-current
      membership term expires; however, you won’t be eligible for a prorated refund
      of any portion of the membership fee paid for the then-current membership
      period.
    </p>

    <p>&nbsp;</p>

    <p>Unless Proteus otherwise agrees
      in writing, all fees and charges are nonrefundable. Proteus may change the
      fees and charges then in effect, or add new fees or charges, by giving you
      notice in advance. If you want to use a different credit card or payment method
      than one previously provided or there is a change in credit card validity or
      expiration date, or if you believe someone has accessed the Services using your
      user name and password without your authorization, you must contact us
      immediately. If you initiate a chargeback or otherwise reverse a payment made
      with your payment method, Proteus may in its discretion terminate your
      account immediately. If Proteus successfully disputes the reversal, and the
      reversed funds are returned, you are not entitled to a refund or to have your
      account or membership reinstated. You are responsible for any fees or charges
      incurred to access the Services through an Internet access provider or other
      third-party service. By way of example and without limitation, the Services
      may include large video and/or image files. You are responsible for payment of
      any bandwidth, data, or subscription charges you may incur from a service provider
      while utilizing the Services.
    </p>

    <p>&nbsp;</p>

    <h2>6. Intellectual Property Rights</h2>

    <p>&nbsp;</p>

    <p>The Portal and its entire
      contents, features, and functionality (including but not limited to all
      information, software, text, displays, images, video and audio, and the design,
      selection and arrangement thereof), are owned by Proteus, its licensors, or
      other providers of such material and are protected by United States and
      international copyright, trademark, patent, trade secret and other intellectual
      property or proprietary rights laws.
    </p>

    <p>&nbsp;</p>

    <p>These Terms of Use permit you to
      use the Portal for your personal, non-commercial use only. You must not
      reproduce, distribute, modify, create derivative works of, publicly display,
      publicly perform, republish, download, store or transmit the Portal or any of
      the material on our Portal. You must not access or use for any commercial
      purposes any part of the Portal or any services or materials available through
      the Portal.
    </p>

    <p>&nbsp;</p>

    <p>If you wish to make any use of
      material on the Portal other than that set out in this section, please contact
      us at info@justfund.us.
    </p>

    <p>&nbsp;</p>

    <p>If you print, copy, modify,
      download, or otherwise use or provide any other person with access to any part
      of the Portal in breach of the Terms of Use, your right to use the Portal will
      cease immediately and you must, at our option, return or destroy any copies of
      the materials you have made. No right, title or interest in or to the Portal
      or any content on the Portal is transferred to you, and all rights not
      expressly granted are reserved by Proteus. Any use of the Portal not
      expressly permitted by these Terms of Use is a breach of these Terms of Use and
      may violate copyright, trademark and other laws.
    </p>

    <p>&nbsp;</p>

    <h2>7. Trademarks</h2>

    <p>&nbsp;</p>

    <p>The Justfund.us name, Proteus
      name, the term “Proteus”, the Proteus logo and all related names, logos,
      product and service names, designs and slogans are trademarks of Proteus or
      its affiliates or licensors. You must not use such marks without the prior
      written permission of Proteus. All other names, logos, product and service
      names, designs and slogans on this Portal are the trademarks of their
      respective owners.
    </p>

    <p>&nbsp;</p>

    <h2>8. Prohibited Uses</h2>

    <p>&nbsp;</p>

    <p>You may use the Portal only for
      lawful purposes and in accordance with these Terms of Use. You agree not to use
      the Portal:
    </p>

    <ul>
      <li>
        In any way that violates any applicable federal, state, local or
        international law or regulation (including, without limitation, any laws
        regarding the export of data or software to and from the US or other
        countries).
      </li>

      <li>
        For the purpose of exploiting, harming or attempting to exploit
        or harm minors in any way by exposing them to inappropriate content, asking for
        personally identifiable information or otherwise.
      </li>

      <li>
        To send, knowingly receive, upload, download, use or re-use any
        material which does not comply with the Content Standards set out in these
        Terms of Use.
      </li>

      <li>
        To transmit, or procure the sending of, any advertising or
        promotional material, including any “junk mail”, “chain letter” or “spam” or
        any other similar solicitation.
      </li>

      <li>
        To impersonate or attempt to impersonate Proteus, a Proteus
        employee, another user or any other person or entity (including, without
        limitation, by using e-mail addresses or screen names associated with any of
        the foregoing).
      </li>

      <li>
        To engage in any other conduct that restricts or inhibits
        anyone’s use or enjoyment of the Portal, or which, as determined by us, may
        harm Proteus or users of the Portal or expose them to liability.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Additionally, you agree not to:</p>

    <ul>
      <li>
        Use the Portal in any manner that could disable, overburden,
        damage, or impair the site or interfere with any other party’s use of the
        Portal, including their ability to engage in real time activities through the
        Portal.
      </li>

      <li>
        Use any robot, spider or other automatic device, process or means
        to access the Portal for any purpose, including monitoring or copying any of
        the material on the Portal.
      </li>

      <li>
        Use any manual process to monitor or copy any of the material on
        the Portal or for any other unauthorized purpose without our prior written
        consent.
      </li>

      <li>
        Use any device, software or routine that interferes with the
        proper working of the Portal.
      </li>

      <li>
        Introduce any viruses, trojan horses, worms, logic bombs or other
        material which is malicious or technologically harmful.
      </li>

      <li>
        Attempt to gain unauthorized access to, interfere with, damage or
        disrupt any parts of the Portal, the server on which the Portal is stored, or
        any server, computer or database connected to the Portal.
      </li>

      <li>
        Attack the Portal via a denial-of-service attack or a distributed
        denial-of-service attack.
      </li>

      <li>
        Otherwise attempt to interfere with the proper working of the
        Portal.
      </li>
    </ul>

    <p>&nbsp;</p>

    <h2>9. Interactions With Other Members / User Contributions</h2>

    <p>&nbsp;</p>

    <p>PROTEUS MAKES NO
      REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF MEMBERS. YOU ARE SOLELY
      RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT
      PROTEUS CURRENTLY DOES NOT (I) CONDUCT CRIMINAL BACKGROUND CHECKS OR
      SCREENINGS OF ITS MEMBERS, (II) INQUIRE INTO THE BACKGROUNDS OF ITS MEMBERS OR
      (III) REGULARLY ATTEMPT TO VERIFY THE STATEMENTS OF ITS MEMBERS, INCLUDING
      WITHOUT LIMITATION ANY OF THE REPRESENTATIONS AND WARRANTIES SET FORTH IN THIS
      AGREEMENT. PROTEUS RESERVES THE RIGHT TO CONDUCT SCREENINGS AT ANY TIME AND
      USING AVAILABLE PUBLIC RECORDS. PROTEUS MAY TERMINATE THE MEMBERSHIP OF ANY
      MEMBER IF IT BELIEVES IN ITS SOLE DISCRETION THAT SUCH MEMBER HAS OR MAY
      VIOLATE ANY OF THE TERMS AND/OR CONDITIONS OF THIS AGREEMENT.
    </p>

    <p>&nbsp;</p>

    <p>PROTEUS IS NOT RESPONSIBLE FOR
      THE CONDUCT OF ANY MEMBER. IN NO EVENT SHALL PROTEUS, ITS OFFICERS,
      DIRECTORS, EMPLOYEES, OR THEIR RESPECTIVE AFFILIATES BE LIABLE (DIRECTLY OR
      INDIRECTLY) FOR ANY LOSSES OR DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT,
      GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT
      OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE
      OF THE WEBSITE OR SERVICE.
    </p>

    <p>&nbsp;</p>

    <p>The Portal may contain message
      boards, chat rooms, personal web pages, or profiles, forums, bulletin boards
      and other interactive features (collectively, “<b>Interactive Services</b>”)
      that allow users to post, submit, publish, display or transmit to other users
      or other persons (hereinafter, “<b>post</b>”) content or materials (collectively,
      “<b>User Contributions</b>”) on or through the Portal.
    </p>

    <p>&nbsp;</p>

    <p>All User Contributions must comply with the Content Standards
      set out in these Terms of Use.
    </p>

    <p>&nbsp;</p>

    <p>Any User Contribution you post to
      the site will be considered non-confidential and non-proprietary. By providing
      any User Contribution on the Portal, you grant us and our affiliates and
      service providers, and each of their and our respective licensees, successors
      and assigns the limited right to use, reproduce, modify, perform, display,
      distribute any such material.
    </p>

    <p>&nbsp;</p>

    <p>You represent and warrant that: </p>

    <ul>
      <li>
        You own or control all rights in and to the User Contributions
        and have the right to grant the license granted above to us and our affiliates
        and service providers, and each of their and our respective licensees, successors
        and assigns.
      </li>

      <li>
        All of your User Contributions do and will comply with these
        Terms of Use.
      </li>

      <li>
        You understand and acknowledge that you are responsible for any
        User Contributions you submit or contribute, and you, not Proteus, have full
        responsibility for such content, including its legality, reliability, accuracy
        and appropriateness.
      </li>

      <li>
        We are not responsible, or liable to any third party, for the
        content or accuracy of any User Contributions posted by you or any other user
        of the Portal.
      </li>
    </ul>

    <p>&nbsp;</p>

    <h2>10. Monitoring and Enforcement; Termination</h2>

    <p>&nbsp;</p>

    <p>We have the right to:</p>

    <ul>
      <li>
        Remove or refuse to post any User Contributions for any or no
        reason in our sole discretion.
      </li>

      <li>
        Take any action with respect to any User Contribution that we
        deem necessary or appropriate in our sole discretion, including if we believe
        that such User Contribution violates the Terms of Use, including the Content
        Standards, infringes any intellectual property right or other right of any
        person or entity, threatens the personal safety of users of the Portal or the
        public or could create liability for Proteus.
      </li>

      <li>
        Disclose your identity or other information about you to any
        third party who claims that material posted by you violates their rights,
        including their intellectual property rights or their right to privacy.
      </li>

      <li>
        Take appropriate legal action, including without limitation,
        referral to law enforcement, for any illegal or unauthorized use of the Portal.
      </li>

      <li>
        Terminate or suspend your access to all or part of the Portal for
        any or no reason, including without limitation, any violation of these Terms of
        Use.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Without limiting the foregoing,
      we have the right to fully cooperate with any law enforcement authorities or
      court order requesting or directing us to disclose the identity or other
      information of anyone posting any materials on or through the Portal. YOU
      WAIVE AND HOLD HARMLESS PROTEUS AND ITS AFFILIATES, LICENSEES, AND SERVICE
      PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE
      FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
      ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW
      ENFORCEMENT AUTHORITIES.
    </p>

    <p>&nbsp;</p>

    <p>However, we do not undertake to
      review material before it is posted on the Portal, and cannot ensure prompt
      removal of objectionable material after it has been posted. Accordingly, we
      assume no liability for any action or inaction regarding transmissions,
      communications, or content provided by any user or third party. We have no
      liability or responsibility to anyone for performance or nonperformance of the
      activities described in this section.
    </p>

    <p>&nbsp;</p>

    <h2>11. Content Standards</h2>

    <p>&nbsp;</p>

    <p>These content standards apply to
      any and all User Contributions and use of Interactive Services. User
      Contributions must in their entirety comply with all applicable federal, state,
      local and international laws and regulations. Without limiting the foregoing,
      User Contributions must not:
    </p>

    <ul>
      <li>
        Contain any material which is defamatory, obscene, indecent,
        abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise
        objectionable.
      </li>

      <li>
        Promote sexually explicit material, violence, or discrimination
        based on race, sex, religion, nationality, disability, sexual orientation, or
        age.
      </li>

      <li>
        Infringe any patent, trademark, trade secret, copyright or other
        intellectual property, or other rights of any other person.
      </li>

      <li>
        Violate the legal rights (including the rights of publicity and
        privacy) of others or contain any material that could give rise to any civil or
        criminal liability under applicable laws or regulations or that otherwise may
        be in conflict with these Terms of Use and
        our <LinkInternal href='/justfund/privacy'>Privacy Policy</LinkInternal>.
      </li>

      <li>
        Be likely to deceive any person.
      </li>

      <li>
        Promote any illegal activity, or advocate, promote, or assist any
        unlawful act.
      </li>

      <li>
        Cause annoyance, inconvenience or needless anxiety or be likely
        to upset, embarrass, alarm or annoy any other person.
      </li>

      <li>
        Impersonate any person, or misrepresent your identity or
        affiliation with any person or organization.
      </li>

      <li>
        Involve commercial activities or sales, such as contests,
        sweepstakes, and other sales promotions, barter or advertising.
      </li>

      <li>
        Give the impression that they emanate from or are endorsed by us
        or any other person or entity, if this is not the case.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p><b>12. Copyright Infringement</b></p>

    <p>&nbsp;</p>

    <p>
      Proteus respects the
      intellectual property rights of others and we expect our users to do the same.
      We will respond to notices of alleged copyright infringement if they comply
      with the Digital Millennium Copyright Act of 1998, the text of which may be
      found at the U.S. Copyright Office website at{' '}
      <LinkExternal href='http://www.copyright.gov/legislation/dmca.pdf'>
        http://www.copyright.gov/legislation/dmca.pdf
      </LinkExternal>.
      Claims of copyright infringement in connection with our service and/or the
      Proteus website must be reported to our Designated Copyright Agent identified
      in the sample notice below.
    </p>

    <p>&nbsp;</p>

    <p>Copyright owners, those
      authorized to act on behalf of one, or those authorized to act under any exclusive
      right under copyright, may report alleged copyright infringements that occur on
      or through our service or website by completing the following DMCA Notice of
      Alleged Infringement and by delivering it to Proteus’ Designated Copyright
      Agent. Before submitting a Notice, consider whether fair use or a similar
      exception applies. Upon receipt, Proteus will take action, in its sole
      discretion and as it deems appropriate, including removal of allegedly
      infringing content from our service and/or website. We reserve the right to
      delete or disable content alleged to be infringing and to terminate accounts of
      repeat infringers.
    </p>

    <p>&nbsp;</p>

    <p>DMCA Notice of Alleged Infringement (“Notice”)</p>

    <ul>
      <li>
        <b>Description of the Work</b> – Please identify the copyrighted
        work that you claim has been infringed, or, if multiple copyrighted works are
        covered by this Notice, you may provide a representative list of such works in
        a single Notice.
      </li>

      <li>
        <b>Specific URL </b>– Please identify the material or link that
        you claim is infringing (or the subject of the infringing activity) and to
        which access should be disabled, including information reasonably sufficient
        for us to locate the material, such as a URL or link or the exact location
        where the allegedly infringing material may be found.
      </li>

      <li>
        <b>Your Contact Information</b> – Please provide information
        reasonably sufficient for us to contact you, such as an address, telephone
        number, and if available, an email address.
      </li>

      <li>
        <b>Include and Agree to the Following Statements </b>– You must
        include both of the following statements in the body of the Notice: “I hereby
        state that I have a good faith belief that the disputed use of the copyrighted
        material is not authorized by the copyright owner, its agent, or the law (e.g.,
        as a fair use). I hereby state that the information contained in this Notice
        is accurate and, under penalty of perjury, that I am the owner, or authorized
        to act on behalf of the owner, of the copyright or of an exclusive right under
        the copyright that is allegedly infringed.”
      </li>

      <li>
        <b>Your Signature</b> – Please provide the full legal name and a
        physical or electronic signature of the individual authorized to act on behalf
        of the owner of an exclusive right that has been infringed.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Deliver this Notice with each of
      the above items to our Designated Copyright Agent:
    </p>

    <p>&nbsp;</p>

    <p>Copyright Agent</p>

    <p>
      Bernstein Shur<br />
      P.O. Box 9729<br />
      Portland, ME 04104<br />
      Attn: Proteus Fund
    </p>

    <p>207-774-1200</p>

    <p>dmcanotice@bernsteinshur.com</p>

    <p>&nbsp;</p>

    <h2>13. Reliance on Information Posted</h2>

    <p>&nbsp;</p>

    <p>The information presented on or
      through the Portal is made available solely for general information purposes.
      We do not warrant the accuracy, completeness, or usefulness of this
      information. Any reliance you place on such information is strictly at your
      own risk. We disclaim all liability and responsibility arising from any
      reliance placed on such materials by you or any other visitor to the Portal, or
      by anyone who may be informed of any of its contents.
    </p>

    <p>&nbsp;</p>

    <p>This Portal may include content
      provided by third parties, including materials provided by other users,
      bloggers and third-party licensors, syndicators, aggregators and/or reporting
      services. All statements and/or opinions expressed in these materials, and all
      articles and responses to questions and other content, other than the content
      provided by Proteus, are solely the opinions and the responsibility of the
      person or entity providing those materials. These materials do not necessarily
      reflect the opinion of Proteus. We are not responsible, or liable to you or
      any third party, for the content or accuracy of any materials provided by any
      third parties.
    </p>

    <p>&nbsp;</p>

    <h2>14. Changes to the Portal</h2>

    <p>&nbsp;</p>

    <p>We may update the content on this
      Portal from time to time, but its content is not necessarily complete or
      up-to-date. Any of the material on the Portal may be out of date at any given
      time, and we are under no obligation to update such material.
    </p>

    <p>&nbsp;</p>

    <h2>15. Information About You and Your Visits to the Portal</h2>

    <p>&nbsp;</p>

    <p>
      All information we collect on this Portal is subject to
      our <LinkInternal href='/justfund/privacy'>Privacy Policy</LinkInternal>.
      By using the Portal, you consent to all actions taken by us with respect to
      your information in compliance with the Privacy Policy.
    </p>

    <p>&nbsp;</p>

    <h2>16. Linking to the Portal and Social Media Features</h2>

    <p>&nbsp;</p>

    <p>You may link to our homepage,
      provided you do so in a way that is fair and legal and does not damage our
      reputation or take advantage of it, but you must not establish a link in such a
      way as to suggest any form of association, approval or endorsement on our part
      without our express written consent.
    </p>

    <p>&nbsp;</p>

    <p>This Portal may provide certain social media features that enable you to:</p>

    <ul>
      <li>
        Link from your own or certain third-party websites to certain
        content on this Portal.
      </li>

      <li>
        Send emails or other communications with certain content, or
        links to certain content, on this Portal.
      </li>

      <li>
        Cause limited portions of content on this Portal to be displayed
        or appear to be displayed on your own or certain third-party websites.
      </li>

      <li>
        You may use these features solely as they are provided by us, and
        solely with respect to the content they are displayed with, and otherwise in
        accordance with any additional terms and conditions we provide with respect to
        such features.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Subject to the foregoing, you must not:</p>

    <ul>
      <li>
        Establish a link from any website that is not owned by you.
      </li>

      <li>
        Cause the Portal or portions of it to be displayed, or appear to
        be displayed by, for example, framing, deep linking or in-line linking, on any
        other site.
      </li>

      <li>
        Link to any part of the Portal other than the homepage.
      </li>

      <li>
        Otherwise take any action with respect to the materials on this
        Portal that is inconsistent with any other provision of these Terms of Use.
      </li>

      <li>
        The website from which you are linking, or on which you make
        certain content accessible, must comply in all respects with the Content
        Standards set out in these Terms of Use.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>You agree to cooperate with us in
      causing any unauthorized framing or linking immediately to cease. We reserve
      the right to withdraw linking permission without notice. We may disable all or
      any social media features and any links at any time without notice in our
      discretion.
    </p>

    <p>&nbsp;</p>

    <h2>17. Links from the Portal</h2>

    <p>&nbsp;</p>

    <p>If the Portal contains links to
      other sites and resources provided by third parties, these links are provided
      for your convenience only. We have no control over the contents of those sites
      or resources, and accept no responsibility for them or for any loss or damage
      that may arise from your use of them. If you decide to access any of the third
      party websites linked to this Portal, you do so entirely at your own risk and subject
      to the terms and conditions of use for such websites.
    </p>

    <p>&nbsp;</p>

    <h2>18. Geographic Restrictions</h2>

    <p>&nbsp;</p>

    <p>The owner of the Portal is based
      in the Commonwealth of Massachusetts in the United States. We provide this
      Portal for use only by persons located in the United States. We make no claims
      that the Portal or any of its content is accessible or appropriate outside of
      the United States. Access to the Portal may not be legal by certain persons or
      in certain countries. If you access the Portal from outside the United States,
      you do so on your own initiative and are responsible for compliance with local
      laws.
    </p>

    <p>&nbsp;</p>

    <h2>19. Disclaimer of Warranties</h2>

    <p>&nbsp;</p>

    <p>You understand that we cannot and
      do not guarantee or warrant that files available for downloading from the
      internet or the Portal will be free of viruses or other destructive code. You
      are responsible for implementing sufficient procedures and checkpoints to
      satisfy your particular requirements for anti-virus protection and accuracy of
      data input and output, and for maintaining a means external to our site for any
      reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
      CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
      TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
      COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
      WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
      DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
    </p>

    <p>&nbsp;</p>

    <p>YOUR USE OF THE WEBSITE, ITS
      CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN
      RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
      WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER PROTEUS NOR ANY
      PERSON ASSOCIATED WITH PROTEUS MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
      TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
      OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER PROTEUS NOR ANYONE
      ASSOCIATED WITH PROTEUS REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT
      OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
      RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
      SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
      COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
    </p>

    <p>&nbsp;</p>

    <p>PROTEUS HEREBY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
      INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT
      AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>

    <p>&nbsp;</p>

    <h2>20. Limitation on Liability</h2>

    <p>&nbsp;</p>

    <p>IN NO EVENT WILL PROTEUS, ITS
      AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS
      OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
      OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
      WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
      INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
      DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
      EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
      ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
      CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
      FORESEEABLE.
    </p>

    <p>&nbsp;</p>

    <p>THE FOREGOING DOES NOT AFFECT ANY
      LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>

    <p>&nbsp;</p>

    <h2>21. Indemnification</h2>

    <p>&nbsp;</p>

    <p>You agree to defend, indemnify
      and hold harmless Proteus, its affiliates, licensors and service providers,
      and its and their respective officers, directors, employees, contractors,
      agents, licensors, suppliers, successors and assigns from and against any
      claims, liabilities, damages, judgments, awards, losses, costs, expenses or
      fees (including reasonable attorneys’ fees) arising out of or relating to your
      violation of these Terms of Use or your use of the Portal, including, but not
      limited to, your User Contributions, any use of the Portal’s content, services
      and products other than as expressly authorized in these Terms of Use or your use
      of any information obtained from the Portal.
    </p>

    <p>&nbsp;</p>

    <h2>22. Governing Law and Jurisdiction</h2>

    <p>&nbsp;</p>

    <p>All matters relating to the
      Portal and these Terms of Use and any dispute or claim arising therefrom or
      related thereto (in each case, including non-contractual disputes or claims), shall
      be governed by and construed in accordance with the internal laws of the Commonwealth
      of Massachusetts without giving effect to any choice or conflict of law
      provision or rule (whether of the Commonwealth of Massachusetts or any other
      jurisdiction).
    </p>

    <p>&nbsp;</p>

    <p>Any legal suit, action or
      proceeding arising out of, or related to, these Terms of Use or the Portal
      shall be instituted exclusively in the federal courts of the United States or
      the courts of the Commonwealth of Massachusetts although we retain the right to
      bring any suit, action or proceeding against you for breach of these Terms of
      Use in your country of residence or any other relevant country. You waive any
      and all objections to the exercise of jurisdiction over you by such courts and
      to venue in such courts.
    </p>

    <p>&nbsp;</p>

    <h2>23. Limitation on Time to File Claims</h2>

    <p>&nbsp;</p>

    <p>ANY CAUSE OF ACTION OR CLAIM YOU
      MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST
      BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
      SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
    </p>

    <p>&nbsp;</p>

    <h2>24. Waiver and Severability</h2>

    <p>&nbsp;</p>

    <p>No waiver by Proteus of any
      term or condition set forth in these Terms of Use shall be deemed a further or
      continuing waiver of such term or condition or a waiver of any other term or
      condition, and any failure of Proteus to assert a right or provision under
      these Terms of Use shall not constitute a waiver of such right or provision.
    </p>

    <p>&nbsp;</p>

    <p>If any provision of these Terms
      of Use is held by a court or other tribunal of competent jurisdiction to be
      invalid, illegal or unenforceable for any reason, such provision shall be
      eliminated or limited to the minimum extent such that the remaining provisions
      of the Terms of Use will continue in full force and effect.
    </p>

    <p>&nbsp;</p>

    <h2>25. Entire Agreement</h2>

    <p>&nbsp;</p>

    <p>The Terms of Use and our Privacy
      Policy constitute the sole and entire agreement between you and Proteus with
      respect to the Portal and supersede all prior and contemporaneous
      understandings, agreements, representations and warranties, both written and
      oral, with respect to the Portal.
    </p>

    <p>&nbsp;</p>

    <h2>26. Your Comments and Concerns</h2>

    <p>This website is operated by Proteus Fund.</p>

    <p>&nbsp;</p>

    <p>All notices of copyright
      infringement claims should be sent to the copyright agent as designated herein.
    </p>

    <p>&nbsp;</p>

    <p>If you have any other feedback,
      comments, requests for technical support or other communications relating to
      the Portal, please contact us
      at <Link href='mailto:info@justfund.us'>info@justfund.us</Link>.
    </p>

    <p><b>&nbsp;</b></p>

    <p>Thank you for visiting the Portal.</p>
  </Box>
);
