import { Box, Button, Typography, styled } from '@mui/material';

export const Root = styled(Box)(() => ({
  border: '1px solid #E9E9EA',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '2rem',
  maxWidth: '900px',
  textAlign: 'left',
  margin: 'auto'
}));

export const EmailText = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '0.8rem'
}));

export const MessageText = styled(Typography)(() => ({
  fontSize: '16px',
  marginBottom: '0.8rem'
}));

export const UnsubscribeText = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '0.8rem'
}));

export const StyledButton = styled(Button)(() => ({
  borderRadius: '120px',
  border: '1px solid #616161',
  color: '#616161',
  background: '#FFF',
  textTransform: 'none',
  fontSize: '14px',
  margin: '0',
  marginTop: '0.7rem'
}));
