import * as React from "react";

function SvgGenderSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="gender-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#gender-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#e57fca">
            <path
              data-name="Path 20"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.7 8.51a.51.51 0 01-1.02 0V7.37l-1.42 1.42a4.08 4.08 0 01-2.74 6.51v1h1a.51.51 0 110 1h-1v1a.51.51 0 11-1.02 0v-1h-1a.51.51 0 110-1h1v-1a4.07 4.07 0 113-7.24L16 6.64h-1.19a.51.51 0 110-1h2.38a.51.51 0 01.51.51z"
            />
            <path
              data-name="Path 21"
              d="M12 8.2a3.06 3.06 0 103.06 3.05A3.06 3.06 0 0012 8.2zm1.34 4.29h-2.66a.45.45 0 010-.89h2.67a.45.45 0 010 .89zm0-1.6h-2.66a.45.45 0 010-.89h2.67a.45.45 0 010 .89z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgGenderSmall;
