import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { focusAreas } from '../../../common/data/data';
import { Tooltip } from './Tooltip';

interface props {
  focus: number;
  size?: number | string;
  link?: string | null;
}

export const FocusIcon = ({ focus, size = '28px', link = null }: props) => {
  const history = useHistory ();
  const t = focusAreas[focus] || focusAreas[12];
  return (
    <Tooltip title={t.name}>
      <IconButton
        size='small'
        onClick={(e) => {
          e.stopPropagation ();
          if (link) {
            history.push (`${link}${focus}`);
          }
        }}
      >
        { React.createElement (t.component, { width: size, height: size }, null) }
      </IconButton>
    </Tooltip>
  );
};
