import { AxiosRequestConfig } from "axios";
import { IBookmark, ICreateBookmark } from "../../interfaces/IBookmark.interfaces";
import { api } from "./api.service";
import { IBloatedFunder } from "../../interfaces/IFunder";

export class BookmarkService {
  private static baseRoute = '/api/bookmark'

  public static async get(params: any): Promise<IBookmark[]> {
    const res = await api.get(`${this.baseRoute}`, { params });
    return res.data;
  }

  public static async getCount(options: AxiosRequestConfig): Promise<{count: number}> {
    const res = await api.get(`${this.baseRoute}/count`, options);
    return res.data;
  }

  public static async getCommonApps(options: AxiosRequestConfig): Promise<{count: number}> {
    const res = await api.get(`${this.baseRoute}/common-app`, options);
    return res.data;
  }

  public static async getFunders(options: AxiosRequestConfig): Promise<IBloatedFunder> {
    const res = await api.get(`${this.baseRoute}/funders`, options);
    return res.data;
  }

  public static async create(data: ICreateBookmark): Promise<IBookmark> {
    const res = await api.post(this.baseRoute, data);
    return res.data;
  }

  public static async delete(id: number): Promise<boolean> {
    const res = await api.delete(`${this.baseRoute}/${id}`)
    return res.data;
  }

}