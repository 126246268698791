export enum ProposalStatus {
  Active = 0,
  Draft = 1,
  Archived = 2,
  // ACTIVE_DRAFT is only for 'option 2' on the Apply to an RFP modal. 
  // needed for permissions on portal. 
  Active_Draft = 3,
  Deleted = 4,
}

export enum ProposalScope {
  NATIONAL = 1,
  REGIONAL = 2,
  STATE = 3,
  LOCAL = 4
}
