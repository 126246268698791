import { Grid, styled } from '@mui/material'
import { SocialMediaProps } from './socialMedia.types'

export const SocialMediaWrapper = styled(Grid)<Partial<SocialMediaProps>>(({iconsSize = 25, gap = 8}) => ({
  display: 'flex',
  gap: gap,
  '& .MuiLink-root': {
    height: iconsSize,
  },
  '& .MuiLink-root > .MuiSvgIcon-root': {
    color: '#FFF',
    borderRadius: '50%',
    width: iconsSize,
    height: iconsSize,
  }
}))