import { Close } from "@mui/icons-material";
import { Avatar, Box, Drawer, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { jfTheme } from "../../../../../styles/jf.theme";

export const ProfileCardTitleAvatar = styled(Avatar)({
  backgroundColor: '#DBE4F3',
  color: '#7C94BD',
  fontWeight: 500,
  height: '50px',
  width: '50px',
  marginRight: '8px',
  fontSize: '24px',
});

export const TitleContainer = styled(Grid)({
  borderBottom: '1px solid #EEEEEE',
  padding: '20px',
});

export const InfoContainer = styled(Box)({
  backgroundColor: '#EFF5F9',
  padding: '16px',
  borderRadius: '8px',
  margin: '16px 0px',
});

export const CloseButton = styled(Close)({
  width: '20px',
  height: '20px',
  cursor: 'pointer',
});

export const Container = styled(Grid)({
  justifyContent: 'center',
  maxHeight: '85vh',
  overflow: 'scroll',
  padding: '0px 24px',
  marginTop: '6px',
});

export const DrawerBox = styled(Box)({
  width: '640px',
  overflow: 'hidden',
});

export const CustomLink = styled(Link)({
  color: jfTheme.palette.secondary.light,
});

export const CustomDrawer = styled(Drawer)({
  zIndex: 9999,
});