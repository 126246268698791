import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const CustomLinkedinIcon = (props: SvgIconProps) => (
  <SvgIcon
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47 47"
    {...props}
  >
    <path
      d="M23.5,47C10.5,47,0,36.5,0,23.5C0,14,5.7,5.5,14.4,1.8c12-5,25.7,0.7,30.7,12.6
        c5,11.9-0.6,25.6-12.4,30.7C29.8,46.4,26.6,47,23.5,47z M26.9,22.8c1.7,0,2.1,1.5,2.1,2.8v6.7h4.1v-7.2c0.1-1.5-0.4-3.1-1.4-4.2
        c-0.9-0.9-2.1-1.4-3.4-1.4c-1.5,0.1-2.8,0.9-3.7,2.1v-1.8h-4.2v12.5h4.1v-7c0-0.3,0-0.7,0.1-1C25.1,23.4,26,22.8,26.9,22.8
        L26.9,22.8z M14.1,19.8v12.5h4.1V19.8H14.1z M16.2,13.8c-1.2-0.1-2.2,0.8-2.3,2c-0.1,1.2,0.8,2.2,2,2.3c0.1,0,0.2,0,0.3,0h0
        c1.2,0.1,2.2-0.8,2.3-2s-0.8-2.2-2-2.3C16.4,13.8,16.3,13.8,16.2,13.8z"
    />
  </SvgIcon>
)
