import * as React from "react";

function SvgOtherSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="other-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#other-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 29"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm-1.43 5.91a8.08 8.08 0 00-2.71 5.7h-2.1a6.29 6.29 0 014.81-5.7zm-4.81 6.48h2.1a8.08 8.08 0 002.71 5.7 6.29 6.29 0 01-4.81-5.7zm5.85 5.53a7.25 7.25 0 01-3-5.53h3zm0-6.31h-3a7.25 7.25 0 013-5.53zm.78-5.53a7.29 7.29 0 013 5.53h-3zm0 11.84v-5.53h3a7.29 7.29 0 01-3 5.53zm1 .17a8.08 8.08 0 002.71-5.7h2.1a6.29 6.29 0 01-4.77 5.7zm2.71-6.48a8.08 8.08 0 00-2.71-5.7 6.29 6.29 0 014.81 5.7z"
              fill="#663293"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgOtherSmall;
