import React from 'react';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { UserAuthService } from '../../../shared/services/api-service/user-auth-api.service';
import { useMessagingContext } from "../../../shared/components/modals/Notification";
import { NotificationStatus } from '../../../shared/enums/notification-status.enum';
import { useHistory } from "react-router";
import { Controller, useForm } from 'react-hook-form';

const classes = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
      border: ' 1px solid #091B30',
    },
    width: '100%',
    marginBottom: '1em'
  },
  caption: {
    fontWeight: 'bold',
    paddingBottom: '10px',
    lineHeight: '22px',
    letterSpacing: 0,
  },
  resetHeading: {
    lineHeight: '61px',
    fontSize: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '22px'
  },
  redUnderline: {
    height: '10px',
    backgroundColor: '#E61436',
    margin: '1em',
    width: '70%',
    marginLeft: '15%'
  },
  fontWeight: {
    fontWeight: 500,
  },
  alignInfo: {
    '@media (min-width: 700px)': {
      marginLeft: '15%'
    },
  }
};

export function JFForgotPassword() {

  const history = useHistory();
  const { notificationDispatch } = useMessagingContext();
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const requiredErrorMessage = "This field is required";

  const handleOpen = () => {
    const data = { removeCancelBtn: true, open: true, title: "Check your email", message: "We have emailed you a reset password link. Go to your email and click the reset password link to continue.", onClose: handleClose }
    notificationDispatch({ type: NotificationStatus.UPDATE, data });
  };

  const handleErrorNotification = () => {
    const data = { removeCancelBtn: true, open: true, title: "Invalid Email", message: `Not a registered Email. Please check and try again.` }
    notificationDispatch({ type: NotificationStatus.UPDATE, data });
  };

  const onSubmit = async (formData: any) => {
    let modified;
    modified = formData;
    try {
      const foundUser = await UserAuthService.sendPasswordReset(modified.email);
      if (foundUser) handleOpen();
    } catch (err) {
      handleErrorNotification();
    }
  };

  const handleClose = (val: boolean) => {
    if (val) {
      history.push(`/`);
    }
  };

  const { control, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });

  return (
    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ ...classes.root, marginTop: '1em', padding: '2em' }}>
        <Grid item xs={12}>
          <Typography variant='h1' sx={classes.resetHeading}>Reset Password</Typography>
          <Box sx={classes.redUnderline} />
        </Grid>
        <Grid item xs={12} sm={8} sx={classes.alignInfo}>
          <Typography sx={classes.fontWeight} paragraph>
            To reset your password, provide your login email address and a new password
            will be emailed to you.
          </Typography>
        </Grid>
        <Grid item sx={classes.alignInfo}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} sm={8}>
              <Typography sx={classes.caption} variant="body2">Email Address*</Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: requiredErrorMessage, pattern: { value: emailRegex, message: "Must be a valid email address" } }}
                render={({ field }) =>
                  <TextField sx={classes.textField}
                    placeholder="Confirm Email"
                    size="small"
                    error={!!errors?.email}
                    helperText={!!errors?.email && errors?.email?.message}
                    variant="outlined"
                    InputProps={{ style: { fontSize: 16 } }}
                    {...field}
                  />
                }
              />
            </Grid>
            <Grid item>
              <Button variant='contained' type="submit" sx={{ marginRight: '1em' }}>
                Reset
              </Button>
              <Link href={'/justfund/signin'} underline="none">
                <Button variant='outlined' type='button'>
                  Cancel
                </Button>
              </Link>
            </Grid>
          </form>
        </Grid>
      </Box>
    </Grid>
  )
}

