import { Dispatch, SetStateAction, useEffect, useReducer, useState } from 'react';
import { Alert, Box, Button, Checkbox, Container, Grid, Typography } from '@mui/material';
import { IUser } from '../../../shared/interfaces/IUser.interface'
import { useMessagingContext } from '../../../shared/components/modals/Notification'
import { UserService } from '../../../shared/services/api-service/user-api.service';
import { useParams, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FetchPage } from '../../../shared/components/consumers';
import { RegisterUserDetails } from './RegisterUserDetails';
import { RegisterFunderDetails } from './RegisterFunderDetails';
import { FunderServiceLevel } from '../../../shared/enums/funder';
import { UserVisibilityType } from '../../../shared/enums/user.enum';

const classes = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
  },
  saveButton: {
    paddingBottom: '1em',
    justifyContent: 'flex-end',
    '@media (max-width: 900px)': {
      marginTop: '1.5em',
      justifyContent: 'center'
    }
  },
  label: {
    transform: "translateY(0.5rem)"
  },
  logoImg: {
    cursor: 'pointer',
    marginRight: '1em',
    marginBottom: '10px',
    height: '70px',
    width: 'auto'
  },
  checkbox: {
    marginRight: '10px',
    alignSelf: 'flex-start'
  }
};

export function UserRegister() {
  const { id } = useParams<{ id: string }>();
  return <FetchPage url={`/api/users/${id}`} renderSuccess={data => <Profile userProfile={data} />} />;
};

interface IUserProps {
  userProfile: IUser
};

interface IBankProps {
  requireBank: boolean,
  checked: boolean,
  membersLength: number[]
};

const bankObj: IBankProps = {
  requireBank: true,
  checked: false,
  membersLength: []
};

function Profile({ userProfile }: IUserProps) {
  const userObj = {
    ...userProfile,
    id: userProfile.id,
    first_name: userProfile.first_name,
    last_name: userProfile.last_name,
    email: userProfile.email,
    phone: userProfile.phone,
    bank_url: userProfile.bank_url,
    display_name: userProfile.display_name,
    is_admin: userProfile.is_admin
  };

  const [selectedPage, setSelectedPage]: [number, Dispatch<SetStateAction<number>>] = useState(1);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [requireBank, setRequireBank] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [anonymousSetting, setAnonymousSetting] = useState<number>(0);
  const [user, setUser] = useReducer(
    (user: IUser, newUser: any) => ({ ...user, ...newUser }),
    userObj
  );

  const { id: userId } = useParams<{ id: string }>();
  const { alertState, alertDispatch } = useMessagingContext();
  const history = useHistory();
  const isFunder = user.funderRoles.length;
  const isGrantee = user.grantees.length;

  const methods = useForm({
    shouldUnregister: false,
  });

  const [bankField, setBankField] = useReducer(
    (bankField: IBankProps, setBankField: any) => ({ ...bankField, ...setBankField }),
    bankObj
  );

  const determineIndividual = (user: IUser): boolean => {
    const isIndividual = user?.funders?.find((fund) => fund.service_level === FunderServiceLevel.Individual);
    return isIndividual;
  };

  const individualOnly = (user: IUser): boolean => {
    const onlyIndivudal = !user?.funders?.find((fund) => fund.service_level !== FunderServiceLevel.Individual);
    return onlyIndivudal;
  };

  const isIndividualFunder = determineIndividual(user);
  const isIndividualOnly = individualOnly(user);

  const hideOptOut = (): boolean => {
    if (!isIndividualFunder) {
      setRequireBank(false);
      return true
    };
    if (isIndividualFunder && isIndividualOnly) {
      setRequireBank(true);
      return true
    };
    if (isIndividualFunder && !isIndividualOnly) {
      setRequireBank(true);
      return false;
    };
    return false;
  };

  const getUser = async () => {
    try {
      if (!userId) return;
      setLoading(true);
      const retrieved = await UserService.getById(parseInt(userId, 10));
      setUser({ ...retrieved, location: { ...retrieved?.locations?.[0], location_id: retrieved?.locations?.[0]?.id } });
    } catch (exc) {
      console.error(exc)
      alertState.showError({ title: 'Something went wrong!', message: 'Unable to get user.' })
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    getUser();
  }, []);

  const validateEmail = async (formEmail: string) => {
    setEmailError('');
    if (userObj?.email === formEmail) {
      return true;
    };
    const validEmail = await UserService.checkEmail(formEmail);
    if (!validEmail) {
      setEmailError(`${formEmail} email is already in use, please choose another`);
      return false;
    };
  };

  const checkFields = (user: any) => {
    const { first_name, last_name, email, phone, role_title, bank_url } = user;
    if (!isFunder && (first_name && last_name && email && phone && role_title)) {
      return true;
    } else if ((isFunder && (first_name && last_name && email && phone && role_title))) {
      return true;
    } else {
      alertDispatch({ type: 'UPDATE', data: { title: 'Something went wrong!', message: 'This form is not valid', severity: 'error' } })
      console.error('This form is not valid');
      return false;
    };
  };

  const onSubmit = async (formData: any) => {
    try {
      // Set focuses
      let userData = formData;
      if (userData.userFocuses?.length) {
        userData.userFocuses = userData.userFocuses.map((focus: { focus_id: string }, index: number) => {
          return {
            focus_id: focus.focus_id,
            order: index
          }
        }).filter((focus: { focus_id: string }) => !!focus.focus_id && focus.focus_id !== 'none' && !!focus.focus_id?.length);
      };
      // Check required focuses - Not Required currently
      // if (isFunder && userData.userFocuses.length !== 3) return;
      // Check required fields
      if (!checkFields(userData)) return;
      // Check if email used / valid
      const validEmail = validateEmail(userData.email);
      if (!validEmail) return;
      if (anonymousSetting !== UserVisibilityType.FALSE && userData.display_name) delete userData.display_name;
      // Send formData to API to edit user
      await UserService.register(userData);
      history.push('/justfund/signin');
    } catch (err) {
      console.error(err);
      alertDispatch({ type: 'UPDATE', data: { title: 'Something went wrong!', message: 'This form is not valid', severity: 'error' } });
    };
  };

  return (
    <Grid container xs={12} sm={6} md={8} display='flex' flexDirection='column' minHeight='100vh' marginX='auto'>
      <Box margin='1em'>
        <Box component='img'
          src='/images/justfund.svg'
          height='70px'
          width='auto'
          alt='JustFund logo'
          sx={classes.logoImg}
        />
        <Typography paragraph gutterBottom>
          Welcome to JustFund! Please confirm your account by filling in the required fields below and clicking Save. You can update your Profile information at any time after your registration is complete.
        </Typography>
      </Box>
      {loading ? <Typography>Loading...</Typography> :
        <Box component='form' onSubmit={methods.handleSubmit(onSubmit, (val) => {
          alertDispatch({ type: 'UPDATE', data: { title: 'Something went wrong!', severity: 'error' } })
          console.error('Something went wrong');
        })}>
          <FormProvider {...methods}>
            <Container sx={classes.root}>
              {(selectedPage === 1) &&
                <RegisterUserDetails setUser={setUser} user={user} emailError={emailError} />
              }
              {isFunder && (selectedPage === 2) ?
                <RegisterFunderDetails setUser={setUser} user={user}  /> :
                <></>
              }
              <Box display={'flex'} sx={{ width: '90%', marginTop: '2em' }}>
                <Checkbox sx={classes.checkbox} checked={privacyPolicy} onChange={({ target }) => setPrivacyPolicy(target.checked)} />
                <Typography variant='body2' textAlign={'left'} sx={classes.label}>
                  By checking this box, I represent that I have reviewed and agree to
                  the <Box component='a' href='https://justfund.us/terms-of-use/' target={'_blank'} rel="noreferrer">Justfund Terms of Service</Box> and {" "}
                  <Box component='a' href='https://justfund.us/privacy-policy/' target={'_blank'} rel="noreferrer">Privacy Policy</Box>
                </Typography>
              </Box>
              <Grid container sx={classes.saveButton}>
                {isFunder && (selectedPage === 1) ?
                  <Button variant='contained' type="button" onClick={() => setSelectedPage(2)}>Next</Button> :
                  <Box>
                    {!isGrantee && <Button variant='outlined' type="button" sx={{ marginRight: '1em' }} onClick={() => setSelectedPage(1)}>Back</Button>}
                    <Button disabled={!privacyPolicy} variant='contained' type="submit">Save</Button>
                  </Box>
                }
              </Grid>
            </Container>
          </FormProvider>
        </Box>
      }
    </Grid>
  )
}
