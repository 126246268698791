import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CustomFacebookIcon = (props: SvgIconProps) => (
  <SvgIcon
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47 47"
    {...props}
  >
    <path
      d="M23.5,47C10.5,47,0,36.5,0,23.5C0,14,5.7,5.5,14.4,1.8c12-5,25.7,0.7,30.7,12.6
        c5,11.9-0.6,25.6-12.4,30.7C29.8,46.4,26.6,47,23.5,47z M18.3,20.8v3.8h3.3v9.7h3.9v-9.7h3.3l0.5-3.8h-3.8v-2.4
        c0-1.1,0.3-1.8,1.9-1.8h2v-3.4C28.4,13,27.4,13,26.5,13h-0.1c-1.3-0.1-2.6,0.4-3.5,1.3c-0.9,1-1.4,2.4-1.4,3.7v2.8L18.3,20.8z"
    />
  </SvgIcon>
)
