import { Grid, Typography } from '@mui/material';
import { Bounce, ToastOptions, toast } from 'react-toastify';

interface ToastContentProps {
  message: string;
  fontSize?: string;
  dataTestId?: string;
}

export const ToastContent: React.FC<ToastContentProps> = ({ message, dataTestId, fontSize = '19px' }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography data-testid={dataTestId} sx={{ fontSize: fontSize }}>{message}</Typography>
      </Grid>
      <Grid item xs="auto">
        x
      </Grid>
    </Grid>
  );
};

// Common options for all toasts
const commonToastOptions: ToastOptions = {
  icon: undefined,
  closeButton: false,
  position: 'bottom-right',
  hideProgressBar: true,
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  transition: Bounce
};

// Success Toast
export const successToast = (message: string, options?: ToastOptions, fontSize?: string, className: string = 'toast-green') => {
  toast.success(<ToastContent message={message} dataTestId="success_toast" fontSize={fontSize} />, {
    ...commonToastOptions,
    ...options,
    className: className
  });
};

// Error Toast
export const errorToast = (message: string, options?: ToastOptions, fontSize?: string, className: string = '') => {
  toast.error(<ToastContent message={message} fontSize={fontSize} />, {
    ...commonToastOptions,
    icon: undefined,
    closeButton: false,
    ...options,
    className: className
  });
};

// Info Toast
export const infoToast = (message: string, options?: ToastOptions) => {
  toast.info(<ToastContent message={message} />, {
    ...commonToastOptions,
    ...options
  });
};

// Warning Toast
export const warningToast = (message: string, options?: ToastOptions) => {
  toast.warning(<ToastContent message={message} />, {
    ...commonToastOptions,
    ...options
  });
};
