import * as React from "react";

function SvgFoodSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="food-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#food-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 19"
              d="M23.892 10.382a12 12 0 10-10.27 13.51 12 12 0 0010.27-13.51zm-16.81-1.27a3.66 3.66 0 013.06-.63 26.891 26.891 0 00-2.75-.94c-.87-.35-.34-1.42.55-1.22a4.39 4.39 0 012.54 1.52 3.83 3.83 0 011.17-2.58c.67-.62 1.66.18 1 .86-1.39 1.53-1.65 2.06-1.26 2.88l-.72.51c-.6-.59-1.15-.45-2.79.71-.88.6-1.66-.43-.8-1.11zm10.22 9c-.58.41-1.08.17-1.83-.49l-4.58-4.07c-1.63-1.46-1.76-2.47.08-3.7l.66-.46c1.87-1.33 2.8-.87 3.59 1.16l2.22 5.71c.36.9.43 1.44-.14 1.83z"
              fill="#eecc19"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgFoodSmall;
