import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProfileCard } from '../../profileCard/ProfileCard';

import { EProfileType } from '../../../enums/profile.enum';
import { IProfile } from '../../../interfaces/IProfile';
import { Loading } from '../../legacy-ui';
import { PublicAPIService } from '../../../services/api-service/public-api.service';
import { Card } from '../../../../common/components';
import ProfileDrawer from '../../newPublicProfile/funded/profileDrawer';

interface IFundedTabContent {
  profileSlug: string;
  setIsOrganizationsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  totalFundedTab: number;
  profileName: string
}

const FundedTabContent = ({ profileSlug, setIsOrganizationsModalOpen, totalFundedTab, profileName }: IFundedTabContent) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [fundedOrganizations, setFundedOrganizations] = useState<IProfile[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [drawerOpen, setDrawerOpen] = useState<IProfile>();
  const limit = 9;

  const getOrganizations = async () => {
    const data = await PublicAPIService.getFundedTabBySlug(profileSlug, {
      limit,
      offset
    })

    if (data) {
      setFundedOrganizations(data);
      setOffset(offset + limit);
    }
    if (loading) {
      setLoading(false);
    }
  };

  const hasMore = totalFundedTab > offset;

  useEffect(() => {
    getOrganizations();
  }, []);

  return (
    loading ? (
      <Loading />
    ) : (
      <Card paddingX={50} paddingY={40}>
        <Grid item xs={12}>
          <Typography variant="h3" fontSize={24} fontWeight={600}>
            Funded Organizations
          </Typography>
        </Grid>
        {!fundedOrganizations.length ? (
          <Grid item xs={12}>
            <Typography variant="body1" fontSize={16} fontWeight={400} marginTop={2}>
              This Funder has not funded any Applicants yet
            </Typography>
          </Grid>
        ) : (
          <Grid container spacing={3} marginTop={4}>
            {fundedOrganizations.map((grantee) => (
              <Grid item key={grantee.id} md={4} sm={12} xs={12}>
                <ProfileCard
                  profile={grantee}
                  profileType={grantee.funder ? EProfileType.FUNDER : EProfileType.GRANTEE}
                  isAdmin={false}
                  showFunders={false}
                  openDetails={() => setDrawerOpen(grantee!)}
                />
              </Grid>
            ))}
            {drawerOpen && <ProfileDrawer open onClose={() => setDrawerOpen(undefined)} entity={drawerOpen} funderName={profileName}/>}
            {hasMore && (
              <Grid item xs={12} textAlign='center'>
                <Button
                  variant="text"
                  size="medium"
                  sx={{
                    color: "#4288C5",
                    fontSize: "14px",
                    fontWeight: 400,
                    textTransform: "none"
                  }}
                  onClick={() => setIsOrganizationsModalOpen(true)}
                >
                  See all {totalFundedTab} Organizations
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Card>
    )
  );
};

export default FundedTabContent;