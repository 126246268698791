import * as React from "react";

const DisabilitySmall = (props: any) => {
	return (
		<svg width="1em" height="1em" viewBox="0 0 200 200" fill="none" {...props}>
      <g clip-path="url(#clip0_85_9033)">
      <rect width="200" height="200"/>
      <circle cx="100" cy="100" r="100" fill="#0D8991"/>
      <g clip-path="url(#clip1_85_9033)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M92.1928 61.8195C97.4621 61.3305 101.556 56.7674 101.556 51.4302C101.556 45.6855 96.8946 41 91.1795 41C85.4644 41 80.8032 45.6855 80.8032 51.4302C80.8032 53.1821 81.2895 54.9747 82.1002 56.4822L85.7975 108.776L123.876 108.787L139.494 145.571L160 137.487L156.825 129.887L145.349 134.051L130.237 98.9825L94.8324 99.2216L94.3463 92.599L119.977 92.6093V82.8104L93.3686 82.7999L92.1928 61.8195Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M130.49 136.816C124.063 149.587 110.611 158 96.2629 158C75.1884 158 58 140.723 58 119.539C58 104.665 66.9679 90.8436 80.3498 84.7166L81.2158 96.0759C73.3008 101.089 68.4216 110.135 68.4216 119.617C68.4216 134.984 80.8905 147.517 96.1784 147.517C110.165 147.517 122.092 136.728 123.718 122.887L130.49 136.816Z" fill="white"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_85_9033">
      <rect width="200" height="200" fill="white"/>
      </clipPath>
      <clipPath id="clip1_85_9033">
      <rect width="102" height="117" fill="white" transform="translate(58 41)"/>
      </clipPath>
      </defs>
		</svg>
	);
}

export default DisabilitySmall;
