import { Avatar, styled } from '@mui/material'
import { AvatarProps } from './avatar.types'

export const AvatarWrapper = styled(Avatar, {
  shouldForwardProp: (propName) => propName !== 'avatarSize',
})<AvatarProps>(({ avatarSize = 40 }) => ({
  fontSize: 24,
  color: '#515660',
  backgroundColor: 'rgba(215, 220, 239, 0.46)',
  border: '1px solid #DBE4F3',
  width: avatarSize,
  height: avatarSize
}))
