import React, { useCallback } from 'react'
import { SocialMediaWrapper } from './socialMedia.styled'
import { SocialMediaProps } from './socialMedia.types'
// mui components
import { Link } from '@mui/material'
// icons
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { CustomFacebookIcon } from '../../Icon/CustomFacebookIcon'
import { CustomLinkedinIcon } from '../../Icon/CustomLinkedinIcon'
import { CustomGlobeIcon } from '../../Icon/CustomGlobeIcon'

export const SocialMedia: React.FC<SocialMediaProps> = ({ children, socialMedia, ...props }) => {
  const { twitter, instagram, facebook, linkedIn, website } = socialMedia
  
  const renderSocialMediaLinks = useCallback(() => {
    const validUrlTest = new RegExp(/https?:\/\//);

    const classes = {
      icon: {
        backgroundColor: '#15233D',
        padding: '6px'
      },
      customIcon: {
        fill: '#15233D'
      }
    }

    const socialMediaItems = [
      { url: website, icon: <CustomGlobeIcon />, type: 'website' },
      { url: twitter, icon: <TwitterIcon sx={classes.icon} />, type: 'twitter' },
      { url: instagram, icon: <InstagramIcon sx={classes.icon} />, type: 'instagram' },
      { url: facebook, icon: <CustomFacebookIcon sx={classes.customIcon} />, type: 'facebook' },
      { url: linkedIn, icon: <CustomLinkedinIcon sx={classes.customIcon} />, type: 'linkedIn' }
    ]

    return socialMediaItems.map(({ url, icon, type }) => {
      
      if (!url) return null

      const href = validUrlTest.test(url) ? url : `https://${url}`
      return (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={href}
          data-testid={`social-media-${type}`}
        >
          {icon}
        </Link>
      )
    })
  }, [ facebook, instagram, linkedIn, twitter, website])

  return (
    <SocialMediaWrapper data-testid="social-media" {...props}>
      {renderSocialMediaLinks()}
    </SocialMediaWrapper>
  )
}
