import * as React from "react";

function SvgDemocracySmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="democracy-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#democracy-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 4"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm.09 18.53H12a4.11 4.11 0 01-4.13-4.1V8.74a.62.62 0 111.23 0v3.61a.24.24 0 10.47 0V6.62a.61.61 0 011.22 0v5.09A.24.24 0 0011 12a.25.25 0 00.24-.24V6.08a.61.61 0 01.61-.61.62.62 0 01.61.61v5.42a.24.24 0 10.47 0V6.62a.62.62 0 011.23 0v6.52a.24.24 0 10.47 0V11a.74.74 0 111.47 0v3.46a4 4 0 01-4.01 4.07z"
              fill="#008272"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgDemocracySmall;
