import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { UserEntityType } from "../../../shared/enums/user.enum";
import { IUser } from "../../../shared/interfaces/IUser.interface";
import { IUserAssociations } from "../../../shared/interfaces/IUserAssociations.interface";
import { UserAuthService } from "../../../shared/services/api-service/user-auth-api.service";
import { useHistory } from "react-router";
import { useFunderContext } from "../../../funder/providers/UserProvider";
import { Link } from "react-router-dom";
import { OrgStatus } from "../../../shared/enums/grantee-status.enum";
import CloseIcon from '@mui/icons-material/Close';


export function PostLoginScreen({ interactionChoice, setOpen }: { interactionChoice: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {

  const history = useHistory();
  const [user, setUser] = useState<IUser | null>(null);
  const [associations, setAssociations] = useState<IUserAssociations | null>(null);
  const { selectFunder } = useFunderContext();

  function updateEntity(id: number, type: UserEntityType) {
    selectFunder(id);
    UserAuthService.setEntity({ entityId: id.toString(), entityType: type?.toString() });
    localStorage.removeItem('funder-preview');
    localStorage.removeItem('grantee-preview');
  }

  function associationCount(associations: IUserAssociations): number {
    let count = 0;
    for (let p in associations) count += p.length;
    return count;
  }

  function gotoDefaultLocation(associations: IUserAssociations) {

    if (associations.funders.length > 0) {
      history.push('/f');
      return;
    }
    if (associations.grantees.length > 0) {
      history.push('/o');
      return;
    }
  }


  useEffect(() => {
    UserAuthService.verifyLogin().then((userResponse: any) => {
      /*Setting User*/
      setUser(userResponse.user);
      /*Setting User Assosiation*/
      UserAuthService.getAssociations(userResponse.user.id).then((userAssociation) => {
        setAssociations(userAssociation)
        if (associationCount(userAssociation) === 1) {
          return gotoDefaultLocation(userAssociation);
        }
      });
    })
  }, []);

  return associations &&
    <Grid container spacing={3} sx={{ display: interactionChoice ? 'block' : 'none' }}>
      <IconButton sx={{ float: 'right' }} size='small' onClick={() => setOpen(false)} aria-label='close'><CloseIcon /></IconButton>
      <Grid item sm={12}>
        <Typography variant='h4' style={{ fontWeight: 'bold' }}>Welcome, {user?.display_name || user?.first_name}!</Typography>
        <Grid item sm={12} mt='1rem' mb='1rem'>
          <Typography variant='h5'>How would you like to interact with the portal?</Typography>
        </Grid>
        {user?.is_admin ?
          <Grid item sx={{ paddingBottom: '0.5em' }}>
            <Typography variant='h5'>As an Admin:</Typography>
            <Link to='/admin'>Go to Admin</Link>
          </Grid>
          : ''
        }
        {(associations?.funders?.length) ?
          <Grid item sm={12} mb='0.5rem'>
            <Typography variant='h5'>As a Funder:</Typography>
            {associations?.funders.map(f =>
              f.status === OrgStatus.ARCHIVED ? "" :
                <Grid item sm={12} mb='0.5rem' key={f.id}>
                  <a href={`/f/funder/${f.id}`}
                    onClick={() => updateEntity(f?.id, UserEntityType.FUNDER)}>{f.name}</a>
                </Grid>
            )}
          </Grid>
          : ""
        }
        {(associations?.grantees?.length) ?
          <Grid item sm={12} mb='0.5rem'>
            <Typography variant='h5'>As an Organization:</Typography>
            {associations?.grantees.map(g =>
              g.status === OrgStatus.ARCHIVED ? "" :
                <Grid item sm={12} mb='0.5rem' key={g.id}>
                  <a href={`/o`}
                    onClick={() => updateEntity(g?.id, UserEntityType.GRANTEE)}>{g.name}</a>
                </Grid>
            )}
          </Grid>
          : ""
        }
      </Grid>
    </Grid>
}
