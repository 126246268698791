import { Link } from "react-router-dom";
import { Button, Container, Grid, Typography } from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';


interface IBluredInfoProps {
  selectedTab: string;
  name: string;
}

export const BluredInfo = ({ selectedTab, name }: IBluredInfoProps) => {
  return (
    <Container
      sx={{
        backgroundImage: `url(/images/blur-${selectedTab}.png)`,
        backgroundRepeat: 'no-repeat',
        minHeight: '500px',
      }}
    >
      <Grid
        container
        direction='column'
        spacing={1}
        marginTop='24px'
      >
        <Grid item>
          <Typography fontSize={24} fontWeight={600}>
            Sign Up to get full access to JustFund
          </Typography>
        </Grid>
        <Grid item direction='row' display='flex' gap={1} alignItems='center'>
          <GroupsIcon fontSize="small" /> 
          <Typography fontSize={16} fontWeight={400}>
            Are you looking to apply for funding opportunities offered by {name} and more?
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{ width: '120px', fontSize: 'large', marginY: '24px' }}
            onClick={() => window.open('https://justfund.us/sign-up-2', '_top')}
            variant="contained"
            color="secondary"
          >
            Sign Up
          </Button>
        </Grid>
        <Grid item>
          <Typography fontSize={16} fontWeight={400}>
            Already a member?
            <Link
              to={{ pathname: 'https://portal.justfund.us/justfund/signin' }}
              target='_top'
              style={{ color: '#4288C5', marginLeft: '8px'}}
            >
              Login
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={16} fontWeight={400}>
            Are you a funder looking to learn more about JustFund? 
            <Link 
              to={{ pathname: 'https://sales.justfund.us/justfund-request-demo' }}
              target='_top'
              style={{ color: '#4288C5', marginLeft: '8px'}}
            >
              Request a demo.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}