import { styled } from '@mui/material';
import { JFBannerProps } from './JFBanner.types';

export const JFBanner = styled('img')<JFBannerProps>(
  ({ borderRadius = 17, borderRadiusTopOnly = false }) => ({
    width: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadiusTopOnly ? 0 : borderRadius,
    borderBottomRightRadius: borderRadiusTopOnly ? 0 : borderRadius,
  })
);
