import React from "react";
import { socialMedia } from "./socialMedia";
import { SocialIcon } from './SocialIcon';
import { Box } from "@mui/material";

const classes = {
  svgIcon: {
    marginRight: '0.7em',
    marginTop: '0.7em',
  },

  socialContainer: {
    display: 'flex',
  }

};

export const SocialBar = () => {
  
  const { twitter, facebook, instagram, linkedin } = socialMedia;

  return (
    <Box component="div" sx={classes.socialContainer}>
      <Box component='a' href={twitter} sx={classes.svgIcon}>
        <SocialIcon url={'/images/icon_white_twitter.svg'} />
      </Box>
      <Box component='a' href={facebook} sx={classes.svgIcon}>
        <SocialIcon url={'/images/icon_white_facebook.svg'} />
      </Box>
      <Box component='a' href={instagram} sx={classes.svgIcon}>
        <SocialIcon url={'/images/icon_white_instagram.svg'} />
      </Box>
      <Box component='a' href={linkedin} sx={classes.svgIcon}>
        <SocialIcon url={'/images/icon_white_linkedin.svg'} />
      </Box>
    </Box>
  );
}