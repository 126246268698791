import * as React from "react";

function SvgHumanSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="human-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#human-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#ea622d">
            <circle
              data-name="Ellipse 1"
              cx="0.48"
              cy="0.48"
              r="0.48"
              transform="translate(11.52 6.3)"
            />
            <path
              data-name="Path 25"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zM7.37 13.9a2.24 2.24 0 01-2.21-1.76.42.42 0 01.08-.36.4.4 0 01.33-.17h3.62a.43.43 0 01.41.54 2.26 2.26 0 01-2.22 1.75zM15 18.55H9a.42.42 0 01-.42-.34.43.43 0 01.24-.48l2.76-1.27V9.83H7.81v.93H7V9.4a.43.43 0 01.38-.4h4.19V8a1.34 1.34 0 11.86 0v1h4.19a.43.43 0 01.43.43v1.36h-.86v-.96h-3.76v6.63l2.76 1.27a.43.43 0 01-.18.82zm3.83-6.4a2.26 2.26 0 01-2.22 1.75 2.26 2.26 0 01-2.22-1.76.39.39 0 01.09-.36.4.4 0 01.33-.17h3.62a.4.4 0 01.33.17.38.38 0 01.08.37z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgHumanSmall;
