import React, { createContext, Dispatch, useContext, useEffect, useState } from 'react';
import { IRfp } from '../../shared/interfaces/IRfp.interfaces';
import { IUser } from '../../shared/interfaces/IUser.interface';
import { RfpService } from '../../shared/services/api-service/rfp-api.service';
import { UserService } from '../../shared/services/api-service/user-api.service';

type AdminContextProvider = {
  selectUser: (id: number) => Promise<void>;
  user: IUser | null,
  adminRfp: IRfp | null,
  selectRfp: (id: number) => void,
}

const defaultContext: AdminContextProvider = {
  selectUser: () => Promise.reject('Not Implemented'),
  user: null,
  adminRfp: null,
  selectRfp: () => { },
}

// This is just scaffolding in case we need some context for the admin ... update as needed following similar patterns to funder/providers/UserProvider.tsx
const AdminContext = createContext<AdminContextProvider>(defaultContext);

export const useAdminContext = () => useContext(AdminContext);

export default function AdminProvider({ children }: { children: React.ReactChild }) {

  const [user, setUser] = useState<IUser | null>(null);
  const [adminRfp, setRfp]: [IRfp | undefined, Dispatch<React.SetStateAction<IRfp | undefined>>] = useState();

  const selectUser = async (id: number) => {
    try {
      const selected = await UserService.getById(id);
      setUser(selected);
    } catch (exc) {
      console.error(exc);
    }
  }

  const selectRfp = async (id: number) => {
    // if (id === 0) {
    //   setRfp(null)
    // }
    const newRfp = await RfpService.getById(id);
    setRfp(newRfp);
    return;
  }

  const context = {
    selectUser,
    user,
    adminRfp: adminRfp ? adminRfp : null,
    selectRfp,
  };

  return (
    <AdminContext.Provider value={context}>
      {children}
    </AdminContext.Provider>
  )

}