import { Button, Box, styled, Drawer, List, ListItem } from '@mui/material'

const classes = {
  typographyStyle: {
    textTransform: 'none',
    justifyContent: 'flex-start'
  },
  btnRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: '900'
  },
  listItem: {
    padding: '10px 12px',
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)'
  }
};

interface mobileProps {
  open: boolean;
  mobileOpen: () => void;
}

export const PublicMobileMenu = ({ mobileOpen, open }: mobileProps) => {
  const Ul = {
    display: 'flex',
    flexFlow: 'row nowrap',
    listStyle: 'none',

    '@media (max-width: 900px)': {
      transform: open ? 'translateX(0)' : 'translateX(100%)',
      color: '#ffffff',
      flexFlow: 'column nowrap',
      height: '100%',
      position: 'fixed',
      paddingTop: '3.5rem',
      right: '0',
      top: '0',
      transition: 'transform 0.3s ease -in -out',
      width: '300px',
    }
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  return (
    <Drawer variant='persistent' anchor='right' sx={{
      ...Ul,
    }} open={open}>
      <Box>
        <DrawerHeader />
        <List>
          <ListItem sx={classes.listItem}>
            <Button
              onClick={() => window.open('/justfund/signin')}
              sx={classes.typographyStyle}
              color='secondary'
              fullWidth={true}
              size='medium'
              variant='text'
            >Sign In</Button>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Button
              onClick={() => window.open('https://justfund.us/sign-up/')}
              sx={classes.typographyStyle}
              fullWidth={true}
              color='secondary'
              size='medium'
              variant='text'
            >Sign Up</Button>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Button
              onClick={() => window.open('https://help.justfund.us/')}
              sx={classes.typographyStyle}
              fullWidth={true}
              color='secondary'
              size='medium'
              variant='text'
            >
              Help
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )

}