import { AxiosRequestConfig } from "axios";
import { api } from "./api.service";
import { IProfile } from "../../interfaces/IProfile";
import { IPaginationRequest } from "../../interfaces/IPaginationRequest";

export class PublicAPIService {
    private static baseRoute = '/api/public'

    // can make signal required at a different point - few different spots in the app that use this
    public static async funderSlug(slug: string, signal: AxiosRequestConfig): Promise<any> {
        const res = await api.get(`${this.baseRoute}/${slug}`, { ...signal })
        return res.data as any;
    }

    public static async getFunderBySlug(slug: string, signal: AxiosRequestConfig): Promise<any> {
        const res = await api.get(`${this.baseRoute}/funder?slug=${slug}`, { ...signal })
        return res.data;
    }

    public static async getFundedTabBySlug(slug: string, pagination?: IPaginationRequest): Promise<IProfile[]> {
        let params: AxiosRequestConfig['params'] = {
            offset: pagination?.offset ?? 0,
            limit: pagination?.limit ?? 10
        };
        if(pagination?.sortFields) {
            params.sortFields = pagination.sortFields;
        }

        const res = await api.get(`${this.baseRoute}/funded-tab?slug=${slug}`, { params });
        return res.data as IProfile[];
    }

}