import React from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material'
import { LinkExternal } from './LinkExternal';

function LinkRenderer (p) {
  return <LinkExternal href={p.href}>{ p.children }</LinkExternal>;
}

export const Markdown = (props) => (
  <StyledReactMarkdown
    {...props}
    renderers={{ link: LinkRenderer }}
  />
);

const StyledReactMarkdown = styled(ReactMarkdown)(({}) => ({
  'h3': {
    fontFamily: 'Proxima Nova, serif',
    fontWeight: '600',
    fontSize: '1.3rem'
  }
}));
