import React, { Fragment } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { useHistory } from 'react-router-dom';

const location = window.location;

const StyledAppBar = {
  minHeight: '52px',
  backgroundColor: '#ffffff !important',
  webkitBoxShadow: '0px 1px 1px #eeeeee',
  mozBoxShadow: '0px 1px 1px #eeeeee',
  boxShadow: '0px 1px 1px #eeeeee',
  'html.translated-ltr&': {
    paddingTop: '40px'
  }
};

const StyledToolbar = {
  height: '52px',
  width: '100%',
  maxWidth: '1024px',
  margin: '0 auto'
};

export interface NavProps {
  showSignIn?: boolean;
}
export const Nav = ({ showSignIn }: NavProps = { showSignIn: true }) => {
  const history = useHistory();

  return (
    <Fragment>
      <AppBar sx={StyledAppBar} position="fixed">
        <Toolbar sx={StyledToolbar}>
          <img
            src="/images/justfund.svg"
            height="36px"
            width="auto"
            alt="JustFund logo"
            style={{ cursor: 'pointer' }}
            onClick={() => location.replace('https://justfund.us')}
          />
          <div style={{ flex: '1 1 auto' }} />
          {showSignIn && (
            <Box sx={{ align: 'right' }}>
              <Button
                onClick={() => {
                  history.push('/justfund/signin');
                }}
                variant="text"
                color="primary"
              >
                SIGN IN
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
};
