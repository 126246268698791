import { IInviteUser, IUser } from '../../interfaces/IUser.interface';
import { api } from './api.service';
import { IListResults } from '../../interfaces/IGenerics.interfaces';
import { IEntityFollow, IFollow } from '../../interfaces/IFollow';
import { AxiosRequestConfig } from 'axios';
import { IBloatedFunder } from '../../interfaces/IFunder';
import { IProfile } from '../../interfaces/IProfile';

export class UserService {

  private static baseRoute = '/api/users';

  public static async list(): Promise<IListResults<IUser>> {
    const res = await api.get(`${this.baseRoute}`);
    return res.data as IListResults<IUser>;
  }

  public static async getById(id: number): Promise<IUser> {
    const res = await api.get(`${this.baseRoute}/${id}`);
    return res.data as IUser;
  }

  public static async editUser(id: number, user: Partial<IUser>): Promise<IUser> {
    const res = await api.put(`${this.baseRoute}/${id}`, user);
    return res.data as IUser;
  }

  public static async forcePasswordReset(formData: {password: string}): Promise<IUser> {
    const res = await api.post(`${this.baseRoute}/force-password-reset`, formData);
    return res.data as IUser;
  }

  public static async checkEmail(email: string): Promise<boolean> {
    const res = email ? await api.get(`${this.baseRoute}/check-email/${email}`) : await Promise.resolve({ data: true });
    return res.data;
  }

  public static async register(data: Partial<IUser>) {
    const res = await api.post(`${this.baseRoute}/register`, data);
    return res.data as Partial<IUser>;
  }

  public static async create(user: IInviteUser): Promise<IUser> {
    const res = await api.post(`${this.baseRoute}/`, user);
    return res.data as IUser;
  }

  public static async getAssociations(id: number): Promise<any> {
    const res = await api.get(`${this.baseRoute}/${id}/associations`);
    return res.data as any;
  }

  public static async getFollowing(config?: AxiosRequestConfig): Promise<IFollow[]> {
    const res = await api.get(`${this.baseRoute}/following`, config);
    return res.data as IFollow[];
  }

  public static async followEntity(entity: IEntityFollow): Promise<IFollow> {
    const res = await api.post(`${this.baseRoute}/follow`, entity);
    return res.data as IFollow;
  }

  public static async unfollowEntity(entity: IEntityFollow): Promise<void> {
    await api.post(`${this.baseRoute}/unfollow`, entity);
  }

  public static async getBookmarkedFunders(options: AxiosRequestConfig = {}): Promise<IBloatedFunder[]> {
    const res = await api.get(`${this.baseRoute}/bookmarked-funders`, options);
    return res.data as IBloatedFunder[];
  }

  public static async getSuggestedOrganizations(): Promise<IProfile[]> {
    const res = await api.get(`${this.baseRoute}/suggested-organizations`);
    return res.data as IProfile[];
  }

  public static async getSuggestedFunders(): Promise<IProfile[]> {
    const res = await api.get(`${this.baseRoute}/suggested-funders`);
    return res.data as IProfile[];
  }

  public static async getSuggestedProfiles(options: AxiosRequestConfig = {}): Promise<IProfile[]> {
    const res = await api.get(`${this.baseRoute}/suggested-profiles`, options);
    return res.data as IProfile[];
  }
}