import * as React from "react";

function SvgHealthSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="health-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#health-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#1444a3">
            <path
              data-name="Path 22"
              d="M15.06 11.3h-2v-2a.12.12 0 00-.06-.08h-1.9a.12.12 0 00-.13.12v2H9a.12.12 0 00-.13.12v1.9a.12.12 0 00.13.12h2v2a.13.13 0 00.13.13H13a.13.13 0 00.12-.13v-2h2a.12.12 0 00.12-.12v-1.9a.12.12 0 10-.18-.16z"
            />
            <path
              data-name="Path 23"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm6.08 10.83c0 6.82-6 7.58-6 7.59-.06 0-6-.77-6-7.59V8.67a.14.14 0 010-.09h.09A10.18 10.18 0 0012 6.35a.11.11 0 01.15 0A10.18 10.18 0 0018 8.54a.14.14 0 01.13.13z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgHealthSmall;
