export enum RubricScoreStatus {
  DRAFT = 0,
  SUBMITTED = 1
}

export enum ApplicationScoringStatus {
  NOT_APPLY = -1,
  PENDING = 0,
  ACCEPTED = 1,
  DECLINED = 2
}

export enum GrantCycleScoringStatus {
  IN_REVIEW = 1,
  INACTIVE = 0,
  ALL = -1
}

export enum EUpdateScorersAction {
  BULK_ADD = 'bulk_add',
  BULK_REMOVE = 'bulk_remove',
  EDIT = 'edit',
  TOP_EDIT = 'top_edit'
}

export enum AVG_SCORE_FILTER {
  ALL,
  LESS_THAN,
  GREATER_THAN
}

export enum ReviewersInfoTooltipSteps {
  NONE,
  STEP_ONE,
  STEP_TWO,
  STEP_THREE
}