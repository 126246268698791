import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Grid, IconButton, styled, Toolbar } from '@mui/material';
import { Logo } from '../../../common/logo/Logo';
import HelpIcon from '@mui/icons-material/Help';
import { theme } from '../../../shared/util/util';
import { PublicMobileMenu } from './PublicMobileMenu';
import { MobileLogo } from '../../../common/logo/MobileLogo';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
  btnRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: '900'
  },
  typographyStyle: {
    textTransform: 'none',
    fontSize: '14px !important',
    color: '#000'
  },
  typographyButtonStyle: {
    fontWeight: 'bold !important',
    textTransform: 'none',
    fontSize: '14px !important',
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#091b30'
  },
  linkColor: {
    color: '#000'
  },
  styledNav: {
    height: '52px',
    width: '100%',
    maxWidth: '1024px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      display: 'none !important' // Override MUI styles
    }
  }

};

export const PublicNav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const onMenuMobileClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  };

  const StyledMobileBar = styled('div')(({ theme }) => ({
    width: '2rem',
    height: '2rem',
    position: 'fixed',
    top: '15px',
    right: '20px',
    zIndex: 20,
    display: 'none',
    div: {
      width: '12rem',
      height: '-1rem',
      backgroundColor: '#ffffff',
      borderRadius: '0px',
      transformOrigin: '1px',
      transition: 'all 0.3s linear'

    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
      flexFlow: 'column nowrap',
    },
  }));


  const StyledMobileLogo = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex'
    }
  }));

  return (
    <Box sx={{ displayPrint: 'none' }}>
      <AppBar component='nav' color='default'>
        <StyledMobileLogo>
          <MobileLogo img='/images/justfund.svg' to='/o' />
        </StyledMobileLogo>
        <StyledMobileBar>
          <IconButton onClick={onMenuMobileClick} sx={classes.btnRight} size='large'>
            {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <PublicMobileMenu open={mobileMenuOpen} mobileOpen={onMenuMobileClick} />
        </StyledMobileBar>
        <Toolbar sx={classes.styledNav}>
          <Link to={{ pathname: 'https://justfund.us/' }} target='_blank' rel= "noopener noreferrer">
            <Logo img='/images/justfund.svg' mlf='-1em' />
          </Link>
          <Grid container spacing={1} alignItems='center' justifyContent='flex-end' flexDirection='row' width='100%' sx={classes.typographyStyle}>
            <Grid item xs={1}>
              <Button sx={classes.typographyStyle} variant='text' color='primary'>
                <Link to='/justfund/signin' style={classes.linkStyle} target='_blank'>Sign In</Link>
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button sx={classes.typographyStyle} variant='text' color='primary'>
                <Link to={{ pathname: 'https://justfund.us/sign-up/' }} style={classes.linkStyle} target='_blank' rel= "noopener noreferrer">Sign Up</Link>
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Link to={{ pathname: 'https://help.justfund.us/' }} style={classes.linkColor} target='_blank' rel= "noopener noreferrer">
                <HelpIcon />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box >
  );
};
