export enum BipocLed {
  NO = 0,
  YES = 1,
  NOTSURE = 2
}

export const BipocLedOptions = [
  { value: BipocLed.NO, label: 'No' },
  { value: BipocLed.YES, label: 'Yes' },
  { value: BipocLed.NOTSURE, label: 'Not Sure' }
];
