import axios, { AxiosResponse } from "axios";
import { UserAuthService } from "./user-auth-api.service";

/**
 * This file doesn't do much. It's just a wrapper for axios that only exists to:
 * 1 - Set the base URL on axios from environment vars
 * 2 - Stuff the `Authorization` header in before a request goes out
 * 3 - Maybe other stuff later
 */
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;


// add an interceptor to check for a `response` in the error and throw that if it exists
axios.interceptors.response.use(
  response => response,
  error => {
    console.error(error)
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

// Secondary usage of axios in case we need one without auth headers .. i.e. for login.
export const axiosService = axios;

// Export our custom axios instance with auth headers added
export const api = axios.create({
  // baseURL: "/api",
  timeout: 60 * 1000
});

api.interceptors.request.use(async (config) => {
  
  let token = UserAuthService.token;

  if (UserAuthService.tokenIsExpired() && token !== null && !config.url?.includes('api/auth/refresh') && !config.url?.includes('api/auth/login')) {
    await UserAuthService.exchangeToken();
    token = UserAuthService.token;
  }
  
  const { entityType, entityId } = UserAuthService.getEntity();
  const newConfig = {
    ...config,
  }

  if (!newConfig.headers) newConfig.headers = {};

  newConfig.headers.Authorization = `Bearer ${token}`;
  newConfig.headers['entity-type'] = entityType || '';
  newConfig.headers['entity-id'] = entityId || '';

  return newConfig;
}, (exc) => Promise.reject(exc));

api.interceptors.response.use((res: AxiosResponse) => {
  return res
}, (err) => {
  return Promise.reject(err);
});
