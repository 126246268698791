import { Avatar, AvatarGroup, Button, Divider, Grid, Typography, styled } from '@mui/material'

export const ProfileCardWrapper = styled(Grid)`
  & .MuiTypography-root {
    color: #515660;
  }
`

export const ProfileCardTitle = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: black !important;
`

export const ProfileCardFocusesWrapper = styled(Grid)`
  min-height: 42px;
`

export const ProfileCardDescriptionWrapper = styled(Grid)`
  height: 51px;
  margin: 12px 0px;
  color: #757575;
`

export const ProfileCardDescription = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #757575 !important;
`

export const ProfileCardDividiver = styled(Divider)`
  width: 100%;
  border-color: '#E7E7E7';
`

export const FundButton = styled(Button)`
  border-radius: 29px;
  background-color: #4288C5;
  color: #FFF;
  font-weight: 600;
  font-size: 14px;
  padding: 3px 18px;
  margin-right: 3px;
  line-height: 1.5;

  &:hover {
    background-color: #24659e;
  }
`

const commonAvatarProps = `
  background-color: #DBE4F3;
  color: #7C94BD;
  font-weight: 500;
`

export const FundersAvatarGroup = styled(AvatarGroup)`
  .MuiAvatar-root {
    ${commonAvatarProps}
    border: none;
    height: 26px;
    width: 26px;
    font-size: 14px;
  }
`

export const ProfileCardTitleAvatar = styled(Avatar)`
  ${commonAvatarProps}
  height: 64px;
  width: 64px;
  font-size: 16px;
`