import * as React from "react";

function SvgCriminalSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="criminal-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#criminal-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#23286c">
            <path data-name="Rectangle 1" d="M9.13 11.19h1.02v3.61H9.13z" />
            <path data-name="Rectangle 2" d="M11.49 11.19h1.02v3.61h-1.02z" />
            <path
              data-name="Path 1"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.26 17.22a.32.32 0 01-.25.12H7a.32.32 0 01-.26-.12.34.34 0 01-.07-.22L7 15.07a.34.34 0 01.33-.28h.47v-3.6H7a.308.308 0 01-.21-.56l5-3.86a.34.34 0 01.41 0l5 3.86a.34.34 0 01-.2.6h-.8v3.6h.46a.34.34 0 01.33.28l.34 1.89a.34.34 0 01-.07.22z"
            />
            <path data-name="Rectangle 3" d="M13.85 11.19h1.02v3.61h-1.02z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgCriminalSmall;
