import * as React from "react";

const MediaSmall = (props: any) => {
	return (
    <svg width="1em" height="1em" viewBox="0 0 200 200" fill="none" {...props}>
      <g clip-path="url(#clip0_85_9032)">
      <rect width="200" height="200"/>
      <circle cx="100" cy="100" r="100" fill="#F73D5E"/>
      <path d="M121.778 95.6285C126.072 94.6104 130.106 92.5259 133.446 89.4861L90.0901 63.9991C89.0202 68.3749 89.1278 72.8955 90.3009 77.1278L121.778 95.6285Z" fill="white"/>
      <path d="M99.5515 117.579L80.1764 106.192L58.0002 145.825L75.3083 156L99.5515 117.579Z" fill="white"/>
      <path d="M136.482 86.2041L91.5193 59.7724C91.9521 58.7821 92.4514 57.8075 93.0184 56.854C100.417 44.4134 116.558 40.2913 129.071 47.6471C141.584 55.0029 145.73 71.0511 138.332 83.4917C137.765 84.4452 137.146 85.3498 136.482 86.2041Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M80.8444 75.6898L126.132 102.307L114.332 122.152L69.0446 95.5348L80.8444 75.6898ZM101.588 99.5516C101.588 102.245 99.392 104.428 96.6831 104.428C93.9741 104.428 91.7781 102.245 91.7781 99.5516C91.7781 96.8584 93.9741 94.675 96.6831 94.675C99.392 94.675 101.588 96.8584 101.588 99.5516Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_85_9032">
      <rect width="200" height="200" fill="white"/>
      </clipPath>
      </defs>
    </svg>

	);
}

export default MediaSmall;
