import { Box, Button, Modal, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { INotificationState } from './Notification';

interface PopupUpdateRenderProps {
  notificationState: INotificationState;
  handleClose: (val: boolean, reason?: string) => void;
}

const PopupUpdateRender: FC<PopupUpdateRenderProps> = ({
  notificationState,
  handleClose
}) => {
  const {
    open,
    title,
    message,
    component,
    removeCancelBtn,
    customBtnText,
    customComponent,
    reason,
    customTitle
  } = notificationState;

  const paper = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    borderRadius: '16px',
    background: 'var(#FFFFFF, #FFF)',
    '@media (max-width: 900px)': {
      top: '8em',
      left: 'auto',
      margin: 'auto',
      transform: 'none'
    }
  };

  const infoIconStyle = {
    color: 'black',
    fontSize: '25px',
    verticalAlign: 'text-top'
  };

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={() => handleClose(false, reason)}
      aria-describedby={'notification-title'}
      style={{
        outline: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={paper}
      style={{
        outline: 'none',
        border: 'none !important',
        maxWidth: '480px',
      }}>
        <Grid
          container
         
        >
          <Grid item xs={12}>
            <Box sx={{ p: 4, paddingY: 3 }}>
              <Typography variant="h5">
                {customTitle || 
                <>
                  <InfoOutlinedIcon style={infoIconStyle} />
                  <span id="notification-title" style={{ fontSize: '23px', marginLeft: '8px' }}>
                    {title}
                  </span>
                </>
                }
              </Typography>
            </Box>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Box sx={{ px: 4, pt: 4 }}>
                {typeof message === 'string' ? (
                  <Typography component="p">{message}</Typography>
                ) : (
                  message
                )}
              </Box>
            </Grid>
          )}
          {component && (
            <Grid item xs={12}>
              <Box sx={{ px: 4 }}>
                <Typography component="p">{component}</Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ px: 4 }}>
              <>{customComponent}</>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 4,
                paddingBottom: 3,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  {removeCancelBtn ? null : (
                    <Button
                      variant="outlined"
                      onClick={() => handleClose(false, reason)}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    data-testid="popup_update_cta_btn"
                    sx={{
                      textTransform: 'capitalize',
                      borderRadius: '120px',
                      paddingRight: '28px',
                      paddingLeft: '28px',
                      color: 'white',
                      fontSize: '17px',
                      fontWeight: '600',
                      backgroundColor: '#0b61b0 !important'
                    }}
                    onClick={() => handleClose(true, reason)}
                  >
                    {customBtnText ? customBtnText : 'Ok'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PopupUpdateRender;
