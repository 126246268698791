import { Box } from '@mui/material';
import React from 'react';

interface SocialIconProps {
  url: string;
}
export const SocialIcon = ({ url }: SocialIconProps) => {
  return (
    <Box component="div">
      <Box component="img"
        src={url}
        width= "30px"
        height= "30px"    
        style={{ cursor: 'pointer' }}
        alt="logo" />
    </Box>
  );

}
