import { CommonIcon } from '../../../../../common/Icon/types';

export const DiscoverIcon = ({ size = 32, color = '#25282B' }: CommonIcon) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7082 24.8702C20.3201 24.8702 24.8695 20.3208 24.8695 14.7089C24.8695 9.09697 20.3201 4.54761 14.7082 4.54761C9.09624 4.54761 4.54688 9.09697 4.54688 14.7089C4.54688 20.3208 9.09624 24.8702 14.7082 24.8702Z"
      stroke={color}
      strokeWidth="1.12903"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.408 27.4105L21.8828 21.8853"
      stroke={color}
      strokeWidth="1.12903"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7287 23.8213C25.6834 20.5746 27.068 13.926 23.8212 8.97132C20.5745 4.01663 13.9259 2.63208 8.97124 5.87882C4.01656 9.12556 2.63201 15.7741 5.87875 20.7288C9.12549 25.6835 15.7741 27.0681 20.7287 23.8213Z"
      stroke={color}
      strokeWidth="1.12903"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9947 24.2161C25.9469 20.971 27.271 14.2347 23.9523 9.17016C20.6335 4.10559 13.9286 2.63062 8.97644 5.87571C4.02428 9.1208 2.70014 15.8571 6.01889 20.9217C9.33764 25.9862 16.0425 27.4612 20.9947 24.2161Z"
      stroke={color}
      strokeWidth="1.12903"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8429 27.7406L22.3625 23.1744C22.2058 23.0146 22.2015 22.7602 22.3527 22.5953C22.504 22.4304 22.7579 22.4127 22.9305 22.5551L27.8655 26.6259C28.2003 26.902 28.2351 27.4024 27.9417 27.7222C27.6484 28.042 27.1468 28.0504 26.8429 27.7406Z"
      fill={color}
      stroke={color}
      strokeWidth="1.12903"
      strokeLinejoin="round"
    />
  </svg>
);
