import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { PageHeader } from '../../group/components/pageHeader';

// Content displayed for 404
export const NotFoundPage = () => {
  const history = useHistory ();
  return (
    <>
      <PageHeader crumbs={[{ text: 'Home', link: '/o' }, { text: 'Page Not Found' }]} />
      <Box maxWidth='1024px' mx='auto' p='20px 4px'>
        <Typography variant='h1'>Page Not Found</Typography>
        <Box textAlign='center' mt='20px'>
          <Typography paragraph>Sorry, could not find that page for you.</Typography>
          <Button type='button' onClick={() => history.push ('/o/')}>
            HOME
          </Button>
        </Box>
      </Box>
    </>
  );
};