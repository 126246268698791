import { FunderServiceLevel } from '../../../../shared/enums/funder/funder-enums';

const allowedServiceLevels = [
  FunderServiceLevel.Standard,
  FunderServiceLevel.Institution,
  FunderServiceLevel.FullService,
  FunderServiceLevel.Enterprise
];

export class Utils {
  public static allowedCheckInFeatures(level: FunderServiceLevel) {
    return level ? allowedServiceLevels.includes(level) : false;
  }
}
