import React from 'react';
import { Box, Link } from '@mui/material';
import { LinkExternal, LinkInternal } from '../../../shared/components/legacy-ui';

export const PrivacyNotice = () => (
  <Box maxWidth={1024} mx='auto' pt='20px'>
    <h1>Privacy Policy</h1>
    <p>Last Modified: September 1, 2020</p>

    <p>&nbsp;</p>

    <p>
      The following sets for the Privacy Policy (“<b>Privacy Policy</b>”) of JustFund.us, a project of the
      Proteus Fund (“<b>Proteus</b>”, “<b>we</b>” or “<b>our</b>”), and is incorporated into and made
      a part of Proteus’ Terms of Use, available at{' '}
      <LinkInternal href='/justfund/terms'>
        https://www.justfund.us/justfund/terms
      </LinkInternal>.
    </p>

    <p>&nbsp;</p>

    <p>The privacy of your personal information (in some countries
      referred to as “personal data” or “personally identifiable information”) is
      important to us. We respect your privacy and are committed to protecting it
      through our compliance with this Privacy Policy.
    </p>

    <p>&nbsp;</p>

    <p>To better protect your privacy, we provide you with our
      Privacy Policy so that you will understand our commitment to you and to your
      privacy. This Privacy Policy describes what information we may collect about
      you; how we use your information; how we protect it; and what choices you have
      about how that information is used. At Proteus we want you to feel as
      comfortable as possible visiting the JustFund.us Portal and using our services.
    </p>

    <p>&nbsp;</p>

    <p>As our Privacy Policy changes in significant ways, we will
      make every effort to notify you of the changes. Minor changes to the policy
      may occur that will not affect our use of individually identifiable or personal
      data. As new features and functionalities are added, we may need to update or
      review the Privacy Policy and reserve the right to do so at any time, without
      prior notice and by posting the revised version. In case we modify the policy
      in a way that your awareness of the changes shall be required, we will provide
      notice at the top of the Privacy Policy for at least 30 days after the new
      effective date so that you can see at a glance that the Policy has changed. In
      other cases we may specifically ask your consent. We will post Privacy Policy
      changes on our website in a timely manner.
    </p>

    <p>&nbsp;</p>

    <h2>Introduction</h2>

    <p>&nbsp;</p>

    <p>This policy describes the types of information we may
      collect from you or that you may provide when you visit the
      website <LinkExternal href='https://www.justfund.us'>www.justfund.us</LinkExternal> and
      any applicable subdomains thereof, and through any applications, mobile
      applications, functionalities, content, materials, or other online services
      provided by Proteus (the “<b>Portal</b>”)
      and our practices for collecting, using, maintaining, protecting and disclosing
      that information.
    </p>

    <p>&nbsp;</p>

    <p>Specifically, this policy applies to information we collect:</p>

    <ul>
      <li>On this Portal.</li>
      <li>In e-mail, text, and other electronic messages between you and this Portal</li>
    </ul>

    <p>&nbsp;</p>

    <p>It does not apply to information collected by:</p>

    <ul>
      <li>Us offline or through any other means, including on any other
        website operated by Proteus or any third party (including our affiliates and
        subsidiaries); or
      </li>

      <li>Any third party (including our affiliates and subsidiaries),
        including through any application or content that may link to or be accessible
        from or on the Portal.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>Please read this policy carefully to understand our policies
      and practices regarding your information and how we will treat it. If you do
      not agree with our policies and practices, your choice is not to use our
      Portal. By accessing or using this Portal, you agree to this privacy policy.
      This policy may change from time to time. Your continued use of this Portal
      after we make changes is deemed to be acceptance of those changes, so please
      check the policy periodically for updates.
    </p>

    <p>&nbsp;</p>

    <h2>Children Under the Age of 18</h2>

    <p>&nbsp;</p>

    <p>Our Portal is not intended for children under 18 years of
      age. No one under age 18 may provide any personal information to or on the
      Portal. We do not knowingly collect personal information from children under
      18. If you are under 18, do not use or provide any information on this Portal
      or on or through any of its features, register on the Portal, make any
      purchases through the Portal, use any of the interactive or public comment
      features of this Portal or provide any information about yourself to us, including
      your name, address, telephone number, e-mail address or any screen name or user
      name you may use. If we learn we have collected or received personal
      information from a child under 18 without verification of parental consent, we
      will delete that information. If you believe we might have any information
      from or about a child under 18, please contact us
      at <Link href='mailto:info@justfund.us'>info@justfund.us</Link>.
    </p>

    <p>&nbsp;</p>

    <h2>Information We Collect About You and How We Collect It</h2>

    <p>&nbsp;</p>

    <p>We collect several types of information from and about users
      of our Portal, including information:
    </p>

    <ul>
      <li>By which you or your organization may be personally identified,
        such as name, postal address, email address, telephone number or ANY OTHER
        INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED AS PERSONAL OR PERSONALLY
        IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW (“<b>personal information</b>”);
      </li>

      <li>That is about you but individually does not identify you; and/or</li>

      <li>About your internet connection, the equipment you use to access our Portal, and usage details.</li>
    </ul>

    <p>&nbsp;</p>

    <p>We collect this information:</p>

    <ul>
      <li>Directly from you when you provide it to us.</li>

      <li>Automatically as you navigate through the site. Information
        collected automatically may include usage details, IP addresses and information
        collected through cookies, web beacons and other tracking technologies.
      </li>

      <li>From third parties, for example, our business partners.</li>
    </ul>

    <p>&nbsp;</p>

    <h2>Information You Provide to Us</h2>

    <p>&nbsp;</p>

    <p>The information we collect on or through our Portal may include:</p>

    <ul>
      <li>Information that you provide by filling in forms or uploading
        information or documentation through our Portal.
      </li>

      <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>

      <li>Your responses to surveys that we might ask you to complete for research purposes.</li>

      <li>Details of transactions you carry out through our Portal and of
        the fulfillment of your membership registration. You may be required to
        provide financial information before registering as a member with our Portal.
      </li>

      <li>Your search queries on the Portal.</li>
    </ul>

    <p>&nbsp;</p>

    <p>You also may provide information to be published or
      displayed (hereinafter, “<b>posted</b>”)
      on publicly viewable areas of the Portal, also known as “<b>Community Activity</b>”,
      or transmitted to other users of the Portal or third parties
      (collectively, “<b>User Contributions</b>”). Your User
      Contributions are posted on and transmitted to others at your own risk.
      Although we limit access to certain pages, please be aware that no security
      measures are perfect or impenetrable. Additionally, we cannot control the
      actions of other users of the Portal with whom you may choose to share your
      User Contributions. Therefore, we cannot and do not guarantee that your User
      Contributions will not be viewed by unauthorized persons.
    </p>

    <p>&nbsp;</p>

    <h2>Information We Collect Through Automatic Data Collection Technologies</h2>

    <p>&nbsp;</p>

    <p>As you navigate through and interact with our Portal, we may
      use automatic data collection technologies to collect certain information about
      your equipment, browsing actions and patterns, including:
    </p>

    <ul>
      <li>Details of your visits to our Portal, including traffic data,
        location data, logs and other communication data and the resources that you
        access and use on the Portal.
      </li>

      <li>Information about your computer and internet connection,
        including your IP address, operating system and browser type.
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>We also may use these technologies to collect information
      about your online activities over time and across third-party websites or other
      online services (behavioral tracking). The information we collect automatically
      is statistical data and does not include personal information, but we may
      maintain it or associate it with personal information we collect in other ways
      or receive from third parties. It helps us to improve our Portal and to
      deliver a better and more personalized service, including by enabling us to:
    </p>

    <ul>
      <li>Estimate our audience size and usage patterns.</li>

      <li>Store information about your preferences, allowing us to customize our
        Portal according to your individual interests.
      </li>

      <li>Speed up your searches.</li>

      <li>Recognize you when you return to our Portal.</li>
    </ul>

    <p>&nbsp;</p>

    <p>The technologies we use for this automatic data collection may include:</p>

    <ul>
      <li><b>Cookies (or browser cookies).</b> A cookie is a small file
        placed on the hard drive of your computer. You may refuse to accept browser
        cookies by activating the appropriate setting on your browser. However, if you
        select this setting you may be unable to access certain parts of our Portal.
        Unless you have adjusted your browser setting so that it will refuse cookies,
        our system will issue cookies when you direct your browser to our Portal.
      </li>

      <li><b>Flash Cookies.</b> Certain features of our Portal may use
        local stored objects (or Flash cookies) to collect and store information about
        your preferences and navigation to, from and on our Portal. Flash cookies are
        not managed by the same browser settings as are used for browser cookies.
      </li>

      <li><b>Web Beacons.</b> Pages of our Portal and our e-mails may
        contain small electronic files known as web beacons (also referred to as clear
        gifs. pixel tags and single-pixel gifs) that permit Proteus, for example, to
        count users who have visited those pages or opened an e-mail and for other
        related website statistics (for example, recording the popularity of certain
        website content and verifying system and server integrity).
      </li>
    </ul>

    <p>&nbsp;</p>

    <p>We do not collect personal information automatically, but we
      may tie this information to personal information about you that we collect from
      other sources or you provide to us.
    </p>

    <p>&nbsp;</p>

    <h2>Third-party Use of Cookies and Other Tracking Technologies</h2>

    <p>&nbsp;</p>

    <p>Some content or applications on the Portal may be served by
      third-parties. These third parties may use cookies alone or in conjunction
      with web beacons or other tracking technologies to collect information about
      you when you use our website. The information they collect may be associated
      with your personal information or they may collect information, including
      personal information, about your online activities over time and across
      different websites and other online services. They may use this information to
      provide you with interest-based (behavioral) or other targeted content.
    </p>

    <p>&nbsp;</p>

    <p>We do not control these third parties’ tracking technologies
      or how they may be used. If you have any questions about targeted content, you
      should contact the responsible provider directly.
    </p>

    <p>&nbsp;</p>

    <h2>How We Use Your Information</h2>

    <p>&nbsp;</p>

    <p>We use information that we collect about you or that you
      provide to us, including any personal information:
    </p>

    <ul>
      <li>To present our Portal and its contents to you.</li>

      <li>To provide you with information, products or services that you request from us.</li>

      <li>To fulfill any other purpose for which you provide it.</li>

      <li>To carry out our obligations and enforce our rights arising from
        any contracts entered into between you and us.
      </li>

      <li>To notify you about changes to our Portal or any products or
        services we offer or provide though it.
      </li>

      <li>To allow you to participate in interactive features on our Portal.</li>

      <li>In any other way we may describe when you provide theinformation.</li>

      <li>For any other purpose with your consent.</li>
    </ul>

    <p>&nbsp;</p>

    <p>We may also use your information to contact you about our
      own and third-parties’ services that may be of interest to you. If you do not
      want us to use your information in this way, please check the relevant box
      located on the form on which we collect your data.
    </p>

    <p>&nbsp;</p>

    <h2>Disclosure of Your Information</h2>

    <p>&nbsp;</p>

    <p>We may disclose aggregated information about our users, and
      information that does not identify any individual, without restriction. We may
      disclose personal information that we collect or you provide as described in
      this privacy policy:
    </p>

    <ul>
      <li>To our subsidiaries and affiliates.</li>

      <li>To contractors, service providers and other third parties we use
        to support our business and who are bound by contractual obligations to keep
        personal information confidential and use it only for the purposes for which we
        disclose it to them.
      </li>

      <li>To a successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution or other sale or transfer of some or
        all of Proteus’s assets, whether as a going concern or as part of bankruptcy,
        liquidation or similar proceeding, in which personal information held by
        Proteus about our Portal users is among the assets transferred.
      </li>

      <li>To third parties to market their products or services to you if
        you have not opted out of these disclosures. We contractually require these
        third parties to keep personal information confidential and use it only for the
        purposes for which we disclose it to them.
      </li>

      <li>To fulfill the purpose for which you provide it. </li>

      <li>For any other purpose disclosed by us when you provide the information.</li>

      <li>With your consent.</li>
    </ul>

    <p>&nbsp;</p>

    <p>We may also disclose your personal information:</p>

    <ul>
      <li>To comply with any court order, law or legal process, including
        to respond to any government or regulatory request.
      </li>

      <li>To enforce or apply our
        Portal <LinkInternal href='/justfund/terms'>Terms of Use</LinkInternal> and
        other agreements, including for member billing and collection purposes.
      </li>

      <li>If we believe disclosure is necessary or appropriate to protect
        the rights, property, or safety of Proteus, our members or others.
      </li>
    </ul>

    <p>&nbsp;</p>

    <h2>Choices About How We Use and Disclose Your Information</h2>

    <p>&nbsp;</p>

    <p>We strive to provide you with choices regarding the personal
      information you provide to us. We have created mechanisms to provide you with
      the following control over your information:
    </p>

    <ul>
      <li><b>Tracking Technologies and Advertising.</b> You can set your
        browser to refuse all or some browser cookies, or to alert you when cookies are
        being sent. To learn how you can manage your Flash cookie settings, visit the
        Flash player settings page on Adobe’s website. If you disable or refuse
        cookies, please note that some parts of this site may then be inaccessible or
        not function properly.
      </li>
    </ul>

    <p><b>&nbsp;</b></p>

    <p>You may communicate with us via email
      at <Link href='mailto:info@justfund.us'>info@justfund.us</Link> to
      request access to, correct or delete any personal information that you have
      provided to us. We cannot delete your personal information except by also
      deleting your user account. We may not accommodate a request to change
      information if we believe the change would violate any law or legal requirement
      or cause the information to be incorrect.
    </p>

    <p>&nbsp;</p>

    <p>If you delete your User Contributions from the Portal,
      copies of your User Contributions may remain viewable in cached and archived
      pages, or might have been copied or stored by other Portal users. Proper access
      and use of information provided on the Portal, including User Contributions, is
      governed by our <LinkInternal href='/justfund/terms'>Terms of Use</LinkInternal>.
    </p>

    <p>&nbsp;</p>

    <h2>Your California Privacy Rights</h2>

    <p>&nbsp;</p>

    <p>California Civil Code Section § 1798.83 permits users of our
      Portal that are California residents to request certain information regarding
      our disclosure of personal information to third parties for their direct
      marketing purposes. To make such a request, please send an email
      to <Link href='mailto:info@justfund.us'>info@justfund.us</Link>.
    </p>

    <p>&nbsp;</p>

    <h2>Data Security</h2>

    <p>&nbsp;</p>

    <p>We have implemented measures designed to secure your
      personal information from accidental loss and from unauthorized access, use,
      alteration and disclosure. All information you provide to us is stored on our
      secure servers behind firewalls. Any payment in connection with a membership
      registration will be encrypted.
    </p>

    <p>&nbsp;</p>

    <p>The safety and security of your information also depends on
      you. Where we have given you (or where you have chosen) a password for access
      to certain parts of our Portal, you are responsible for keeping this password
      confidential. We ask you not to share your password with anyone. We urge you
      to be careful about giving out information in public areas of the Portal like
      message boards. The information you share in public areas may be viewed by any
      user of the Portal.
    </p>

    <p>&nbsp;</p>

    <p>Unfortunately, the transmission of information via the
      internet is not completely secure. Although we do our best to protect your
      personal information, we cannot guarantee the security of your personal
      information transmitted to our Portal. Any transmission of personal
      information is at your own risk. We are not responsible for circumvention of
      any privacy settings or security measures contained on the Portal.
    </p>

    <p>&nbsp;</p>

    <h2>Changes to Our Privacy Policy</h2>

    <p>It is our policy to post any changes we make to our privacy
      policy on this page with a notice that the privacy policy has been updated on
      the Portal home page. If we make material changes to how we treat our users’
      personal information, we will notify you by email to the email address
      specified in your account and/or through a notice on the Portal home page. The
      date the privacy policy was last revised is identified at the top of the page.
      You are responsible for ensuring we have an up-to-date active and deliverable
      email address for you, and for periodically visiting our Portal and this
      privacy policy to check for any changes.
    </p>

    <p>&nbsp;</p>

    <h2>Contact Information</h2>

    <p>To ask questions or comment about this privacy policy and
      our privacy practices, please send an email
      to <Link href='mailto:info@justfund.us' tc='cBrand1'>info@justfund.us</Link>.
    </p>
  </Box>
);
