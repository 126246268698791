import { IFocus } from '../../interfaces/IFocus';
import {
  IBloatedGrantee,
  IGranteeContact,
  IGranteeCreate,
  IGranteeFindApplicantNames,
  IGranteeFindByName
} from '../../interfaces/IGrantee';
import { api } from './api.service';
import { IContact } from '../../interfaces/IContact.interface';
import { AxiosRequestConfig } from 'axios';
import { IApplication } from '../../interfaces/IProposal';

export class GranteeAPIService {
  private static baseRoute = '/api/grantees';

  public static async get(
    options: AxiosRequestConfig
  ): Promise<IBloatedGrantee[]> {
    const res = await api.get(`${this.baseRoute}`, options);
    return res.data as IBloatedGrantee[];
  }

  public static async getTable(params?: any): Promise<IBloatedGrantee[]> {
    const res = await api.get(`${this.baseRoute}/manage`, { params });
    return res.data as IBloatedGrantee[];
  }

  public static async findByName(
    name?: string,
    options: AxiosRequestConfig = {}
  ): Promise<IGranteeFindByName[]> {
    options.params = { ...options.params, name };
    const res = await api.get(`${this.baseRoute}/search`, options);
    return res.data as IGranteeFindByName[];
  }

  public static async findByManyParams(
    queryParams: {
      name?: string;
      fiscal_sponsor?: string;
      tax_id?: string;
      tax_status_id?: string;
    },
    options: AxiosRequestConfig = {}
  ): Promise<IGranteeFindByName[]> {
    options.params = { ...options.params, ...queryParams };
    const res = await api.get(`${this.baseRoute}/search`, options);
    return res.data as IGranteeFindByName[];
  }

  public static async findApplicantNames(
    name: string,
    options: AxiosRequestConfig = {}
  ): Promise<IGranteeFindApplicantNames> {
    options.params = { ...options.params, name };
    const res = await api.get(`${this.baseRoute}/names`, options);
    return res.data as IGranteeFindApplicantNames;
  }

  public static async getById(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<IBloatedGrantee> {
    const res = await api.get(`${this.baseRoute}/${id}`, config);
    return res.data as IBloatedGrantee;
  }

  public static async getDrafts(
    rfpId: number,
    config?: AxiosRequestConfig
  ): Promise<IApplication[]> {
    const res = await api.get(`${this.baseRoute}/draft/${rfpId}`, config);
    return res.data;
  }

  public static async getFocus(config?: AxiosRequestConfig): Promise<IFocus[]> {
    const res = await api.get(`/api/focus`, config);
    return res.data as IFocus[];
  }

  public static async activeContacts(email: string): Promise<IContact> {
    const res = await api.get(`${this.baseRoute}/active/${email}`);
    return res.data as IContact;
  }

  public static async put(grantee: any, id: number): Promise<IBloatedGrantee> {
    const res = await api.put(`${this.baseRoute}/${id}`, grantee);
    return res.data as IBloatedGrantee;
  }

  public static async delete(id: string, email: string): Promise<IContact> {
    const res = await api.delete(`${this.baseRoute}/removeuser/${id}/${email}`);
    return res.data as IContact;
  }

  public static async getStates(): Promise<any> {
    const res = await api.get(`/api/states`);
    return res.data;
  }

  public static async post(grantee: IGranteeCreate): Promise<IBloatedGrantee> {
    const res = await api.post(`${this.baseRoute}/invite`, grantee);
    return res.data as IBloatedGrantee;
  }

  public static async getInvitation(rfpId: number): Promise<any> {
    const res = await api.get(`/api/rfp/${rfpId}/invitations`);
    return res.data;
  }

  public static async searchByMail(
    email: string,
    options: AxiosRequestConfig = {}
  ) {
    const res = await api.get(
      `${this.baseRoute}/grantee-by-email/${email}`,
      options
    );
    return res.data;
  }
}
