import * as React from "react";

function SvgEnvironmentSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="environment-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#environment-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 18"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zM9.78 17.48a29.78 29.78 0 014-7.3c.26-.33-.32-.68-.58-.33a33.76 33.76 0 00-4 7.27C4.33 13.58 9.81 6.71 16 6.19c2.08 5.06-.87 13.39-6.22 11.29z"
              fill="#4a7a2b"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEnvironmentSmall;
