import * as React from "react";

function SvgRacialSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="racial-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#racial-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#ffa13b">
            <path
              data-name="Path 30"
              d="M13.38 11.42a2.84 2.84 0 01-1.31-.71l-1.19 1.18a1.6 1.6 0 01-2.26 0 .72.72 0 01-.08-1.06l3.1-3.1a3.9 3.9 0 00-5.15 5.83l5.05 5a.63.63 0 00.88 0 .62.62 0 000-.89l-2.5-2.5a.24.24 0 010-.33.22.22 0 01.33 0l2.5 2.5a.64.64 0 00.89 0 .62.62 0 00.18-.44.63.63 0 00-.18-.45l-2.5-2.5a.233.233 0 01.33-.33l2.5 2.5a.64.64 0 00.45.19.63.63 0 00.44-1.07l-2.51-2.51a.24.24 0 010-.33.25.25 0 01.34 0l2.5 2.5a.63.63 0 10.89-.88z"
            />
            <path
              data-name="Path 31"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.86 13.93l-1.41 1.41a1.12 1.12 0 01-.78.32h-.13a1.06 1.06 0 01-.31.9 1.08 1.08 0 01-.77.33h-.16a.53.53 0 010 .13 1.07 1.07 0 01-.32.77 1.09 1.09 0 01-.77.32h-.13v.13a1.11 1.11 0 01-1.1 1.1 1.13 1.13 0 01-.78-.32L6.16 14A4.384 4.384 0 0112 7.46a4.32 4.32 0 012.75-1 4.38 4.38 0 013.11 7.45z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgRacialSmall;
