type HubSpotEvents = (string | Record<string, string>)[][];
declare global {
	interface Window {
		_hsq: HubSpotEvents
	}
}

const _hsq = window._hsq = window._hsq || [];

class HubSpotClient {

  constructor(private hubSpotEvents = _hsq) {}

  public recordPageView() {
    this.hubSpotEvents.push(['trackPageView']);
  }

  public identifyUser(email: string) {
    this.hubSpotEvents.push(['identify', { email }]);
    this.hubSpotEvents.push(['trackPageView']);
  }
}

export const HubspotService = new HubSpotClient();
