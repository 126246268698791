import * as React from "react";

function SvgImmigrationSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="immigration-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#immigration-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#d83039">
            <path
              data-name="Path 26"
              d="M12 9.5a1.68 1.68 0 101.68 1.68A1.68 1.68 0 0012 9.5zm0 2.75a1.07 1.07 0 111.07-1.07A1.07 1.07 0 0112 12.25z"
            />
            <path
              data-name="Path 27"
              d="M13.37 13.77h-2.74a.31.31 0 100 .61h2.74a.31.31 0 100-.61z"
            />
            <path
              data-name="Path 28"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm2.18 4.87a.27.27 0 01.27 0 .31.31 0 01.13.24v1h-4.21zm1.63 12a.3.3 0 01-.3.3h-7a.3.3 0 01-.3-.3V7.06a.31.31 0 01.3-.3h7a.31.31 0 01.3.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgImmigrationSmall;
