//libraries
import {
  PendingActions,
  Check,
  WarningAmber,
  EventOutlined
} from '@mui/icons-material';

//interfaces
import { TStatus } from './interfaces';

type TColor = 'primary' | 'warning';

interface IIconProps {
  sx: { width: number; height: number };
  color: TColor;
}

interface IStatusInfo {
  StatusIcon: React.ElementType;
  text: string;  
  iconProps: IIconProps;
}

const size = { width: 24, height: 24 };

export const statusMap: Record<TStatus, Partial<IStatusInfo>> = {
  scheduled: {
    StatusIcon: EventOutlined,
    text: 'Scheduled',
    iconProps: { sx: size, color: 'primary' }
  },
  overdue: {
    StatusIcon: WarningAmber,
    text: 'Overdue',
    iconProps: {
      sx: size,
      color: 'warning'
    }
  },
  done: {
    StatusIcon: Check,
    text: 'Done',
    iconProps: {
      sx: size,
      color: 'primary'
    }
  },
  pending: {
    StatusIcon: PendingActions,
    text: 'Pending',
    iconProps: {
      sx: size,
      color: 'primary'
    }
  },
  default: {}
};
