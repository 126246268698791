import { sortByProperty } from '../util/sortObjects';
import {
  ClimateSmall,
  CriminalSmall,
  DemocracySmall,
  DisabilitySmall,
  EconomicSmall,
  EducationSmall,
  EnvironmentSmall,
  EquitableSmall,
  FoodSmall,
  GenderSmall,
  HealthSmall,
  HousingSmall,
  HumanSmall,
  ImmigrationSmall,
  LgbtqSmall,
  MediaSmall,
  OtherSmall,
  RacialSmall,
  TransportSmall
} from '../images';

const al = { value: 'AL', name: 'Alabama' };
const ak = { value: 'AK', name: 'Alaska' };
const as = { value: 'AS', name: 'American Samoa' };
const az = { value: 'AZ', name: 'Arizona' };
const ar = { value: 'AR', name: 'Arkansas' };
const ca = { value: 'CA', name: 'California' };
const co = { value: 'CO', name: 'Colorado' };
const ct = { value: 'CT', name: 'Connecticut' };
const de = { value: 'DE', name: 'Delaware' };
const dc = { value: 'DC', name: 'District of Columbia' };
const fl = { value: 'FL', name: 'Florida' };
const ga = { value: 'GA', name: 'Georgia' };
const gu = { value: 'GU', name: 'Guam' };
const hi = { value: 'HI', name: 'Hawaii' };
const ida = { value: 'ID', name: 'Idaho' };
const il = { value: 'IL', name: 'Illinois' };
const ind = { value: 'IN', name: 'Indiana' };
const ia = { value: 'IA', name: 'Iowa' };
const ks = { value: 'KS', name: 'Kansas' };
const ky = { value: 'KY', name: 'Kentucky' };
const la = { value: 'LA', name: 'Louisiana' };
const me = { value: 'ME', name: 'Maine' };
const md = { value: 'MD', name: 'Maryland' };
const ma = { value: 'MA', name: 'Massachusetts' };
const mi = { value: 'MI', name: 'Michigan' };
const mn = { value: 'MN', name: 'Minnesota' };
const ms = { value: 'MS', name: 'Mississippi' };
const mo = { value: 'MO', name: 'Missouri' };
const mt = { value: 'MT', name: 'Montana' };
const ne = { value: 'NE', name: 'Nebraska' };
const nv = { value: 'NV', name: 'Nevada' };
const nh = { value: 'NH', name: 'New Hampshire' };
const nj = { value: 'NJ', name: 'New Jersey' };
const nm = { value: 'NM', name: 'New Mexico' };
const ny = { value: 'NY', name: 'New York' };
const nc = { value: 'NC', name: 'North Carolina' };
const nd = { value: 'ND', name: 'North Dakota' };
const mp = { value: 'MP', name: 'Northern Mariana Islands' };
const oh = { value: 'OH', name: 'Ohio' };
const ok = { value: 'OK', name: 'Oklahoma' };
const or = { value: 'OR', name: 'Oregon' };
const pa = { value: 'PA', name: 'Pennsylvania' };
const pr = { value: 'PR', name: 'Puerto Rico' };
const ri = { value: 'RI', name: 'Rhode Island' };
const sc = { value: 'SC', name: 'South Carolina' };
const sd = { value: 'SD', name: 'South Dakota' };
const tn = { value: 'TN', name: 'Tennessee' };
const tx = { value: 'TX', name: 'Texas' };
const vi = { value: 'VI', name: 'US Virgin Islands' };
const ut = { value: 'UT', name: 'Utah' };
const vt = { value: 'VT', name: 'Vermont' };
const va = { value: 'VA', name: 'Virginia' };
const wa = { value: 'WA', name: 'Washington' };
const wv = { value: 'WV', name: 'West Virginia' };
const wi = { value: 'WI', name: 'Wisconsin' };
const wy = { value: 'WY', name: 'Wyoming' };

export const states = [al, ak, as, az, ar, ca, co, ct, de, dc, fl, ga, gu, hi, ida,
  il, ind, ia, ks, ky, la, me, md, ma, mi, mn, ms, mo, mt, ne, nv, nh, nj, nm,
  ny, nc, nd, mp, oh, ok, or, pa, pr, ri, sc, sd, tn, tx, vi, ut, vt, va, wa, wv, wi,
  wy];

export const regions = [
  { value: 1, name: 'West' },
  { value: 2, name: 'Midwest' },
  { value: 3, name: 'Northeast' },
  { value: 4, name: 'South' },
  { value: 5, name: 'US Territory' },
];

export const metros = [
  { value: 1, name: 'Atlanta' },
  { value: 2, name: 'Los Angeles' },
  { value: 3, name: 'New York City' },
  { value: 4, name: 'San Francisco Bay Area' },
];

export const scales = [
  { value: 0, name: 'National' },
  { value: 1, name: 'Regional' },
  { value: 2, name: 'State' },
  { value: 3, name: 'Local' },
];

export const statuses = [
  { value: 'draft', name: 'Draft' },
  { value: 'nominee', name: 'Draft' },
  { value: 'pending', name: 'Active' },
  { value: 'active', name: 'Active' },
  { value: 'completed', name: 'Active' },
  { value: 'archived', name: 'Archived' },
];

export const strategies = [
  { value: 1, name: 'Advocacy' },
  { value: 2, name: 'Art' },
  { value: 3, name: 'Base Building' },
  { value: 4, name: 'Capacity Building / Org Development' },
  { value: 5, name: 'Coalition Building' },
  { value: 6, name: 'Communications & Media' },
  { value: 7, name: 'Convening' },
  { value: 8, name: 'Culture' },
  { value: 9, name: 'Digital Organizing' },
  { value: 10, name: 'Direct Action' },
  { value: 11, name: 'Direct Service' },
  { value: 12, name: 'Education' },
  { value: 13, name: 'Healing / Restorative Justice' },
  { value: 14, name: 'Leadership Development' },
  { value: 15, name: 'Movement Building' },
  { value: 16, name: 'Mutual Aid' },
  { value: 17, name: 'Organizing' },
  { value: 18, name: 'Policy' },
  { value: 19, name: 'Research' },
  { value: 20, name: 'Social Enterprise' },
  { value: 21, name: 'Voter Registration / GOTV' },
];

export const communities = [
  { value: 1, name: 'Asian or Asian American / AAPI' },
  { value: 2, name: 'Black' },
  { value: 3, name: 'Caribbean' },
  { value: 4, name: 'Immigrant' },
  { value: 5, name: 'Indigenous' },
  { value: 6, name: 'Jewish' },
  { value: 7, name: 'Latino / Latina / Latinx' },
  { value: 8, name: 'LGBTQ+' },
  { value: 9, name: 'Muslim' },
  { value: 10, name: 'People of African Descent' },
  { value: 11, name: 'People who are Justice-involved' },
  { value: 12, name: 'People who are Undocumented' },
  { value: 13, name: 'People with Disabilities' },
  { value: 14, name: 'Refugees' },
  { value: 15, name: 'Rural' },
  { value: 16, name: 'Seniors' },
  { value: 17, name: 'Veterans' },
  { value: 18, name: 'West Indian' },
  { value: 19, name: 'Women' },
  { value: 20, name: 'Workers' },
  { value: 21, name: 'Youth' },
  { value: 22, name: 'Youth: College' },
  { value: 23, name: 'Youth: High School' },
  { value: 24, name: 'Other' },
];

interface FocusAreasData {
  [index: number]: {
    key: number;
    name: string;
    component: React.FC<{ width?: number | string, height?: number | string }>;
    imagePath: string;
  };
}

export const focusAreas: FocusAreasData = {
  1: { key: 1, name: 'Environmental Justice / Climate Justice / Nature', component: EnvironmentSmall, imagePath: '/images/icons/environment.svg' },
  2: { key: 2, name: 'Criminal Justice', component: CriminalSmall, imagePath: '/images/icons/criminal_justice.svg' },
  3: { key: 3, name: 'Education', component: EducationSmall, imagePath: '/images/icons/education.svg' },
  4: { key: 4, name: 'Immigration', component: ImmigrationSmall, imagePath: '/images/icons/immigration.svg' },
  5: { key: 5, name: 'LGBTQ+', component: LgbtqSmall, imagePath: '/images/icons/lgbtq.svg' },
  6: { key: 6, name: 'Racial Justice', component: RacialSmall, imagePath: '/images/icons/racial_justice.svg' },
  7: { key: 7, name: 'Economic Justice', component: EconomicSmall, imagePath: '/images/icons/economic_justice.svg' },
  8: { key: 8, name: 'Democracy & Civic Engagement', component: DemocracySmall, imagePath: '/images/icons/democracy_civic.svg' },
  9: { key: 9, name: 'Gender & Reproductive Justice', component: GenderSmall, imagePath: '/images/icons/gender_reproductive.svg' },
  10: { key: 10, name: 'Healthcare / Wellness / Public Health', component: HealthSmall, imagePath: '/images/icons/healthcare.svg' },
  11: { key: 11, name: 'Human Rights / Civil Rights & Liberties', component: HumanSmall, imagePath: '/images/icons/human_civilrights.svg' },
  12: { key: 12, name: 'Other', component: OtherSmall, imagePath: '/images/icons/other.svg' },
  13: { key: 13, name: 'Climate / Disaster Response and Recovery', component: ClimateSmall, imagePath: '/images/icons/climate.svg' },
  14: { key: 14, name: 'Equitable Development', component: EquitableSmall, imagePath: '/images/icons/equitable.svg' },
  15: { key: 15, name: 'Food Justice', component: FoodSmall, imagePath: '/images/icons/food.svg' },
  16: { key: 16, name: 'Housing / Homelessness', component: HousingSmall, imagePath: '/images/icons/housing.svg' },
  17: { key: 17, name: 'Transportation / Utilities / Public Infrastructure', component: TransportSmall, imagePath: '/images/icons/transport.svg' },
  18: { key: 18, name: 'Journalism / Media', component: MediaSmall, imagePath: '/images/icons/media.svg' },
  19: { key: 19, name: 'Disability Justice', component: DisabilitySmall, imagePath: '/images/icons/disability.svg' },
};

const t1a = Object.values (focusAreas);
const t1b = t1a.filter ((a) => a.key !== 12);
const t1c = sortByProperty (t1b, 'name');
export const orderedFocusAreas = [...t1c, focusAreas[12]];

export const urgents = {
  1: { name: 'COVID-19' },
  2: { name: 'Refugee Resettlement' }
};

export const formats = {
  check: 'Check',
  online: 'Online',
  stock: 'Stock',
  ACH: 'ACH',
  wire: 'Wire',
  other: 'Other',
};
