import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { jfTheme } from '../../../styles/jf.theme'
import { ScrollToTop } from '../../../shared/util/scrollToTop';
import { NotFoundPage } from '../../../shared/util/NotFoundPage';
import { PublicRfp } from '../../../shared/components/viewRfp/PublicRFP';
import { PublicNav } from './PublicNav';
import { Footer } from '../../../shared/components/Footer';

const theme = jfTheme;

export const App = () => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <CssBaseline />
        <Box display='flex' flexDirection='column' minHeight='100vh'>
          <PublicNav />
          <Box flexGrow={1} flexShrink={1}>
            <Switch>
              <Route path='/p/rfps/:id' component={PublicRfp} />
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
