import * as React from "react";

function SvgClimateSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="climate-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#climate-small_svg__a)">
        <g data-name="Group 1" fill="#93c83e">
          <circle cx="12" cy="12" r="10" fill="white" />
          <path
            data-name="Path 2"
            d="M11.997 0a12 12 0 1012 12 12 12 0 00-12-12zm0 18.613A6.612 6.612 0 1118.609 12a6.622 6.622 0 01-6.612 6.617z"
          />
          <path
            data-name="Path 3"
            d="M13.497 11.38h-1.823l1.161-1.739a.62.62 0 10-1.029-.691l-1.809 2.707a.617.617 0 00.513.963h1.822l-1.161 1.74a.619.619 0 00.169.86.632.632 0 00.343.1.612.612 0 00.517-.277l1.806-2.7a.618.618 0 00-.512-.963z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgClimateSmall;
