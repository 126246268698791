import * as React from "react";

function SvgEducationSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="education-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#education-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 17"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm3.65 14.07a.44.44 0 01-.11.29A4.93 4.93 0 0112 15.85a5 5 0 01-2-.44V13.5l1.51.68a1.25 1.25 0 001 0l3.14-1.43zm2.65-3.41l-2.65 1.2-.81.37-2.67 1.21a.33.33 0 01-.17 0 .35.35 0 01-.17 0l-2.66-1.21v4.27a.41.41 0 11-.81 0v-4.64l-2.66-1.2a.41.41 0 010-.75l6.13-2.78a.4.4 0 01.34 0l6.13 2.79a.38.38 0 01.24.37.4.4 0 01-.24.37z"
              fill="#80a3db"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEducationSmall;
