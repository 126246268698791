import { Box, styled } from '@mui/material';
import { CardLeftBorderProps, CardProps } from './card.types';
import { CardWrapperProps } from '.';

const getStatusColor = (status: CardProps['status']) => {
  switch (status) {
    case 'primary':
      return '#4288C5';
    default:
      return 'transparent';
  }
};

export const CardContentWrapper = styled(Box)<CardProps>(
  ({ paddingX = 60, paddingY = 50, onClick }) => ({
    paddingTop: paddingY,
    paddingBottom: paddingY,
    paddingRight: paddingX,
    paddingLeft: paddingX,
    height: '100%',
    cursor: !!onClick ? 'pointer' : ''
  })
);

export const CardWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'withshadow'
})<CardWrapperProps>(({ theme, withshadow = true, status = 'none' }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: withshadow ? '0px 4px 21px rgba(86, 111, 149, 0.12)' : undefined,
  borderRadius: 17,
  border: '1px solid #DBE4F3',
  '& .MuiTypography-root': {
    color: '#515660'
  },
  borderLeft: status !== 'none' ? 0 : undefined,
  position: 'relative',
  overflow: 'hidden',
  height: '100%'
}));

export const CardLeftBorder = styled(Box)<CardLeftBorderProps>(({ status }) => ({
  padding: 3,
  backgroundColor: getStatusColor(status),
  position: 'absolute',
  height: '100%',
  borderTopLeftRadius: 17,
  borderBottomLeftRadius: 17
}));
