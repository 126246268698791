import * as React from "react";

function SvgHousingSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="housing-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#housing-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1">
            <path
              data-name="Path 24"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm6.36 12.35a.4.4 0 01-.37.25H16.8v4.11a.4.4 0 01-.4.4h-2.83a.4.4 0 01-.4-.4v-2.45a.4.4 0 00-.4-.4h-1.54a.4.4 0 00-.4.4v2.45a.4.4 0 01-.4.4h-3a.4.4 0 01-.4-.4V12.6H6a.4.4 0 01-.26-.71l6-4.91a.39.39 0 01.5 0l1.59 1.3v-.65a.4.4 0 01.4-.4h1.67a.39.39 0 01.4.4V10l2 1.93a.4.4 0 01.06.42z"
              fill="#ff7676"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgHousingSmall;
