export enum RFPStatus {
    APPROVED,
    SUBMITTED,
    DRAFT
  }

export enum RFPStatusLabel {
    ACTIVE = 'Active',
    APPROVED = 'Approved',
    CLOSED = 'Closed',
    DELETED = 'Deleted',
    DRAFT = 'Draft',
    SUBMITTED = 'Submitted'
  }