import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import io, { Socket } from 'socket.io-client';
import { IUser } from '../interfaces/IUser.interface';
import { UserAuthService } from '../services/api-service/user-auth-api.service';
import { isDevMode } from '../util/constants';

type RealtimeContextProviderProps = {
  socketIo: Socket | null;
  joinRooms: (rooms: string[]) => void;
  leaveRooms: (rooms: string[]) => void;
  joinedRooms: string[];
};

const RealtimeContext = createContext<RealtimeContextProviderProps>({
  socketIo: null,
  joinRooms: () => {},
  leaveRooms: () => {},
  joinedRooms: []
});

export const useRealtimeContext = () => useContext(RealtimeContext);

interface IRealtimeProviderProps {
  children: ReactNode;
  user: IUser | null;
}

const RealtimeProvider = ({ children, user }: IRealtimeProviderProps) => {
  const [userConnected, setUserConnected] = useState<number>();
  const [socketIo, setSocketIo] = useState<Socket | null>(null);
  const joinedRooms = useRef<string[]>([]);

  useEffect(() => {
    const token = UserAuthService.token;
    if (user) {
      if (!!socketIo) {
        if (userConnected !== user.id) {
          // previously connected but user changed
          socketIo.disconnect();
        } else {
          // already connected
          return;
        }
      }

      const socketIOConn = io(process.env.REACT_APP_API_BASE_URL!, {
        auth: { token },
        transports: ['websocket']
      });
      socketIOConn.on('connect', () => {
        setUserConnected(user.id);
        setSocketIo(socketIOConn);
        if (isDevMode()) console.log('Socket.io - Connected');
        if (joinedRooms.current.length) {
          // reconnect rooms if server went down
          socketIOConn.emit('bulk-join', joinedRooms.current);
        }
      });
      socketIOConn.on('disconnect', () => {
        setUserConnected(undefined);
        if (isDevMode()) console.log('Socket.io - Disconnected');
      });
    }
    return () => {
      socketIo && socketIo.disconnect();
    };
  }, [user, userConnected, socketIo]);

  const joinRooms = (rooms: string[]) => {
    if (socketIo) {
      const notJoinedRooms = rooms.filter(
        (room) => !joinedRooms.current.includes(room)
      );
      if (notJoinedRooms.length === 0) return;
      socketIo.emit('bulk-join', notJoinedRooms);
      joinedRooms.current = [...joinedRooms.current, ...notJoinedRooms];
    }
  };

  const leaveRooms = (rooms: string[]) => {
    if (socketIo) {
      const notLeftRooms = rooms.filter((room) =>
        joinedRooms.current.includes(room)
      );
      if (notLeftRooms.length === 0) return;
      socketIo.emit('bulk-leave', notLeftRooms);
      joinedRooms.current = joinedRooms.current.filter(
        (room) => !notLeftRooms.includes(room)
      );
    }
    // Note: If the user joined the same room twice, when leaving one instance, it disconnects from all instances of the room
  };

  return (
    <RealtimeContext.Provider
      value={{
        socketIo,
        joinRooms,
        leaveRooms,
        joinedRooms: joinedRooms.current
      }}
    >
      {children}
    </RealtimeContext.Provider>
  );
};

export default RealtimeProvider;
