import React from 'react';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useFunderContext } from '../../funder/providers/UserProvider';
import { LinkInternal } from './legacy-ui';

interface props {
  crumbs: {
    link?: string;
    text: string;
  }[];
}

export const PageHeader = ({ crumbs }: props) => {
  const { funder } = useFunderContext();
  const name = funder?.name;

  return (
    <Box sx={{ borderBottom: '1px solid #d8d8d8', backgroundColor: '#ffffff' }}>
      <Box maxWidth='1024px' m='0 auto' p='4px' style={{ display: 'none' }}>
        <Typography align='right'>{name}</Typography>
        <Breadcrumbs separator='>' aria-label='breadcrumb'>
          {crumbs.map((a) => (
            a.link ?
              <LinkInternal key={a.text} href={a.link}>{a.text}</LinkInternal> :
              <Typography key={a.text}>{a.text}</Typography>
          ))}
        </Breadcrumbs>
      </Box>
    </Box>
  );
};
