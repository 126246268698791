import grey from '@mui/material/colors/grey';
import { createStyles, makeStyles } from '@mui/styles';

const radius = '5px';
const size = 50;

const useStyles = makeStyles(
  (theme: { palette: { primary: { main: string } } }) =>
    createStyles({
      loader: {
        border: radius + ' solid ' + grey['200'],
        borderTop: radius + ' solid ' + theme.palette.primary.main,
        borderRadius: '50%',
        width: size,
        height: size,
        animation: 'loading-indicator-spinner 1.5s linear infinite'
      }
    })
);

const JFCircularProgress = () => {
  const styles = useStyles();
  return (
    <>
      <style>
        {`@keyframes loading-indicator-spinner {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }`}
      </style>
      <div className={styles.loader} />
    </>
  );
};

export default JFCircularProgress;
