import { Box, Button, List, ListItem, Typography, styled } from '@mui/material';

export const StyledBox = styled(Box)({
  fontSize: '18px',
});

export const PreviewTab = styled(Box)({
  marginTop: '10px',
  backgroundColor: '#1A75BD',
  width: '150px',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#fff',
  position: 'absolute',
});

export const ApplyButton = styled(Button)({
  padding: '.75rem 10rem',
  fontWeight: '600',
  borderRadius: 2,
  letterSpacing: '1.3px',
});

export const MainContainer = styled(Box)({
  margin: '0 auto',
  marginTop: '30px',
  maxWidth: '1024px',
});

export const CopyPublicLinkButton = styled(Button)({
  textTransform: 'none',
  color: '#1a75bd',
  gap: '5px',
});

export const BottomSpacedText = styled(Typography)({
  marginBottom: '16px',
});

export const StyledList = styled(List)({
  listStyleType: 'disc', 
  paddingLeft: '32px',
});

export const StyledListItem = styled(ListItem)({
  display: 'list-item',
});