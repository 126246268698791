import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ProfileCard } from '../../../profileCard/ProfileCard';
import { EProfileType } from '../../../../enums/profile.enum';
import { IProfile } from '../../../../interfaces/IProfile';
import { Loading } from '../../../legacy-ui';
import { useParams } from 'react-router';
import { PublicAPIService } from '../../../../services/api-service/public-api.service';
import ProfileDrawer from '../profileDrawer';
import { ScrollableContainer } from './styles';
import { toast } from 'react-toastify';
import { OrganizationInfo } from './OrganizationInfo';
import { BackToProfileButton } from './BackToProfileButton';

interface IAllOrganizations {
  isAdmin: boolean;
  profileSlug?: string;
}

const AllOrganizations = ({ isAdmin }: IAllOrganizations) => {
  const { id, profileSlug } = useParams<{ id: string, profileSlug: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [fundedOrganizations, setFundedOrganizations] = useState<IProfile[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [drawerOpen, setDrawerOpen] = useState<IProfile>();
  const [amount, setAmount] = useState<number>();
  const [funderName, setFunderName] = useState('');
  const limit = 15;

  useEffect(() => {
    getOrganizations();
  }, []);

  const getOrganizations = async () => {
    try {
      const fundedDataQuery = PublicAPIService.getFundedTabBySlug(profileSlug, {
          limit,
          offset
        })
      const chartDataQuery = PublicAPIService.getFunderBySlug(profileSlug, { signal: undefined });

      const [fundedData, chartData] = await Promise.all([fundedDataQuery, chartDataQuery]);

      if (fundedData) {
        setFundedOrganizations([...fundedOrganizations, ...fundedData]);
        setOffset(offset + limit);

        if (fundedData.length < limit) {
          setHasMore(false);
        }
        if(chartData) {
          setAmount(chartData.totalFundedTab);
          setFunderName(chartData.name);
        }
      }
      if (loading) {
        setLoading(false);
      }
    }
    catch (error) {
      toast.error('Error loading funded organizations');
    }
    finally {
      setLoading(false);
    }
  }

  return (
    loading ? (
      <Loading />
    ) : (
      <ScrollableContainer
        dataLength={fundedOrganizations.length}
        next={getOrganizations}
        hasMore={hasMore}
        loader={<Loading />}
        scrollableTarget="scrollableBox"
      >
        <Grid container maxWidth="1200px">
          <BackToProfileButton funderId={id} />
          <OrganizationInfo amount={amount?.toString()} funderName={funderName} />
          <Grid container spacing={3}>
            {fundedOrganizations.map((grantee) => {
              return (
                <Grid item key={grantee!.id} md={4} sm={12} xs={12}>
                  <ProfileCard
                    openDetails={() => setDrawerOpen(grantee!)}
                    profile={grantee!}
                    profileType={!!grantee.funder ? EProfileType.FUNDER : EProfileType.GRANTEE}
                    isAdmin={isAdmin}
                    showFunders={false}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {drawerOpen && <ProfileDrawer open onClose={() => setDrawerOpen(undefined)} entity={drawerOpen} funderName={funderName}/>}
      </ScrollableContainer>
    ))
};

export default AllOrganizations;