import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CustomGlobeIcon = (props: SvgIconProps) => (
  <SvgIcon
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 158 158"
    fill="none"
    {...props}
  >
    <circle cx="79" cy="79" r="75" stroke="black" stroke-width="8" />
    <path
      d="M62.0004 6C41.5004 28.6667 13.1004 89.6 63.5004 152"
      stroke="black"
      stroke-width="10"
    />
    <path
      d="M93.7692 6C114.269 28.6667 142.669 89.6 92.2692 152"
      stroke="black"
      stroke-width="10"
    />
    <path
      d="M134 126.362C116.612 117.649 69.8685 105.577 22 127"
      stroke="black"
      stroke-width="10"
    />
    <path d="M134 30.6376C116.612 39.3515 69.8685 51.4234 22 30" stroke="black" stroke-width="10" />
    <path d="M78 4.5V153.5" stroke="black" stroke-width="10" />
    <path d="M4.5 79H152.5" stroke="black" stroke-width="10" />
  </SvgIcon>
);
