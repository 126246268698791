import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (input = "", formatOption = "MM/dd/yyyy") => {
  if (!input) return input;
  return format(new Date(input), formatOption);
};

export const formatDateYMD = (input = "", formatOption = "yyyy/MM/dd") => {
  if (!input) return input;
  return format(new Date(input), formatOption);
};

export const fullName = ({ first_name, last_name }: any) => {
  return [first_name, last_name].filter((v) => !!v).join(" ");
};

export const formatString = (date: Date | number, formatOption = "MM/dd/yyyy") => format(date, formatOption);

export const formatUtcDate = (input = "", formatOption = "MM/dd/yyyy") => {
  if (!input) return input;
  return formatInTimeZone(new Date(input), 'utc', formatOption)
}
