import React from 'react';
import { AvatarAndNameProps } from './avatarAndName.types';
import { Grid, Typography } from '@mui/material';
import { Avatar } from '../../../common/components';
import { useHistory } from 'react-router';
import { getProfileRedirectUri } from '../../util/util';

export const AvatarAndName: React.FC<AvatarAndNameProps> = ({
  shortProfile,
  avatarSize,
  redirect = true
}) => {
  const { id, isPublished, name, logo, isGrantee } = shortProfile;

  const history = useHistory();

  const handleOnClick = () =>
    redirect && isPublished && history.push(getProfileRedirectUri(id, !!isGrantee, false));

  return (
    <Grid container columnSpacing={2} justifyContent="flex-start" alignItems="center">
      <Grid item xs="auto" justifyContent="start" alignItems="center">
        <Avatar alt={`${name} logo`} src={logo || ''} avatarSize={avatarSize}>
          {name ? name.substring(0, 1) : 'A'}
        </Avatar>
      </Grid>
      <Grid item xs={8} alignItems="center" display="grid">
        <Typography
          noWrap
          onClick={handleOnClick}
          sx={{
            textDecoration: isPublished ? 'underline' : '',
            cursor: isPublished ? 'pointer' : ''
          }}
          variant="h6"
        >
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
};
