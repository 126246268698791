import { Box, Divider, Drawer, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const NavbarBox = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  flexGrow: 1,
  marginRight: '30px',
});

export const NavMenuLink = styled(Link)({
  textDecoration: 'none',
  color: '#000',
  padding: '10px 24px',
  display: 'block',
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight: 700,
});

export const MenuLabel = styled(Typography)({
  position: 'relative',
  '&:after': {
    content: '""',
    width: '0',
    height: '3px',
    display: 'block',
    background: 'none',
    borderBottom: '3px solid #E61436',
    transition: 'width .25s ease',
    marginLeft: 0,
    position: 'absolute',
  },
  '&:hover:after': {
    width: '100%',
    marginLeft: 0,
  }
});

export const SignInSignUpLink = styled(Link)({
  textDecoration: 'none',
  color: '#E61436',
  display: 'flex',
  gap: '10px',
  fontSize: '18px',
  lineHeight: '25px',
  fontWeight: 500,
  flexDirection: 'row',
  padding: '0 10px',
  '& .MuiSvgIcon-root': {
    fontSize: '25px',
  },
});

export const StyledDivider = styled(Divider)({
  width: '2px',
  backgroundColor: '#E61436',
  margin: '0 10px',
});

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: 240
  },
});

export const DrawerMenuLink = styled(Link)(({ isBold }: { isBold?: boolean }) => ({
  textDecoration: 'none',
  color: '#000',
  padding: '10px 15px',
  display: 'block',
  fontSize: '20px',
  lineHeight: '20px',
  fontWeight: isBold ? 700 : 'normal',
  textAlign: 'center',
  width: '100%',
}));