import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory, useParams } from "react-router-dom";
import { useMessagingContext } from '../../../shared/components/modals/Notification';
import { UserAuthService } from '../../../shared/services/api-service/user-auth-api.service';
import { UserService } from '../../../shared/services/api-service/user-api.service';
import { Controller, useForm } from "react-hook-form";
import { NotificationStatus } from '../../../shared/enums/notification-status.enum';


// TODO: Clean up these CSS classes, a lot of these probably aren't necessary now that the theme is fully stood up
const classes = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
      border: ' 1px solid #000000',
      boxShadow: 'inset 0 2px 00 #ffffff'
    },
    marginBlockEnd: '25px',
    width: '300px'
  },
  fields: {
    width: '100%',
    margin: 'auto',
  },
  labelLeft: {
    fontWeight: 'bold',
    paddingBottom: '10px',
    lineHeight: '22px',
    letterSpacing: 0,
  },
  resetHeading: {
    lineHeight: '61px',
    fontSize: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '22px'
  },
  redUnderline: {
    height: '10px',
    backgroundColor: '#E61436',
    margin: '1em',
    width: '70%',
    marginLeft: '15%'
  },
};

interface IParams {
  id: string;
}
export const RequiredResetPassword = () => {


  const userId = useParams<IParams>();
  const history = useHistory();
  const passwordRegex = /[a-z0-9!@#:;<>?~%^$*&()\\-`.+,/\"]{8,}/i;
  const requiredErrorMessage = "This field is required";

  const { control, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });
  const { notificationDispatch } = useMessagingContext();

  const [password, setPassword] = useState({ password: "", showPassword: false });
  const [verifyId, setVerifyId] = useState<number>();


  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOpen = () => {
    const data = { open: true, title: "Password Reset", message: "Password Reset Successfully. Please Log In.", onClose: handleClose, removeCancelBtn: true };
    notificationDispatch({ type: NotificationStatus.UPDATE, data });
  }

  const handleClose = (val: boolean) => {
    if (val) {
      history.push(`/justfund/signin`);
    }
  };

  const handleErrorNotification = () => {
    notificationDispatch({ type: NotificationStatus.UPDATE, data: { open: true, title: 'Error', message: "Something has gone wrong. Please try again or contact info@justfund.us" } })
  }

  const onSubmit = async (formData: any) => {
    if (verifyId === parseInt(userId.id, 10))
      try {
        const reset = await UserService.forcePasswordReset(formData);
        if (reset) handleOpen();
      } catch (err) {
        console.error(err)
        handleErrorNotification()
      }
  }

  useEffect(() => {
    UserAuthService.verifyLogin().then(response => {
      setVerifyId(response.user?.id);
    })
  }, [])

  return (
    <Grid container>
      <Box sx={{ ...classes.root, width: '1015px', height: '569px', marginX: 'auto', marginTop: '2em', textAlign: 'center' }}>
        <Grid item sm={12} sx={{ marginBottom: '1em' }}>
          <Typography variant="h1" sx={classes.resetHeading}>Create a New Password</Typography>
          <Box sx={classes.redUnderline} />
        </Grid>
        <Grid item sm={12} sx={{ marginBottom: '1em' }}>
          <Typography variant="h3">Your password has been reset, please enter a new password</Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Typography sx={classes.labelLeft}>New Password</Typography>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: requiredErrorMessage, pattern: { value: passwordRegex, message: "Password must be at least 8 characters" } }}
              render={({ field }) =>
                <TextField
                  sx={classes.textField}
                  placeholder="Enter new password"
                  size="small"
                  variant="outlined"
                  id="input-with-icon-textfield"
                  type={password.showPassword ? 'text' : 'password'}
                  error={!!errors?.password}
                  helperText={!!errors?.password && errors?.password?.message}
                  {...field}
                  InputProps={{
                    style: { fontSize: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large">
                          {password.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Grid>
          <Button variant='contained' type="submit">Submit</Button>
        </form>
        <Grid item sm={12}>
        </Grid>
      </Box >
    </Grid>
  );
}
