import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, TextField, Typography, Select, FormControlLabel, Checkbox, Box, RadioGroup, Radio, FormControl, Tooltip } from '@mui/material';
import { FocusApiServices } from '../../../shared/services/api-service/focus-api.services';
import { IFocus } from '../../../shared/interfaces/IFocus';
import { IUser } from '../../../shared/interfaces/IUser.interface';
import { FunderServiceLevel } from '../../../shared/enums/funder/funder-enums';
import { useFunderContext } from '../../../funder/providers/UserProvider';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { urlRegex } from '../../../shared/util/constants';
import blueinfo from '../../../images/blueinfo.svg';
import _ from 'lodash';
import { requiredErrorMessage } from '../../../shared/util/util';
import { UserVisibilityType } from '../../../shared/enums/user.enum';

const classes = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
    width: '2573px',
    maxHeight: "604px"
  },
  menuItem: {
    maxWidth: '75%',
    '@media (max-width: 900px)': {
      maxWidth: '50%',
    }
  },
  checkbox: {
    fontSize: '16px'
  },
  label: {
    transform: "translateY(1rem)",
    '@media (max-width: 900px)': {
      transform: "translateY(0.5em)"
    }
  }
};

interface IBankProps {
  requireBank: boolean,
  checked: boolean,
  membersLength: number[]
};

export interface IUserSettingsProps {
  setUser: React.Dispatch<React.SetStateAction<any>>,
  user: IUser,
  // TODO: Implement bank field and anonymous setting later
  // bankField: IBankProps,
  // setBankField: React.Dispatch<React.SetStateAction<any>>,
  // hideOptOut: () => boolean,
  // anonymousSetting: UserVisibilityType,
  // setAnonymousSetting: React.Dispatch<React.SetStateAction<any>>
};

export function RegisterFunderDetails({ user, setUser }: IUserSettingsProps) {
  const [focusAreas, setFocusAreas] = useState([{ id: '', name: '', urgent: '' }]);
  const { funder } = useFunderContext();
  const { control, formState: { errors }, watch } = useFormContext();
  // const bankUrl = useWatch({ control, name: 'bank_url', defaultValue: user?.bank_url || "" })
  // Commented out until focus areas are in a multi select
  // const focusAreasSelected = watch('focusAreas', false);
  const invalidUrlMessage = 'Must be a valid url i.e. www.google.com or https://www.google.com';

  const sortFocus = (order: number) => {
    return user.userFocuses.map((userFocus) => {
      if (userFocus.order === order) return userFocus.focus_id;
    }).filter(focus => focus !== undefined)
  }

  // might not need to check against the context funder here, but leaving in as an extra safety measure. 
  const isIndividualFunder = user?.funders?.find((fund) => fund.service_level === FunderServiceLevel.Individual);
  const funderBankUrl = funder?.bank_url;

  // const determineRequiredBank = (): boolean => {
  //   if (bankField.checked) return false;
  //   if (isIndividualFunder && bankField.requireBank) return true;
  //   return false;
  // };

  useEffect(() => {
    FocusApiServices.get().then(response => {
      const focus = response.map((focus: IFocus) => {
        return {
          id: `${focus.id}`,
          name: `${focus.name}`,
          urgent: `${focus.urgent}`
        }
      })
      setFocusAreas(focus)
    });
    if (funderBankUrl && isIndividualFunder) return setUser({ bank_url: funderBankUrl, funder_id: funder?.id });
  }, []);

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={6}>
        <Typography variant="h6">Fund Account Link{determineRequiredBank() === true ? '*' : ''}</Typography>
        <Controller
          name="bank_url"
          control={control}
          defaultValue={user?.bank_url || ""}
          rules={{ required: determineRequiredBank(), pattern: { value: urlRegex, message: invalidUrlMessage } }}
          render={({ field }) =>
            <TextField
              fullWidth
              disabled={bankField.checked}
              variant="outlined"
              defaultValue={user?.bank_url || ""}
              error={!!errors?.bank_url}
              helperText={!!errors?.bank_url && errors?.bank_url?.message}
              placeholder="ex: https://www.bankofamerica.com"
              size="small"
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 3 }}
              {...field}
            />
          }
        />
      </Grid> */}
      {
      // !hideOptOut() &&
      //   <Grid item xs={12} sx={classes.checkbox}>
      //     <Box display={'flex'}>
      //       <Controller
      //         name="activity_visibility"
      //         control={control}
      //         render={({ field }) =>
      //           <Checkbox
      //             disabled={bankUrl}
      //             defaultChecked={bankField.checked}
      //             sx={classes.checkbox}
      //             {...field}
      //             onChange={() => setBankField({ ...bankField, checked: !bankField.checked, requireBank: false })}
      //           />
      //         }
      //       />
      //       <Typography variant='body2' textAlign={'left'} sx={classes.label}>I do not have / I do not wish to add a Fund Account Link</Typography>
      //     </Box>
      //   </Grid>
      }
      {
        // TODO: Uncomment
        // <Grid item xs={12}>
        //   {/* TODO: Implement this functionality for Anynonmous funding in a separate ticket */}
        //   {/* Do we want this for individual funders only? */}
        //   <Typography variant="h6" align="justify">
        //     Anonymous Preference {' '}
        //     <Tooltip
        //       title='Select whether you would like your name to appear as "Anonymous" to grantees and/or other funders. You will be able to see your own name on your commitments. You can also choose to update the name that is displayed when you mark a commitment.'>
        //       <Box component='img' src={blueinfo} alt='more info' />
        //     </Tooltip>
        //   </Typography>
        //   <FormControl>
        //     <Controller
        //       control={control}
        //       name="anonymous_preference"
        //       defaultValue={0}
        //       render={({ field }) =>
        //         <RadioGroup
        //           row={false}
        //           aria-label="profile options"
        //           {...field}
        //         >
        //           <FormControlLabel
        //             value={0}
        //             onChange={(e) => setAnonymousSetting(parseInt(e.target.value))}
        //             control={<Radio size="small" color="primary" />}
        //             label={<Typography variant="body2">Not Anonymous</Typography>}
        //           />
        //           <FormControlLabel
        //             value={1}
        //             onChange={(e) => setAnonymousSetting(parseInt(e.target.value))}
        //             control={<Radio size="small" color="primary" />}
        //             label={<Typography variant="body2">Not Anonymous, Change my Display Name</Typography>}
        //           />
        //           <FormControlLabel
        //             value={2}
        //             onChange={(e) => setAnonymousSetting(parseInt(e.target.value))}
        //             control={<Radio size="small" color="primary" />}
        //             label={<Typography variant="body2">Anonymous to Grantees Only</Typography>}
        //           />
        //           <FormControlLabel
        //             value={3}
        //             onChange={(e) => setAnonymousSetting(parseInt(e.target.value))}
        //             control={<Radio size="small" color="primary" />}
        //             label={<Typography variant="body2">Anonymous to both Funders and Grantees</Typography>}
        //           />
        //         </RadioGroup>
        //       }
        //     />
        //   </FormControl>
        // </Grid>
      }
      {/* {(anonymousSetting === UserVisibilityType.FALSE) ?
        <Grid item xs={12} md={6}>
          <Typography variant="h6" align="justify">Display Name*</Typography>
          <Controller
            name="display_name"
            control={control}
            rules={(anonymousSetting === UserVisibilityType.FALSE) ? { required: requiredErrorMessage } : {}}
            render={({ field }) =>
              <TextField
                placeholder="Enter display name"
                size="small"
                fullWidth
                error={!!errors.display_name}
                helperText={!!errors.display_name && errors.display_name?.message}
                variant="outlined"
                InputProps={{ style: { fontSize: 16 } }}
                inputProps={{ tabIndex: 3 }}
                {...field}
              />
            }
          />
        </Grid>
        : <Grid item xs={0} md={6}></Grid>
      } */}
      {/* <Grid item xs={12}>
      TODO: Finish implementing multiselect to replace dropdowns
        <Typography variant="h6">Focus Areas</Typography>
        <Controller
          name="focusAreas"
          control={control}
          defaultValue={[]}
          // defaultValue={funder.funderfocusAreas!.map((funderfocusArea) => funderfocusArea.focusArea_id)}
          render={({ field }) =>
            <Select
              // sx={classes.menuItem}
              {...field}
              variant="outlined"
              size="small"
              multiple
              fullWidth
              renderValue={(selected) => focusAreas ? _.truncate(renderSelections(selected, focusAreas), { 'length': 60 }) : []}
            >
              {focusAreas && focusAreas?.map((focusArea) => (
                <MenuItem
                  disabled={(focusAreasSelected?.length === 3 && !focusAreasSelected?.includes(focusArea.id)) ? true : false}
                  key={focusArea.id}
                  value={focusArea.id}
                >
                  <Checkbox checked={field.value.includes(focusArea.id)} />
                  <ListItemText primary={_.truncate(focusArea.name)} />
                </MenuItem>
              ))}
            </Select>
          }
        />
      </Grid> */}
      <Grid item xs={0} md={6}></Grid>
      <Grid item xs={12}>
        <Typography variant="h5">
          My Focus Area Interests {' '}
          <Tooltip
            title='Please choose your top three focus areas to create personalized proposal recommendations.'>
            <Box component='img' src={blueinfo} alt='more info' />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">Focus Area 1</Typography>
        <Controller
          name="userFocuses[0].focus_id"
          control={control}
          defaultValue={sortFocus(0) || "none"}
          render={({ field }) =>
            <Select
              sx={{ fontSize: '16px' }}
              size="small"
              fullWidth
              variant="outlined"
              {...field}
            >
              {focusAreas.map((focus) => {
                return (
                  <MenuItem key={focus.id} value={focus.id} sx={{ fontSize: '16px' }}>
                    {window.innerWidth < 900 ? _.truncate(focus.name, { 'length': 40 }) : focus.name}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">Focus Area 2</Typography>
        <Controller
          name="userFocuses[1].focus_id"
          control={control}
          defaultValue={sortFocus(1) || "none"}
          render={({ field }) =>
            <Select
              sx={{ fontSize: '16px' }}
              size="small"
              fullWidth
              variant="outlined"
              {...field}
            >
              {focusAreas.map((focus) => {
                return (
                  <MenuItem key={focus.id} value={focus.id} sx={{ fontSize: '16px' }}>
                    {window.innerWidth < 900 ? _.truncate(focus.name, { 'length': 40 }) : focus.name}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">Focus Area 3</Typography>
        <Controller
          name="userFocuses[2].focus_id"
          control={control}
          defaultValue={sortFocus(2) || "none"}
          render={({ field }) =>
            <Select
              sx={{ fontSize: '16px' }}
              size="small"
              fullWidth
              variant="outlined"
              {...field}
            >
              {focusAreas.map((focus) => {
                return (
                  <MenuItem key={focus.id} value={focus.id} sx={{ fontSize: '16px' }}>
                    {window.innerWidth < 900 ? _.truncate(focus.name, { 'length': 40 }) : focus.name}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </Grid>
    </Grid>
  )
}