import { EnumInterpreter } from "../classes/Interpreter";

export enum OrgStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
}

export enum ConsolidatedStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
  ACTIVE_UNPAID = 2,
  ACTIVE_CANCELED = 3
}

export const FunderStatusInterpreter = new EnumInterpreter<ConsolidatedStatus>(new Map([
  [ConsolidatedStatus.ACTIVE, 'Active'],
  [ConsolidatedStatus.ARCHIVED, 'Archived'],
  [ConsolidatedStatus.ACTIVE_UNPAID, 'Active - Unpaid'],
  [ConsolidatedStatus.ACTIVE_CANCELED, 'Active - Canceled']
]));
  