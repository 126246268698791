import { Grid, Skeleton, Typography } from '@mui/material';
import { CustomLink, InfoContainer } from './styles';

export const OrganizationInfo = ({ amount, funderName }: { amount?: string, funderName?: string}) => {
  return <Grid container maxWidth="1200px">
    <Grid item xs={12}>
      {amount ? <Typography fontSize={32} fontWeight={600}>{`Funded Organizations (${amount})`}</Typography> : <Skeleton variant="text" width={500} height={40} />}
    </Grid>
    <Grid item xs={12}>
      {funderName ? <Typography variant="h5" fontWeight={400}>{funderName}</Typography> : <Skeleton variant="text" width={200} height={40} />}
      </Grid>
    <Grid item xs={12}>
    <InfoContainer>
      <Typography variant="body2" color="textPrimary">
        {`To apply for funding opportunities offered by NY Rural Organization `}
        <CustomLink href="#" to="/sign-up">
          {`log in`}
        </CustomLink>
        {` or `}
        <CustomLink href="#" to={"/justfund/signin"}>
          {`sign up now`}
        </CustomLink>
        {` . If you have any questions, reach out to support at `}
        <CustomLink href="mailto:info@justfund.us" to="#">
          info@justfund.us
        </CustomLink>
        .
      </Typography>
    </InfoContainer>
    </Grid>
  </Grid>
}