export class EnumInterpreter<EnumType> {
    public readonly enumMap: Map<EnumType, string>;

    constructor(instanceEnumMap: Map<EnumType, string>) {
      this.enumMap = instanceEnumMap;
    }

    public interpret(interpretable: EnumType): string {
      return this.enumMap.get(interpretable)!;
    }
}