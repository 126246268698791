import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback
} from 'react';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  DialogActions,
  Button,
  Grid
} from '@mui/material';

interface DialogContextType {
  showLoading: (message?: string) => void;
  closeDialog: () => void;
  showSuccess: (message: string, onClose?: () => void) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    isLoading: false,
    isSuccess: false,
    message: '',
    onCloseSuccess: undefined as (() => void) | undefined
  });

  const showLoading = useCallback(
    (message: string = 'Loading...') => {
      setDialogState({ ...dialogState, isLoading: true, message });
    },
    [dialogState]
  );

  const showSuccess = useCallback(
    (message: string, onClose?: () => void) => {
      setDialogState({
        ...dialogState,
        isSuccess: true,
        message,
        onCloseSuccess: onClose
      });
    },
    [dialogState]
  );

  const closeDialog = useCallback(() => {
    setDialogState({
      ...dialogState,
      isLoading: false,
      isSuccess: false,
      message: ''
    });
    if (dialogState.isSuccess && dialogState.onCloseSuccess) {
      dialogState.onCloseSuccess();
    }
  }, [dialogState]);

  return (
    <DialogContext.Provider value={{ showLoading, closeDialog, showSuccess }}>
      {children}
      <Dialog
        disableEscapeKeyDown
        open={dialogState.isLoading || dialogState.isSuccess}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            closeDialog();
          }
        }}
        sx={{
          zIndex: 10000
        }}
      >
        <DialogContent
          style={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: ' 350px'
          }}
        >
          {dialogState.isLoading && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{
                paddingRight: '4px',
                paddingLeft: '4px'
              }}
            >
              <Grid item xs={3}>
                <CircularProgress
                  sx={{
                    color: '#0b61b0'
                  }}
                  size="2.5rem"
                />
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="h4"
                  sx={{
                    color: 'black',
                    whiteSpace: 'nowrap',
                    fontWeight: 600,
                    fontSize: '25px'
                  }}
                >
                  {dialogState.message}
                </Typography>
              </Grid>
            </Grid>
          )}
          {dialogState.isSuccess && (
            <>
              <Typography variant="h4" style={{ marginTop: 20 }}>
                {dialogState.message}
              </Typography>
              <DialogActions>
                <Button onClick={closeDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DialogContext.Provider>
  );
};
