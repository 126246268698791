import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { IUser } from "../../../shared/interfaces/IUser.interface";
import { IUserAssociations } from "../../../shared/interfaces/IUserAssociations.interface";
import { UserAuthService } from "../../../shared/services/api-service/user-auth-api.service";
import { Link } from 'react-router-dom';
import { UserEntityType } from '../../../shared/enums/user.enum';
import { UserStatus } from '../../../shared/enums/user-status';
import { OrgStatus } from '../../../shared/enums/grantee-status.enum';
import { INotificationState, useMessagingContext } from "../../../shared/components/modals/Notification";
import { NotificationStatus } from '../../../shared/enums/notification-status.enum';
import { useQuery } from '../../../shared/hooks/useQuery.hook';
import { IBloatedFunder } from '../../../shared/interfaces/IFunder';
import { IBloatedGrantee } from '../../../shared/interfaces/IGrantee';
import { BillingStatus } from '../../../shared/enums/stripe';

// TODO - this whole file _needs_ to be updated / cleaned up. 

const styles = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
      border: ' 1px solid #091B30',
    },
  },
  block: {
    display: 'block',
    margin: '10px 0px'
  },
  signInButton: {
    backgroundColor: '#1A75BD',
    height: '70px',
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: 0,
  },
  redUnderline: {
    height: '10px',
    width: '157px',
    backgroundColor: '#E61436',
    margin: '0px 0px 43px 0px'
  },
  signIn: {
    lineHeight: '61px',
    fontSize: '50px',
    fontWeight: 'bold',
  },
  margin: {
    marginLeft: "90px"
  }
};

export function SignIn(props: any) {
  const { setError, control, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });
  const [interactionChoice, setInteractionChoice] = useState(false);
  const [userName, setUserName] = useState<string | undefined>('');
  const [admin, setAdmin] = useState(false);
  const [associations, setAssociations] = useState<IUserAssociations | null>(null);
  const history = useHistory();
  const requiredErrorMessage = "This field is required";
  const redirectTo = useQuery().get('redirect');
  const { notificationDispatch } = useMessagingContext();

  const completeSignIn = async (user: IUser) => {
    // If they haven't registered yet, send them to the register screen
    if (user.status === UserStatus.UNREGISTERED) return history.push(`/register/${user.id}`);
    // if user.is_admin === true they should *always* have access to admin. Small safety net to ensure JF staff does not accidentally lock themselves out completely. 
    // if they try to use the portal they will be logged out automatically, but can change their status at any point in admin. 
    else if (user.status !== UserStatus.ACTIVE && !user.is_admin) {
      setAssociations(null);
      const data = {
        open: true,
        title: "Something Went Wrong",
        message: `This account has been locked. Please reach out to info@justfund.us for resolution if you feel this was made in error.`,
        onclose: async () => {
          await UserAuthService.logout()
        },
        removeCancelBtn: true
      }
      return notificationDispatch({ type: NotificationStatus.UPDATE, data })
    };
    const userAssociations = await UserAuthService.getAssociations(user.id);
    const funderBillingStatus = userAssociations.funders[0]?.billingEvents?.[0]?.subscription_status;
    setAssociations(userAssociations);
    // default redirect for single association accounts
    if (user.is_admin && getActiveAssociations(userAssociations) <= 0) return history.push('/admin');
    const uncollectableStatus = (funderBillingStatus === BillingStatus.UNCOLLECTABLE || funderBillingStatus === BillingStatus.VOID || funderBillingStatus === BillingStatus.CANCELED);

    if (getActiveAssociations(userAssociations) === 1 && uncollectableStatus) {
      const data: INotificationState = {
        open: true,
        title:  'Your plan is no longer active',
        message: "Your subscription plan is no longer active. To renew your plan, please reach out to sales@justfund.us",
        removeCancelBtn: true,
        type: 'NOTIFICATION',
        onClose: async () => {
          await UserAuthService.logout()
        },
      }
      notificationDispatch({ type: NotificationStatus.UPDATE, data });
    } else if (getActiveAssociations(userAssociations) <= 0) {
      const data: INotificationState = {
        open: true,
        title: uncollectableStatus ? 'Your plan is no longer active' : 'Unable to login!',
        message: uncollectableStatus ?
          "Your subscription plan is no longer active. To renew your plan, please reach out to sales@justfund.us" :
          `We're sorry, you no longer have access to this account. If you believe you've received this message in error, please contact info@justfund.us.`,
        removeCancelBtn: true,
        type: 'NOTIFICATION',
        onClose: async () => {
          await UserAuthService.logout()
        },
      }
      notificationDispatch({ type: NotificationStatus.UPDATE, data });
    }  

    // If an admin reset their password, force them to change it
    // TODO: Update this so the user can't do anything in the portal until their password has been changed
    if (user?.force_password_reset) {
      history.push(`/justfund/reset-password-required/${user?.id}`);
    };

    // set user and associations
    setAdmin(user?.is_admin);
    setUserName(user?.first_name);

    // choice of role for login
    setInteractionChoice(true);
  }

  const onSubmit = async (formData: any) => {
    const trimmedEmail = `${formData.email}`.trim(); // clean up spaces before/after
    try {

      const result = await UserAuthService.login(trimmedEmail, formData.password);
      if (result.userId) {
        const { user } = await UserAuthService.verifyLogin();
        await completeSignIn(user);
      }
      return;

    } catch (err: any) {
      setError('email', { type: 'value', message: 'Incorrect email or password.' }, { shouldFocus: true });
    }
  }

  const goToRoute = (rootPath: "f" | "o" | "admin", id?: number) => {
    const shouldRedirect = redirectTo?.split('/')?.[1] === rootPath;
    switch (rootPath) {
      case 'f':
        return id && shouldRedirect ? redirectTo : `/f/funder/${id}`;;
      case 'o':
        return shouldRedirect ? redirectTo : `/o/`;
      case 'admin':
        return shouldRedirect ? redirectTo : `/admin/`;
      default:
        return '';
    }
  }

  // Returns count of non role and active user associations
  const getActiveAssociations = (userAssociations: IUserAssociations): number => {
    return (
      userAssociations?.funders.filter(
        (funder: IBloatedFunder) => funder.status !== OrgStatus.ARCHIVED)
        .concat(
          userAssociations?.grantees.filter(
            (grantee: IBloatedGrantee) => grantee.status !== OrgStatus.ARCHIVED)
        ).length
    );
  };

  function updateEntity(id: number, type: UserEntityType) {
    UserAuthService.setEntity({ entityId: id.toString(), entityType: type?.toString() });
  }

  useEffect(() => {
    if (!UserAuthService.tokenIsExpired()) {
      UserAuthService.verifyLogin().then(verification => {
        completeSignIn(verification.user);
      }).catch(err => console.log(err));
    }
    else UserAuthService.exchangeToken().then(newToken => {
      if (!UserAuthService.tokenIsExpired()) {
        UserAuthService.verifyLogin().then(verification => {
          completeSignIn(verification.user);
        }).catch(err => console.log(err));
      }
    });
  }, []);

  return (
    <Grid
      container
    >
      <Grid 
        container 
        item 
        lg={4} md={8} sm={12} xs={12} 
        sx={styles.root}
        height="auto"
        mt="2rem"
        mx="auto"
        p="4rem 5rem 5rem 4rem"
      >
        <Grid container alignItems="center" sx={{ display: interactionChoice ? 'none' : '' }}>
          <Grid item xs={12}>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography sx={styles.signIn}>Sign In</Typography>
            <Box sx={styles.redUnderline} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="body1"> Don't have an account yet?</Typography>
            <Box sx={styles.block}>
              <a href="https://justfund.us/sign-up/">
                Sign Up
              </a>
            </Box>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ display: interactionChoice ? 'block' : 'none' }}>
            <Grid item xs={12}>
              <Typography variant='h4' data-testid='welcome_header'>Welcome, {userName}!</Typography>
              <Grid item xs={12} mt='1rem' mb='1rem'>
                <Typography variant='h5'>How would you like to interact with the portal?</Typography>
              </Grid>
              {admin ?
                <Grid item sx={{ paddingBottom: '0.5em' }}>
                  <Typography variant='h5' data-testid='admin_header'>As an Admin:</Typography>
                  <Box sx={{ fontSize: 16 }}>
                    <Link to={goToRoute('admin')} data-testid='adminconsole_link'>Go to Admin</Link>
                  </Box>
                </Grid>
                : ""
              }
              {(associations?.funders?.filter((funder) => funder.status !== OrgStatus.ARCHIVED).length) ?
                <Grid item sm={12} mb='0.5rem'>
                  <Typography variant='h6' data-testid='funder_header'>As a Funder:</Typography>
                  {associations?.funders.filter((funder) => funder.status !== OrgStatus.ARCHIVED).map((funder) => {
                    return (
                      <Grid item sm={12} key={funder.id} sx={{ fontSize: 16 }}>
                        <Link to={goToRoute('f', funder.id)} onClick={() => updateEntity(funder?.id, UserEntityType.FUNDER)} data-testid={'funder_link_'+funder.id}>{funder.name}</Link>
                      </Grid>
                    )
                  })}

                </Grid>
                : ""
              }
              {(associations?.grantees?.filter((grantee) => grantee.status !== OrgStatus.ARCHIVED).length) ?
                <Grid item sm={12} mb='0.5rem'>
                  <Typography variant='h6' data-testid='applicant_header'>As an Organization:</Typography>
                  {associations?.grantees.filter((grantee) => grantee.status !== OrgStatus.ARCHIVED).map((grantee) => {
                    return (
                      <Grid item sm={12} key={grantee.id} sx={{ fontSize: 16 }}>
                        <Link to={goToRoute('o')} onClick={() => updateEntity(grantee?.id, UserEntityType.GRANTEE)} data-testid={'applicant_link_'+grantee.id}>{grantee.name}</Link>
                      </Grid>
                    )
                  })}
                </Grid>
                : ""
              }
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ display: interactionChoice ? 'none' : '' }} spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{ required: requiredErrorMessage }}
                render={({ field }) =>
                  <TextField
                    sx={styles.textField}
                    error={!!errors.email}
                    helperText={!!errors.email && <>{errors.email?.message}</>}
                    fullWidth
                    label='Email Address*'
                    autoFocus
                    autoCapitalize='none'
                    autoCorrect='off'
                    placeholder='name@domain.org'
                    inputProps={{
                      'data-testid': 'email'
                    }}
                    {...field}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue={""}
                rules={{ required: requiredErrorMessage }}
                render={({ field }) =>
                  <TextField
                    type="password"
                    sx={styles.textField}
                    error={!!errors.password}
                    helperText={!!errors.password && <>{errors.password?.message}</>}
                    fullWidth
                    label='Password*'
                    autoCapitalize='none'
                    autoCorrect='off'
                    placeholder='Password'
                    inputProps={{
                      'data-testid': 'password'
                    }}
                    {...field}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth sx={styles.signInButton} variant="contained" type='submit' data-testid='signin_btn'>SIGN IN</Button>
            </Grid>
            <Grid item sm={6}>
              <Link to="/justfund/forgot-password">
                I forgot my password
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
