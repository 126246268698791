import * as React from "react";

function SvgEquitableSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="equitable-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#equitable-small_svg__a)">
        <g data-name="Group 2" fill="#9b7fc6">
          <circle cx="12" cy="12" r="10" fill="white" />
          <path
            data-name="Path 5"
            d="M11.92 13.757a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
          <path
            data-name="Path 6"
            d="M11.2 11.632a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.294.294 0 00-.293-.3z"
          />
          <path
            data-name="Path 7"
            d="M11.2 9.507a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.294.294 0 00-.293-.3z"
          />
          <path
            data-name="Path 8"
            d="M10.486 13.757a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
          <path
            data-name="Path 9"
            d="M9.052 13.757a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
          <path
            data-name="Path 10"
            d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm4.485 16.178a.3.3 0 01-.3.3H7.644a.3.3 0 01-.3-.3v-3.506a.3.3 0 01.3-.3h1.855V8.418a.3.3 0 01.3-.3h1.834v-.839a.3.3 0 01.593 0v.839h1.811a.3.3 0 01.3.3v3.954h1.855a.3.3 0 01.3.3z"
          />
          <path
            data-name="Path 11"
            d="M14.786 13.757a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
          <path
            data-name="Path 12"
            d="M13.352 13.757a.3.3 0 00-.3.3v.744a.3.3 0 10.592 0v-.744a.3.3 0 00-.292-.3z"
          />
          <path
            data-name="Path 13"
            d="M12.635 11.632a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
          <path
            data-name="Path 14"
            d="M12.635 9.507a.3.3 0 00-.3.3v.744a.3.3 0 10.593 0v-.744a.3.3 0 00-.293-.3z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgEquitableSmall;
