import { AxiosRequestConfig } from 'axios';
import { ProposalStatus } from '../../enums/proposal-status.enum';
import { ICommonApp, ICreateProposal, IProposal, ITransferredProposal } from '../../interfaces';
import { api } from "./api.service";
import { IPrivateTab } from '../../components/viewProposals/ProposalPanel';

export class ProposalAPIService {
  private static baseRoute = '/api/proposals'

  public static async list(options: AxiosRequestConfig = {}): Promise<IProposal> {
    // hard coding in the limit for now, we should hit this query for the Funder home page when we want to show 'recommended proposals'
    const res = await api.get(`${this.baseRoute}`, options);
    return res.data as IProposal;
  }
  // move this into list
  public static async getByGrantee(id: number | undefined, config?: AxiosRequestConfig): Promise<IProposal[]> {
    const res = await api.get(`${this.baseRoute}`, { ...config, params: { grantee_id: id, limit: 100 } })
    return res.data as IProposal[];
  }

  public static async getLegacyProposals(config?: AxiosRequestConfig): Promise<IProposal[]> {
    const res = await api.get(`${this.baseRoute}`, { ...config, params: { proposalsOnly: 1, limit: 100 } })
    return res.data as IProposal[];
  }

  public static async getById(id: number, config?: AxiosRequestConfig): Promise<IProposal> {
    const res = await api.get(`${this.baseRoute}/${id}`, config);
    return res.data as IProposal;
  }

  public static async editProposal(id: number, proposal: Partial<IProposal>): Promise<IProposal> {
    const res = await api.put(`${this.baseRoute}/${id}`, proposal);
    return res.data as IProposal;
  }
  public static async create(proposal: ICreateProposal): Promise<ICreateProposal> {
    const res = await api.post(`${this.baseRoute}/`, proposal);
    return res.data as ICreateProposal;
  }

  public static async delete(id: number): Promise<ICreateProposal> {
    const res = await api.delete(`${this.baseRoute}/${id}`);
    return res.data as ICreateProposal;
  }
  // this is only for 'archiving' proposal from the grantee home page.
  // no need to update any other info, just the status.
  public static async proposalStatus(id: number, status: ProposalStatus): Promise<any> {
    // const { status } = req
    const res = await api.put(`${this.baseRoute}/${id}`, { status });
    return res.data as IProposal;
  }
  // Even with newer frameworks we need to manually create and click on a link in order to kick off the browser download flow.
  public static async getDocument(id: number): Promise<void> {
    const res = await api.get(`${this.baseRoute}/document/${id}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `proposal-document-${Date.now()}.docx`);
    document.body.appendChild(link);
    link.click();
    // Cleanup
    document.body.removeChild(link);
  }

  public static async transfer(proposalId: number, orgId: number): Promise<ITransferredProposal> {
    const res = await api.put(`${this.baseRoute}/transfer/${proposalId}`, { orgId });
    return res.data as ITransferredProposal;
  }

  public static async getPrivateTab(proposalId: number, config: AxiosRequestConfig): Promise<IPrivateTab> {
    const res = await api.get(`/pages/proposal/private/${proposalId}`, config);
    return res.data as IPrivateTab;
  }

  public static async getCommonAppProposal(proposalId: number): Promise<ICommonApp> {
    const res = await api.get('/api/common-app/' + proposalId);
    return res.data as ICommonApp;
  }
}