export const constants = {
  rowsPerPage: 25,
  adminRowsPerPage: 50
}

// TODO - tie this into other parts of the app where we are declaring scope locally. 
export const scopes =
  [
    { value: 1, name: 'National' },
    { value: 2, name: 'Regional' },
    { value: 3, name: 'State' },
    { value: 4, name: 'Local' },
    { value: 5, name: 'None' }
  ];

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const taxIdRegex = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;

export const onlyNumericRegex = /^[0-9]*$/;

export const isDevMode = () => !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV !== 'prod';

export const MAX_TAG_CHARACTERS = 60;