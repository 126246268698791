import React, { useEffect } from "react";

/*
	This hook can be used without change to the useEffect implementation.
	Proper use of the hook is intended to account for components unmounting
	after an effect has been initiated.  Rather than an empty argument in the
	effect callback, useCancellableEffect passes an AbortSignal object when executing
	the callback.  This AbortSignal can be used in Axios ajax calls as the value
	for the `signal` property on the AxiosRequestConfig object.  API calls that are
	made with this configuration will be canceled if the component unmounts before
	the request returns fully from the server.
*/

declare const UNDEFINED_VOID_ONLY: unique symbol;
// Destructors are only allowed to return void.
type Destructor = () => void | { [UNDEFINED_VOID_ONLY]: never };
type cancellableEffectCallback = (signal: AbortSignal) => void | Destructor;

export const useCancellableEffect = (effect: cancellableEffectCallback, deps: React.DependencyList | undefined) => {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const callbackCleanup = effect(signal);

    return () => {
      abortController.abort();
      if (callbackCleanup) callbackCleanup();
    };
  }, deps);
};
