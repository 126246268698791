import { CommonIcon } from '../../../../../common/Icon/types';

export const BookmarkIcon = ({ size = 29, color = '#515660', filled = false }: CommonIcon) => (
  <svg
    width="auto"
    height={size}
    viewBox="0 0 29 29"
    fill={filled ? color : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9577 25.375L14.4993 19.3333L6.04102 25.375V6.04167C6.04102 5.40073 6.29563 4.78604 6.74884 4.33283C7.20205 3.87961 7.81674 3.625 8.45768 3.625H20.541C21.182 3.625 21.7966 3.87961 22.2499 4.33283C22.7031 4.78604 22.9577 5.40073 22.9577 6.04167V25.375Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.21674 24.088L5.80471 24.3712L6.37124 25.1953L6.78326 24.912L6.21674 24.088ZM14.5 19L14.7883 18.5915C14.6173 18.4708 14.3892 18.4694 14.2167 18.588L14.5 19ZM6.78326 24.912L14.7833 19.412L14.2167 18.588L6.21674 24.088L6.78326 24.912ZM14.2117 19.4085L22.7117 25.4085L23.2883 24.5915L14.7883 18.5915L14.2117 19.4085Z"
      fill={color}
    />
    <path
      d="M6.21674 23.088L5.80471 23.3712L6.37124 24.1953L6.78326 23.912L6.21674 23.088ZM14.5 18L14.7883 17.5915C14.6173 17.4708 14.3892 17.4694 14.2167 17.588L14.5 18ZM6.78326 23.912L14.7833 18.412L14.2167 17.588L6.21674 23.088L6.78326 23.912ZM14.2117 18.4085L22.7117 24.4085L23.2883 23.5915L14.7883 17.5915L14.2117 18.4085Z"
      fill={color}
    />
  </svg>
);
