export enum SurveyType {
  CYCLE,
  GENERAL
}

export enum SurveyStatus {
  ACTIVE,
  INACTIVE
}

export enum SurveyQuestionType {
  OPEN,
  SINGLE,
  MULTIPLE,
  RATING
}

export enum SurveyQuestionGetter {
  QUESTION_DELETE = 'deleteQuestion',
  QUESTION_DESCRIPTION = 'description',
  QUESTION_HASH = 'hash',
  QUESTION_ID = 'id',
  QUESTION_PAYLOAD = 'payload',
  QUESTION_REQUIRED = 'is_required',
  QUESTION_TYPE = 'type',
  SUPPLEMENTARY_QUESTIONS = 'questions',
  SURVEY_DELETE = 'deleteSurvey'
}
