import { CommonIcon } from '../../../../../common/Icon/types';

export const ProfileIcon = ({ size = 35, color = '#515660' }: CommonIcon) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 34 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17.5715" r="16.3929" stroke={color} strokeWidth="1.21429" />
    <path
      d="M33.3929 18.1787C33.3929 27.5878 26.0336 35.1787 17 35.1787C7.96638 35.1787 0.607143 27.5878 0.607143 18.1787C0.607143 8.76958 7.96638 1.17868 17 1.17868C26.0336 1.17868 33.3929 8.76958 33.3929 18.1787Z"
      stroke={color}
      strokeWidth="1.21429"
    />
    <path
      d="M33.3929 18.7858C33.3929 28.5501 26.0148 36.393 17 36.393C7.98525 36.393 0.607143 28.5501 0.607143 18.7858C0.607143 9.02153 7.98525 1.17868 17 1.17868C26.0148 1.17868 33.3929 9.02153 33.3929 18.7858Z"
      stroke={color}
      strokeWidth="1.21429"
    />
    <path
      d="M25.6841 29.9946V25.5111C25.6841 24.4211 25.2111 23.3757 24.3692 22.605C23.5272 21.8342 22.3853 21.4012 21.1947 21.4012H12.2159C11.0253 21.4012 9.88339 21.8342 9.04147 22.605C8.19955 23.3757 7.72656 24.4211 7.72656 25.5111V29.9946"
      fill={color}
    />
    <path
      d="M16.7053 17.2913C19.1847 17.2913 21.1947 15.4512 21.1947 13.1814C21.1947 10.9116 19.1847 9.07153 16.7053 9.07153C14.2259 9.07153 12.2159 10.9116 12.2159 13.1814C12.2159 15.4512 14.2259 17.2913 16.7053 17.2913Z"
      fill={color}
    />
    <path
      d="M25.6841 29.9946V25.5111C25.6841 24.4211 25.2111 23.3757 24.3692 22.605C23.5272 21.8342 22.3853 21.4012 21.1947 21.4012H12.2159C11.0253 21.4012 9.88339 21.8342 9.04147 22.605C8.19955 23.3757 7.72656 24.4211 7.72656 25.5111V29.9946M21.1947 13.1814C21.1947 15.4512 19.1847 17.2913 16.7053 17.2913C14.2259 17.2913 12.2159 15.4512 12.2159 13.1814C12.2159 10.9116 14.2259 9.07153 16.7053 9.07153C19.1847 9.07153 21.1947 10.9116 21.1947 13.1814Z"
      stroke={color}
      strokeWidth="2.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6562 26.8811H7.89522L7.28516 30.9429L15.7852 33.9644L20.7065 33.2639L26.7137 30.9287L21.9266 26.8811H17.6562Z"
      fill={color}
    />
  </svg>
);
