import { decode } from 'he';

export const htmlToText = (html?: string): string => {
  if (!html) return '';

  const tempDiv = document.createElement('span');
  tempDiv.innerHTML = html;
  const text = tempDiv.textContent || tempDiv.innerText || '';

  const decodedText = decode(text);

  const cleanedText = decodedText
    .replace(/&nbsp;/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();

  return cleanedText;
};
