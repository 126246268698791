import { styled } from '@mui/material';
import { JFProfileImageProps } from './JFProfileImage.types';

export const JFProfileImage = styled('img')<JFProfileImageProps>(
  ({
    hasSrc,
    size,
    borderWidth = 3,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 40,
    marginRight = 40,
    objectFit = 'contain',
    backgroundColor = '#FFF'
  }) => ({
    backgroundColor: hasSrc ? backgroundColor : 'transparent', 
    borderColor: hasSrc ? '#000' : 'transparent',
    borderRadius: hasSrc ? '50%' : '0',    
    borderStyle: hasSrc ? 'solid' : 'none',    
    borderWidth: hasSrc ? borderWidth : '0',
    height: size,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    objectFit,
    width: size
  })
);
