import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { IProfile } from '../../../shared/interfaces/IProfile';

interface ProfileTypeTagProps {
  types: ('Nonprofit' | 'JustFunder' | 'Fund' | 'Project' | 'Beta')[];
}

const typeStyles: {
  [key in ProfileTypeTagProps['types'][number]]: {
    backgroundColor: string;
    color: string;
  };
} = {
  Nonprofit: {
    backgroundColor: '#E3F2FD',
    color: '#004391'
  },
  JustFunder: {
    backgroundColor: '#E8F5E9',
    color: '#044B0E'
  },
  Fund: {
    backgroundColor: '#F3E5F5',
    color: '#462D58'
  },
  Project: {
    backgroundColor: '#FBE9E7',
    color: '#8A3102'
  },
  Beta: {
    backgroundColor: '#FFF7E0',
    color: '#8A3102'
  }
};

const baseStyles = `
    height: 28px;
    font-size: 14px;
    font-family: 'Proxima Nova';
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    word-wrap: break-word;
    margin: 4px;
    border: none; 
    border-radius: 6px;
`;

const getChipStyles = (type: ProfileTypeTagProps['types'][number]) => `
    background-color: ${typeStyles[type].backgroundColor};
    color: ${typeStyles[type].color};
    ${baseStyles}
`;

export const StyledChip = styled(Chip)<{ type: ProfileTypeTagProps['types'][number] }>`
  ${({ type }) => getChipStyles(type)}
`;

const ProfileTypeTag: React.FC<ProfileTypeTagProps> = ({ types }) => {
  return (
    <>
      {types.map((type, index) => (
        <StyledChip key={index} label={type} type={type} variant="outlined" />
      ))}
    </>
  );
};

export default ProfileTypeTag;

export const getProfileTypes = (
  profile: IProfile
): ('Nonprofit' | 'JustFunder' | 'Fund' | 'Project')[] => {
  const types: ('Nonprofit' | 'JustFunder' | 'Fund' | 'Project')[] = [];

  if (profile.funder) {
    types.push('JustFunder');
  }

  if (
    !profile.funder &&
    (profile?.detail?.tax_status_id === 1 || profile?.detail?.tax_status_id === 2 || profile?.detail?.tax_status_id === 4)
  ) {
    types.push('Nonprofit');
  }

  if (profile?.fund === 1) {
    types.push('Fund');
  }

  return types;
};
