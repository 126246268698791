import React from 'react'
import { CardContentWrapper, CardLeftBorder, CardWrapper } from './card.styled'
import { CardProps } from './card.types'

export type CardWrapperProps = CardProps & {
  withshadow?: boolean;
};

export const Card: React.FC<CardWrapperProps> = ({ children, action, status = 'none', withshadow, ...props }) => (
  <CardWrapper data-testid='card' status={status} withshadow={withshadow} >
    {status !== 'none' && <CardLeftBorder data-testid="card-border" status={status} />}
    <CardContentWrapper data-testid="card-content" onClick={action} status={status} {...props}>
      {children}
    </CardContentWrapper>
  </CardWrapper>
)
