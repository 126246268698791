import { Link, styled } from '@mui/material'
import { LinkProps } from './link.types'

export const LinkWrapper = styled(Link)<LinkProps>(({fontSize = 16, fontWeight = 500}) => ({
  color: '#4288C5 !important',
  cursor: 'pointer',
  fontWeight,
  fontSize,
  textDecoration: 'none',
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word'
}))
