import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { errorToast } from '../../../shared/util/toast';
import { useDialog } from '../../../shared/context/GeneralDialog.context';
import { UserAuthService } from '../../../shared/services/api-service/user-auth-api.service';

import { Root, EmailText, MessageText, UnsubscribeText, StyledButton } from './UnsubscribeEmailPage.styled';

const UnsubscribeEmailPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const email = query.get('email');
  const userId = query.get('userId');

  const { showLoading, closeDialog } = useDialog();

  useEffect(() => {
    if (token) {
      const unsubscribe = async () => {
        showLoading('Unsubscribing...');
        try {
          await UserAuthService.unSubscribeEmailFrequencyByToken(token);
        } catch (err) {
          errorToast('Failed to unsubscribe. Please contact administrator.');
        } finally {
          closeDialog();
        }
      };

      unsubscribe();
    }
  }, [token]);

  const handleManagePreference = () => {
    window.location.href = `/f/userprofile/${userId}?tab=settings`;
  };

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Root>
        <>
          <EmailText>{email}</EmailText>
          <UnsubscribeText>
            You’ve unsubscribed. You will no longer receive updates about
            funding needs in your focus areas.
          </UnsubscribeText>
          <MessageText>
            You can resubscribe if this was an error.
          </MessageText>
          <StyledButton
            variant="outlined"
            onClick={handleManagePreference}
          >
            Manage preference
          </StyledButton>
        </>
      </Root>
    </Box>
  );
};

export default UnsubscribeEmailPage;
