import { EnumInterpreter } from "../../classes/Interpreter";

export enum BillingEventType {
  INVOICE = 'invoice',
  INVOICE_ITEM = 'invoiceitem',
  SUBSCRIPTION = 'subscription'
}

export enum BillingStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  UNCOLLECTABLE = 'uncollectable',
  VOID = 'void',
  CANCELED = 'canceled'
}

export const FunderBillingStatusInterpreter = new EnumInterpreter(new Map([
  [BillingStatus.DRAFT, 'Draft'],
  [BillingStatus.OPEN, 'Open'],
  [BillingStatus.PAID, 'Paid'],
  [BillingStatus.UNCOLLECTABLE, 'Uncollectable'],
  [BillingStatus.VOID, 'Void']
]));
