import DOMPurify from 'dompurify';
import styled from 'styled-components';

interface IJFReadOnlyTextFieldProps {
  value?: string;
  isHtml?: boolean;
}

const StyledDiv = styled.div`
  white-space: normal;
  font-size: 16px;

  p {
    margin: 0px 0px;
  }
`;

const JFReadOnlyTextField = (props: IJFReadOnlyTextFieldProps) => {
  const { value = "", isHtml = false } = props;

  const htmlValue = value ? DOMPurify.sanitize(value, { ADD_ATTR: ['target'] }) : ''

  if (isHtml) {
    return (
      <StyledDiv
        dangerouslySetInnerHTML={{
          __html: htmlValue
        }}
      />
    );
  }

  return (
    <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>{value}</div>
  );
};

export default JFReadOnlyTextField;
