import { Button, Divider, Grid, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { dollarFormatter } from '../../../util/util'
import { Card } from '../../../../common/components'
import { AvatarAndName } from '../../avatarAndName'
import { IProfile } from '../../../interfaces/IProfile'
import { IProfile as IViewProfile } from '../../../interfaces/IViewProfile.interface'
import { PublicAPIService } from '../../../services/api-service/public-api.service'
import { AmountCard } from '../amountCard/AmountCard'
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface IProfileFundingProps {
  entity: IViewProfile
  profileSlug: string
  setIsOrganizationsModalOpen: Dispatch<SetStateAction<boolean>>
}

export const ProfileFunding = ({
  entity,
  profileSlug,
  setIsOrganizationsModalOpen
}: IProfileFundingProps) => {
  const [funded, setFunded] = useState<IProfile[]>([]);

  const renderRecentlyFunded = useCallback(() => funded.map((profile) => {
    const isFunder = !!profile.funder;
    const organization = {
      id: isFunder ? profile.funder?.id! : profile.id,
      name: profile.name,
      logo: isFunder ? profile.funder?.logo?.url : profile.logoUrl,
      isPublished: isFunder ? profile.funder?.isPublished : profile.isPublished,
      isGrantee: !isFunder,
    }
    return (
      <Grid item container key={`avatarAndName-${profile.name}`}>
        <AvatarAndName
          shortProfile={organization}
          avatarSize={56}
        />
      </Grid>
    )
  }), [funded]);

  useEffect(() => {
    PublicAPIService.getFundedTabBySlug(
      profileSlug,
      {
        limit: 3,
        sortFields: [
          { sortBy: 'created_at', sortDir: 'desc' },
          { sortBy: 'id', sortDir: 'desc' }
        ]
      }
    ).then((res) => {
      setFunded(res);
    })
    .catch(err => err.message !== 'canceled' && err);
  }, [profileSlug]);

  return (
    <Card paddingX={30} paddingY={40}>
      <Grid container spacing={4}>
        <Grid item container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h2" fontSize={24} fontWeight={600}>JustFunded</Typography>
          </Grid>
          <Grid item>
            <AmountCard
              amount={dollarFormatter(entity.totalFunded, 0)}
              currency={'USD'}
              title={'Total'}
              icon={RequestQuoteOutlinedIcon}
            />
          </Grid>
          <Grid item>
            <AmountCard
              amount={entity?.commitments.toString()}
              title={'Grants'}
              icon={VerifiedOutlinedIcon}
            />
          </Grid>
          {funded?.length ? (
            <>
              <Grid item marginY={2}>
                <Divider />
              </Grid>
              <Grid item paddingBottom={2}>
                <Typography variant="h3" fontSize={24} fontWeight={600}>Recently Funded</Typography>
              </Grid>
              {renderRecentlyFunded()}
            </>
          ): null}
        </Grid>
        <Grid item>
          {funded?.length ? (
            <Button
              variant="text"
              onClick={() => setIsOrganizationsModalOpen?.(true)}
              endIcon={<OpenInNewIcon />}
            >
              <Typography fontSize={'16px'} textTransform={'none'}>See more</Typography>
            </Button>
          ): null}
        </Grid>
      </Grid>
    </Card>
  )
}
