import React, { createContext, Dispatch, useContext, useEffect, useState } from 'react';
import { IBloatedGrantee } from '../../shared/interfaces/IGrantee';
import { GranteeAPIService } from '../../shared/services/api-service/grantee.service';
import { IUser } from '../../shared/interfaces/IUser.interface';
import { UserService } from '../../shared/services/api-service/user-api.service';
import { IRfp } from '../../shared/interfaces/IRfp.interfaces';
import { RfpService } from '../../shared/services/api-service/rfp-api.service';
import { GranteeUserRole } from '../../shared/enums/grantee.enum';
import { useCancellableEffect } from '../../shared/hooks/useCancellableEffect';
import { UserAuthService } from '../../shared/services/api-service/user-auth-api.service';
import { IUserAssociations } from '../../shared/interfaces/IUserAssociations.interface';
import { hotjarService } from '../../shared/services/hotjar-service/hotjar.service';
import { HubspotService } from '../../shared/services';

type UserContextProvider = {
  grantees: IBloatedGrantee[],
  grantee: IBloatedGrantee | null,
  selectGrantee: (id: number) => void,
  requestFundeesUpdate: () => void,
  user: IUser | null,
  rfp: IRfp | null,
  selectRfp: (id: number) => void,
  hasRole: (role: GranteeUserRole) => boolean,
  setGrantee: React.Dispatch<React.SetStateAction<any>>
}


const GranteeContext = createContext<UserContextProvider>({
  grantees: [] as IBloatedGrantee[],
  grantee: null,
  selectGrantee: () => {},
  requestFundeesUpdate: () => {},
  user: null,
  rfp: null,
  selectRfp: () => {},
  setGrantee: ()=>{},
  hasRole: role => false
});

export const useGranteeContext = () => useContext(GranteeContext);


export default function UserProvider({ children }: { children: React.ReactChild}) {
  const [ grantees, setGrantees] = useState([] as IBloatedGrantee[]);
  const [ grantee, setGrantee ]:[ IBloatedGrantee | undefined, Dispatch<React.SetStateAction<IBloatedGrantee | undefined>>] = useState();
  const [ user, setUser ]: [IUser | undefined , Dispatch<React.SetStateAction<IUser | undefined>>]= useState();
  const [ updateFundees, setUpdate] = useState(0);
  const [ rfp, setRfp ]: [IRfp | undefined, Dispatch<React.SetStateAction<IRfp | undefined>>] = useState();
  const [associations, setAssociations] = useState<IUserAssociations | null>(null);
  const requestFundeesUpdate = () => {
    setUpdate(updateFundees + 1);
  }

  const hasRole = (role: GranteeUserRole): boolean => {
    return user?.granteeUsers?.some(userRole => userRole.role_id === role && grantee?.id === userRole.grantee_id) || false;
  }

  const selectGrantee = async (id: number) => {
    const newGrantee = await GranteeAPIService.getById(id)
    setGrantee(newGrantee);
    return;
  }

  const selectRfp = async (id: number) => {
    if (id === 0) {
      setRfp(undefined)
    }
    const newRfp = await RfpService.getById(id);
    setRfp(newRfp);
    return;
  }

  useEffect(() => {
    UserAuthService.verifyLogin().then(async loginVerification => {
      const userId = loginVerification.user.id;
      hotjarService.userId = userId;
      HubspotService.identifyUser(loginVerification.user?.email!);
      UserAuthService.getAssociations(userId).then( userAssociations => {
        setAssociations(userAssociations);
        hotjarService.hasGranteeAssociations = !!associations?.grantees && associations.grantees!.length > 0;
        hotjarService.hasFunderAssociations = !!associations?.funders && associations.funders!.length > 0;
      });
      UserService.getById(userId).then(jfUser => {
        setUser(jfUser)
        hotjarService.userEmail = jfUser.email!;
      });
    });
  }, []);

  useCancellableEffect(signal => {
    if (grantee) {
      GranteeAPIService.getById(grantee.id, { signal })
        .then(response => setGrantees([response]))
        .catch(err => err.message !== 'canceled' && err);
    }
  }, [grantee])

  return (
    <GranteeContext.Provider
      value={{
        grantees,
        grantee: grantee ? grantee : null,
        selectGrantee,
        user: user ? user : null,
        rfp: rfp ? rfp : null,
        selectRfp,
        requestFundeesUpdate,
        hasRole,
        setGrantee
      }}
    >
      {children}
    </GranteeContext.Provider>
  )
}
