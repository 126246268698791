import React from "react";
import {
  Typography,
  Grid,
} from "@mui/material";
import JFReadOnlyTextField from "../../../../../common/components/JFReadOnlyTextField/JFReadOnlyTextField.component";
import { IProfile } from "../../../../interfaces/IProfile";
import { ProfileCardTitleAvatar } from "../../../profileCard/ProfileCard.styles";
import { CloseButton, Container, CustomDrawer, CustomLink, DrawerBox, InfoContainer, TitleContainer } from "./styles";

interface ProfileDrawerProps {
  open: boolean;
  onClose: () => void;
  entity: IProfile;
  funderName: string
}

const ProfileDrawer: React.FC<ProfileDrawerProps> = ({ open, onClose, entity, funderName }) => {

  const {
    mission,
    description,
    team_description,
  } = entity.detail;

  return (
    <CustomDrawer anchor="right" open={open} onClose={onClose} sx={{ zIndex: 9999 }}>
      <DrawerBox>
        <TitleContainer container alignItems="center" justifyContent={"space-between"}>
          <Grid item>
            <ProfileCardTitleAvatar
              src={entity.logoUrl}
              alt={entity.name}
            >
              {entity.name.charAt(0).toUpperCase()}
            </ProfileCardTitleAvatar>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5">{entity?.name}</Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent={"flex-end"}>
            <CloseButton onClick={onClose} />
          </Grid>
        </TitleContainer>
        <Container container rowSpacing={2}>
          <InfoContainer>
            <Typography variant="body2" color="textPrimary">
              {`To apply for funding opportunities offered by ${funderName ?? '-'} Organization `}
              <CustomLink href="#" to="/sign-up">
                {`log in`}
              </CustomLink>
              {` or `}
              <CustomLink href="#" to={"/justfund/signin"}>
                {`sign up now`}
              </CustomLink>
              {` . If you have any questions, reach out to support at `}
              <CustomLink href="mailto:info@justfund.us" to="#">
                info@justfund.us
              </CustomLink>
              .
            </Typography>
          </InfoContainer>
          {mission &&
            <Grid xs={12} item>
              <Typography sx={{ marginBottom: 1 }} variant="h5">Mission Statement</Typography>
              <JFReadOnlyTextField value={mission} isHtml={true} />
            </Grid>
          }
          {!!description &&
            <Grid xs={12} item>
              <Typography sx={{ marginBottom: 1 }} variant="h5">{Array.isArray(entity?.proposals) && `Organization`}  Description</Typography>
              <JFReadOnlyTextField value={description} isHtml={true} />
            </Grid>
          }
          {!!team_description &&
            <Grid xs={12} item>
              <Typography sx={{ marginBottom: 1, marginTop: 2 }} variant="h5"> Team Description</Typography>
              <JFReadOnlyTextField value={team_description} isHtml={true} />
            </Grid>
          }
        </Container>
      </DrawerBox>
    </CustomDrawer>
  );
};

export default ProfileDrawer;
