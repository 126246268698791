import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { Redirect, useHistory, useParams } from 'react-router';
import { useCancellableEffect } from '../../../shared/hooks/useCancellableEffect';
import { PublicAPIService } from '../../../shared/services/api-service/public-api.service';
import { LoadingPage } from '../../../shared/components/LoadingPage';
import { useEffect } from 'react';
import { Loading } from '../../../shared/components/legacy-ui/Loading';

export const DynamicRedirect = () => {
    const { slug } = useParams<{ slug: string }>();

    // have this set up on the API to return a funders name / website if JF decides they want to open this up to other funders and show a profile,
    // or a funder / grantee users website etc. 
    // useCancellableEffect(signal => {
    //     PublicAPIService.funderSlug(slug, { signal }).then(response => {
    //         return response.website ? window.location.href = response.website : null;
    //     }).catch(err => err.message !== 'cancelled' && err);
    // }, [])

    useEffect(() => {
        if (slug.toLowerCase() === 'houseus') window.location.replace('http://www.houseus.org/');
        return;
    }, [slug])

    return (
        <Loading />
    )
}