import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Divider, InputAdornment, TextField, Theme, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useHistory, useParams } from "react-router-dom";
import { useMessagingContext } from '../../../shared/components/modals/Notification';
import { UserAuthService } from '../../../shared/services/api-service/user-auth-api.service';
import { Controller, useForm } from "react-hook-form";
import { NotificationStatus } from '../../../shared/enums/notification-status.enum';

const classes = {
  root: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.25)',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
      border: ' 1px solid #000000',
      boxShadow: 'inset 0 2px 00 #ffffff'
    },
    marginBlockEnd: '25px',
    width: '300px'
  },
  labelLeft: {
    fontWeight: 'bold',
    paddingBottom: '10px',
    lineHeight: '22px',
    letterSpacing: 0,
  },
  resetHeading: {
    lineHeight: '61px',
    fontSize: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '22px'
  },
  redUnderline: {
    height: '10px',
    backgroundColor: '#E61436',
    margin: '1em',
    width: '70%',
    marginLeft: '15%'
  },
};

interface IParams {
  token: string;
  resetType: 'new' | 'reset'
}

const passwordMessage = {
  'reset': { open: true, title: "Password Reset", message: "Password Reset Successfully. Please Log In.", removeCancelBtn: true },
  'new': { open: true, title: "Password Created", message: "Password Created Successfully. Please Log In.", removeCancelBtn: true }
}

export const NewPassword = () => {


  const { token, resetType } = useParams<IParams>();
  const history = useHistory();
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex = /[a-z0-9!@#:;<>?~%^$*&()\\-`.+,/\"]{8,}/i;

  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });

  const { notificationDispatch } = useMessagingContext();

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleOpen = () => {
    const data = { ...passwordMessage[resetType], onClose: handleClose };
    notificationDispatch({ type: NotificationStatus.UPDATE, data });
  }

  const handleClose = (val: boolean) => {
    if (val) {
      // the api endpoint to update a users password logs them out, no need call userAuthService.logout here
      history.push('/justfund/signin')
    }
  };

  const handleErrorNotification = () => {
    notificationDispatch({ type: NotificationStatus.UPDATE, data: { open: true, title: 'Error', message: "Please confirm the email address you entered matches your account." } })
  }
  const requiredErrorMessage = "This field is required";

  const { control, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });

  const onSubmit = async (formData: any) => {
    let modified: any;
    modified = formData;
    try {
      const reset = await UserAuthService.resetPassword(token, modified.email, modified.password);
      if (reset) handleOpen();
    } catch (err) {
      console.error(err)
      handleErrorNotification()
    }
  }


  return (
    <Grid container>
      <Box sx={{ ...classes.root, width: '1015px', height: '569px', marginX: 'auto', marginTop: '2em', textAlign: 'center' }}>
        <Grid item sm={12} sx={{ marginBottom: '1em' }}>
          <Typography variant="h1" sx={classes.resetHeading}>Create a New Password</Typography>
          <Box sx={classes.redUnderline} />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Typography sx={classes.labelLeft}>Email</Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: requiredErrorMessage, pattern: { value: emailRegex, message: "Must be a valid email address" } }}
              render={({ field }) =>
                <TextField sx={classes.textField}
                  placeholder="Confirm Email"
                  size="small"
                  error={!!errors?.email}
                  helperText={!!errors?.email && errors?.email?.message}
                  variant="outlined"
                  InputProps={{ style: { fontSize: 16 } }}
                  {...field}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={classes.labelLeft}>New Password</Typography>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: requiredErrorMessage, pattern: { value: passwordRegex, message: "Password must be at least 8 characters" } }}
              render={({ field }) =>
                <TextField
                  sx={classes.textField}
                  placeholder="Enter new password"
                  size="small"
                  variant="outlined"
                  id="input-with-icon-textfield"
                  type={password.showPassword ? 'text' : 'password'}
                  error={!!errors?.password}
                  helperText={!!errors?.password && errors?.password?.message}
                  {...field}
                  InputProps={{
                    style: { fontSize: 16 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large">
                          {password.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Grid>
          <Grid item sm={12}>
            <Button variant='contained' type="submit">Submit</Button>
          </Grid>
        </form>
        <Grid item sm={12}>
        </Grid>
      </Box >
    </Grid>
  );
}
