import { AxiosRequestConfig } from 'axios';
import { api } from './api.service';

export abstract class BaseApiService {
  protected static handleObjectUrl(objectUrl: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    // Cleanup
    document.body.removeChild(link);
  }

  protected static async getCsv(
    url: string,
    fileName: string,
    options: AxiosRequestConfig = {}
  ): Promise<void> {
    const res = await api.get(url, {
      responseType: 'blob',
      ...options
    });

    if (res.data?.size > 0) {
      const objectUrl = window.URL.createObjectURL(res.data);
      this.handleObjectUrl(objectUrl, fileName);
    }
  }

  protected static async postCsv(
    url: string,
    fileName: string,
    data: any,
    options: AxiosRequestConfig = {}
  ): Promise<void> {
    const res = await api.post(url, data, {
      responseType: 'blob',
      ...options
    });

    if (res.data?.size > 0) {
      const objectUrl = window.URL.createObjectURL(res.data);
      this.handleObjectUrl(objectUrl, fileName);
    }
  }
}
