import { Box, Grid, Icon, Typography } from "@mui/material";

interface AmountCardProps {
  amount: string;
  currency?: string;
  title: string;
  icon: any;
}

export const AmountCard = ({ amount, currency, title, icon }: AmountCardProps) => {
  return (
    <Box sx={{ backgroundColor: "#EFF5F9", borderRadius: '10px', padding: '20px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item justifyItems='center'>
          <Icon component={icon} fontSize="small" />
        </Grid>
        <Grid item>
          <Box padding={0}>
            <Typography variant="caption" fontSize={20} fontWeight={600}>
              {amount}
            </Typography>
            {currency ? (
              <Typography
                variant="caption"
                fontSize={20}
                fontWeight={400}
                sx={{ marginLeft: '8px' }}
              >
                {currency}
              </Typography>
            ): null}
          </Box>
          <Box padding={0}>
            <Typography variant="caption" fontSize={16} fontWeight={400}>
              {title}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}