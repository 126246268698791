import { ILoginFunder } from "../interfaces/IFunder";
import { IUser } from "../interfaces/IUser.interface";

declare global {
    interface Window { Appcues: any; }
}

interface Group {
    id: any;
    name?: string;
    service_level?: any;
    created_at?: string;
    type: string;
}

interface Track {
    id: any;
    route?: string;
    article: any;
}

const appcueId : string | undefined  = process.env.REACT_APP_APPCUE_ID;

export const identifyUserWithAppcues = (user: IUser, funder: ILoginFunder, roles: any ) => {


    if (!appcueId) {
        return;
    }
    
    window.Appcues.identify(
        `u${user.id}f${funder.id}g${funder.grantee_id}`, // unique, required
    {
        userId: `u${user.id}f${funder.id}g${funder.grantee_id}`, // unique, required  
        display_name: user.first_name + ' ' + user.last_name, // current user's display name
        email: user.email, // current user's email
        funder_id: `u${user.id}f${funder.id}`,
        funder_name: funder.name,
        is_admin: user.is_admin,
        grantee_id: `u${user.id}g${funder.grantee_id}`,
        grantee_name: funder?.grantee?.name,
        service_level: funder.service_level,
        ...roles
    }
    );
};

export const groupWithAppcues = (group: Group) => {

    if (!appcueId) {
        return;
    }
    
    window.Appcues.group(
        group.id,
      {
        group_id: group.id,
        group_name: group.name,
        group_service_level: group.service_level,
        group_created_at: group.created_at,
        group_type: group.type
      }
    );
  };

export const trackWithAppcues = (track: Track) => {

    if (!appcueId) {
        return;
    }
    
    window.Appcues.track(track.id, {
        url: track.route,
        article: track.article,
      }
    );
  };