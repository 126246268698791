import { ArrowBack } from "@mui/icons-material";
import { Box, Drawer, Grid, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { jfTheme } from "../../../../../styles/jf.theme";
import InfiniteScroll from "react-infinite-scroll-component";

export const TitleContainer = styled(Grid)({
  borderBottom: '1px solid #EEEEEE',
  padding: '20px',
});

export const InfoContainer = styled(Box)({
  margin: '16px 0px',
});

export const BackButtonTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  cursor: 'pointer',
});

export const BackIcon = styled(ArrowBack)({
  width: '20px',
  height: '20px',
});

export const Container = styled(Grid)({
  justifyContent: 'center',
  maxHeight: '85vh',
  overflow: 'scroll',
  padding: '0px 24px',
  marginTop: '6px',
});

export const DrawerBox = styled(Box)({
  width: '640px',
  overflow: 'hidden',
});

export const CustomLink = styled(Link)({
  color: jfTheme.palette.secondary.light,
});

export const CustomDrawer = styled(Drawer)({
  zIndex: 9999,
});

export const ScrollableContainer = styled(InfiniteScroll)({
  height: '100vh',
  paddingTop: '24px',
  backgroundColor: '#F7F7F7',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});