import * as React from "react";

function SvgTransportSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="transport-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#transport-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#30d3f9">
            <path
              data-name="Path 32"
              d="M8.83 11.8a.67.67 0 10.65.67.65.65 0 00-.65-.67z"
            />
            <path
              data-name="Path 33"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.82 13.92a1.26 1.26 0 01-1.06 1.23v1.26a.73.73 0 01-1.45 0v-1.24H8.69v1.24a.73.73 0 01-1.45 0v-1.26a1.26 1.26 0 01-1.06-1.23v-1.49a2.69 2.69 0 011.43-2.36v-.05l.59-1.95a1.67 1.67 0 011.59-1.2h4.42a1.67 1.67 0 011.59 1.2l.58 1.93v.05a2.69 2.69 0 011.43 2.36z"
            />
            <path
              data-name="Path 34"
              d="M15.17 11.8a.67.67 0 10.65.67.65.65 0 00-.65-.67z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgTransportSmall;
