import React from 'react';
import { LoadingPage } from '../../../shared/components/LoadingPage';
import { useFetch } from '../../hooks';

export type IFetchProps = {
  renderSuccess: (data: any) => any;
  url: string;
  params?: { [key: string]: string | number | string[] | number[]} | null;
  loadingFallback?: any;
  renderError?: (error: any) => any;
  reload?: boolean;
}

export function FetchPage({
  renderSuccess,
  url,
  params = null,
  loadingFallback = <LoadingPage
    message={'Loading...'}
    error={false}
  />,
  renderError = data => {
    console.error(data)
    return <p>{typeof data === 'string' ? data : 'Page load error, please try refreshing the page.'}</p>
  }
}: IFetchProps) {
  const { loading, data, error } = useFetch(url, params);

  if (loading) return loadingFallback;
  if (error) return renderError(error);
  if (data) return renderSuccess(data);

  return null;
}