import { AxiosRequestConfig } from "axios";
import { IFocus } from "../../interfaces/IFocus";
import { INameAndId } from "../../interfaces/INameAndID.interfaces";
import { api } from "./api.service";

export class FocusApiServices {
  
  private static baseRoute = "/api/focus"

  public static async getById(id: number): Promise<IFocus> {
    const res = await api.get(`${this.baseRoute}/${id}`);
    return res.data as IFocus;
  }
  
  public static async list(signal?: AxiosRequestConfig): Promise<INameAndId[]> {
    const res = await api.get(`${this.baseRoute}/`, { ...signal })
    return res.data as INameAndId[];
  }

  public static async get():Promise<IFocus[]> {
    const res = await api.get(`${this.baseRoute}`)
    return res.data as IFocus[];

  }

  public static async update(focus: Partial<IFocus>, id: number): Promise<IFocus> {
    const res = await api.patch(`${this.baseRoute}/${id}`, focus);
    return res.data as IFocus;
  }


  public static async create(focus: IFocus): Promise<IFocus> {
    const res = await api.post(`${this.baseRoute}`, focus);
    return res.data as IFocus;
  }

}