import { upperCase } from "lodash";
import moment from "moment";

/**
 * @param {any[]} array
 * @param {string} property
 */
export function sortByProperty (array, property, ascending = true) {
  if (property.indexOf('.') !== -1) {
    return sortByNestedProperty (array, property, ascending);
  } else {
    // sorting works different if data is string, date or number
    if (typeof array[0][property] === "string") {
      const isDate = moment(array[0][property], "MM/DD/YYYY", true).isValid();

      return isDate 
        ? sortDates(array, property, ascending) 
        : sortStrings(array, property, ascending);
    } else {
      return traditionalBubleSorting(array, property, ascending);
    }
  }
}

/**
 * @param {any[]} array
 * @param {string | number} property
 * @param {boolean} ascending
 */
function traditionalBubleSorting (array, property, ascending) {
  return array.sort((a, b) => (
    (a[property] < b[property]) ? ((ascending) ? -1 : 1) :
    (a[property] > b[property]) ? ((ascending) ? 1 : -1) : 0
  ));
}

/**
 * @param {any[]} array
 * @param {string | number} property
 * @param {boolean} ascending
 */
function sortStrings (array, property, ascending) {
  // for strings, all to uppercase (to have uppers and lowers together, Aa, Bb, Zz)
  return array.sort((a, b) => (
    (upperCase(a[property]) < upperCase(b[property])) ? ((ascending) ? -1 : 1) :
    (upperCase(a[property]) > upperCase(b[property])) ? ((ascending) ? 1 : -1) : 0
  ));
}

/**
 * @param {any[]} array
 * @param {string | number} property
 * @param {boolean} ascending
 */
function sortDates (array, property, ascending) {
  // for dates... format to YYYYMMDD
  return array.sort((a, b) => {
    a = moment(new Date(a[property])).format('YYYYMMDD');
    b = moment(new Date(b[property])).format('YYYYMMDD');
    return (a < b) ? (ascending ? -1 : 1) :
           (a > b) ? (ascending ? 1 : -1) : 0;
  });
}

/**
 * @param {any[]} array
 * @param {string} property
 * @param {boolean} ascending
 */
function sortByNestedProperty (array, property, ascending) {
  const list = property.split ('.');
  return array.sort ((a, b) => {
    const aa = list.reduce ((current, name) => current[name], a);
    const bb = list.reduce ((current, name) => current[name], b);
    return ((aa < bb) ? ((ascending) ? -1 : 1) : (aa > bb) ? ((ascending) ? 1 : -1) : 0);
  });
}

/**
 * @param {any[]} array
 * @param {any[]} properties
 * @param {any[]} ascendings
 */
export function sortByProperties (array, properties, ascendings = []) {
  if (properties.reduce ((result, current) => (result || current.indexOf ('.') !== -1), false)) {
    return sortByNestedProperties (array, properties, ascendings);
  } else {
    return array.sort ((a, b) => {
      let order = 0;
      let index = 0;
      while ((order === 0) && (index < properties.length)) {
        const property = properties[index];
        const ascending = (typeof ascendings[index] === 'undefined') ? true : ascendings[index];
        order = ((a[property] < b[property]) ? ((ascending) ? -1 : 1) :
          (a[property] > b[property]) ? ((ascending) ? 1 : -1) : 0);
        index += 1;
      }
      return order;
    });
  }
}

/**
 * @param {any[]} array
 * @param {any[]} properties
 * @param {any[]} ascendings
 */
function sortByNestedProperties (array, properties, ascendings) {
  const list = properties.map ((a) => a.split ('.'));
  return array.sort ((a, b) => {
    let order = 0;
    let index = 0;
    while ((order === 0) && (index < properties.length)) {
      const aa = list[index].reduce ((current, name) => current[name], a);
      const bb = list[index].reduce ((current, name) => current[name], b);
      const ascending = (typeof ascendings[index] === 'undefined') ? true : ascendings[index];
      order = ((aa < bb) ? ((ascending) ? -1 : 1) : (aa > bb) ? ((ascending) ? 1 : -1) : 0);
      index += 1;
    }
    return order;
  });
}
