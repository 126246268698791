// WARNING: PLEASE UPDATE THE BACKEND ENUM WHENEVER THIS FILE IS UPDATED !!!

export enum TaxStatusId {
  '501(c)(3)' = 1,
  '501(c)(4)' = 2,
  'Other' = 3,
  'Federally Recognized Tribe' = 4,
  'For-profit' = 5
}

// This converts tax_status_id into the respective string equivalent
export type TaxStatusDef = {
  [key in TaxStatusId]: string;
};

export const TaxStatusOptions = Object.entries(TaxStatusId)
  .filter(([key]) => isNaN(Number(key)))
  .map(([label, value]) => ({ value: value as number, label }));

export const TaxStatusObj: TaxStatusDef = Object.entries(TaxStatusId)
  .filter(([key, value]) => isNaN(Number(key)))
  .reduce((acc: any, [label, value]) => {
    return { ...acc, [value]: label };
  }, {});

// Grantee Tax Statuses

export interface IStatuses {
  '501(c)(3)': boolean;
  '501(c)(4)': boolean;
  Other: boolean;
  'Federally Recognized Tribe': boolean;
  'For-profit': boolean;
}

export const GranteeDefTaxStatus: IStatuses = Object.entries(TaxStatusId)
  .filter(([key]) => isNaN(Number(key)))
  .reduce((acc: any, [label]) => {
    return { ...acc, [label]: false };
  }, {});
