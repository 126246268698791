import { useHistory } from 'react-router-dom';
export const MobileLogo = (props) => {
  const history = useHistory ();
  
  return (
    <div>
      <img
        src={ props.img }
        height='36px'
        width='auto'
        alt='JustFund logo'
        style={{ cursor: 'pointer', marginTop: '0.5em', marginLeft: '1em'  }}
        onClick={() => { history.push (props.to); }}
      />
    </div>
  );

}
