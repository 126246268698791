import { useEffect, useState } from 'react';
import { useGranteeContext } from '../../group/providers/UserProvider';
import { ProposalAPIService } from '../services/api-service/proposal-api.service';
import { RfpService } from '../services/api-service/rfp-api.service';
import { useParams } from 'react-router';
import { UserService } from '../services/api-service/user-api.service';
import { FunderService } from '../services/api-service/funder-api.service';
import { api } from '../services/api-service/api.service';
import { GranteeAPIService } from "../services/api-service/grantee.service";
import { UserAuthService } from '../services/api-service/user-auth-api.service';
import { AdminAPIService } from '../services/api-service/admin-api.service';

export enum UseFetchApiRequests {
  manageUsers = "manageUsers",
  manageGrantCycles = "manageGrantCycles",
  manageRfps = "manageRfps",
  getOneRfp = "getOneRfp",
  manageProposals = "manageProposals",
  getFunders = "getFunders",
  getFunderMembers = 'getFunderMembers',
  getOneFunder = "getOneFunder",
  getOneProposal = "getOneProposal",
  getOneGrantee = "getOneGrantee",
  getOneUser = "getOneUser",
  getGrantCycleList = "getGrantCycleList"
}

export interface IUseFetch {
  data: any;
  error: any;
  loading: boolean;
}

export const useFetch = (url: string, params: { [key: string]: string | number | string[] | number[] } | null): IUseFetch => {
  const [data, setData] = useState();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(true);

  const granteeId = UserAuthService.getEntity().entityId;
  const granteeContext = useGranteeContext();

  const { id } = useParams() as Record<string, string>;
  const apiCall = (url: string): Promise<any> => {
    let promise;

    switch (url) {
      case `/api/proposals/?grantee_id=${id}`:
        promise = ProposalAPIService.getByGrantee(granteeContext?.grantee?.id).then((proposal) => proposal);
        break;
      case UseFetchApiRequests.manageUsers:
        promise = UserService.list().then(({ rows }) => rows);
        break;
      case UseFetchApiRequests.manageRfps:
        promise = RfpService.list().then(({ rows }) => rows);
        break;
      case UseFetchApiRequests.getOneRfp:
        promise = id ? RfpService.getById(parseInt(id, 10)) : Promise.resolve(null);
        break;
      case UseFetchApiRequests.manageProposals:
        promise = ProposalAPIService.list(parseInt(granteeId!, 10)).then((proposals) => proposals);
        break;
      case UseFetchApiRequests.getOneProposal:
        promise = ProposalAPIService.getById(parseInt(id, 10)).then((proposal) => proposal);
        break;
      case UseFetchApiRequests.getFunders:
        promise = FunderService.discover().then((funder) => funder);
        break;
      case UseFetchApiRequests.getOneFunder:
        promise = FunderService.getById(parseInt(id, 10)).then((funder) => funder);
        break;
      case UseFetchApiRequests.getOneUser:
        promise = UserService.getById(parseInt(id, 10)).then((user) => user);
        break;
      case UseFetchApiRequests.getOneGrantee:
        promise = GranteeAPIService.getById(parseInt(id || granteeId!, 10)).then((grantee) => grantee);
        break;
      case UseFetchApiRequests.getGrantCycleList:
        promise = AdminAPIService.getGrantCycleList();
        break;
    }
    if (!promise) return new Promise((resolve, reject) => reject(`Case not defined: ${url}`));
    return promise;
  }
  // signal: AbortSignal
  const loadData = (signal: AbortSignal) => {
    // const abortController = new AbortController();
    // const signal = abortController.signal;
    if (!url) return;
    if (Object.values(UseFetchApiRequests).includes(url as UseFetchApiRequests)) {
      apiCall(url).then((data) => {
        if (data) setData(data);
        else setError('Nothing Found')
      }).catch((err) => setError(err)).finally(() => setLoading(false));
    } else {
      api.get(url, { params, signal })
        .then(({ data }) => {
          if (data) setData(data);
          else setError('Nothing Found');
        })
        .then(() => setLoading(false))
        .catch(err => {
          setLoading(false);
          if (err.message !== 'canceled') setError(err);
        });
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    loadData(abortController.signal);
    return () => {
      abortController.abort();
    }
  }, [url, params]);

  return {
    loading,
    data,
    error
  }
}
