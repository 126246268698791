import React from 'react';
import { Tooltip as TooltipCore, styled } from '@mui/material';

const StyledTooltip = styled(TooltipCore)({
  arrow: {
    color: '#29757e',
  },
  tooltip: {
    backgroundColor: '#29757e',
    color: '#ffffff',
    fontSize: '12px',
  },
});

export const Tooltip = ({ children, ...rest }: any) => (
  <StyledTooltip placement='top' arrow {...rest}>
    {children}
  </StyledTooltip>
);
