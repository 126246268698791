import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ScrollToTop } from '../../../shared/util/scrollToTop';
import { JFForgotPassword, RequiredResetPassword, SignIn } from '../signin';
import { NewPassword } from '../signin/NewPasswordForm';
import { Info } from '../info';
import { PrivacyNotice, TermsOfUse } from '../notices';
import { Nav } from './Nav';
import { Footer } from '../../../shared/components/Footer';
import { jfTheme } from '../../../styles/jf.theme';
import { DynamicRedirect } from '../redirect/DynamicRedirect';
import UnsubscribeEmailPage from '../unsubscribe-email/UnsubscribeEmailPage';
import { DialogProvider } from '../../../shared/context/GeneralDialog.context';



const theme = jfTheme;

export const App = () => {
  const location = useLocation();

  const routesWithoutFooterAndSignIn = ['/justfund/unsubscribe-email'];

  const shouldShowFooter = !routesWithoutFooterAndSignIn.includes(location.pathname);

  return (
    <StyledEngineProvider injectFirst>
  
      <DialogProvider>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <CssBaseline />
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Nav showSignIn={false} />
            <Box flexGrow={1} flexShrink={1}>
              <Switch>
                <Route exact path="/" component={SignIn} />
                <Route exact path="/:slug" component={DynamicRedirect} />
                <Route exact path="/justfund/howitworks" component={Info} />
                <Route exact path="/justfund/signin" component={SignIn} />
                <Route
                  exact
                  path="/justfund/forgot-password"
                  component={JFForgotPassword}
                />
                <Route
                  exact
                  path="/justfund/password/:resetType/:token"
                  component={NewPassword}
                />
                <Route
                  exact
                  path="/justfund/reset-password-required/:id"
                  component={RequiredResetPassword}
                />
                <Route exact path="/justfund/terms" component={TermsOfUse} />
                <Route
                  exact
                  path="/justfund/privacy"
                  component={PrivacyNotice}
                />
                <Route
                  exact
                  path="/justfund/unsubscribe-email"
                  component={UnsubscribeEmailPage}
                />
              </Switch>
            </Box>

            {shouldShowFooter && <Footer />}
          </Box>
        </ThemeProvider>
      </DialogProvider>
    </StyledEngineProvider>
  );
};
