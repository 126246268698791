import { Box, SxProps, Theme, Typography } from '@mui/material';
import JFCircularProgress from '../../../common/components/JFCircularProgress/JFCircularProgress.component';

export const Loading = ({ sx }: { sx?: SxProps<Theme> }) => (
  <Box
    sx={{
      textAlign: 'center',
      m: '140px auto 0 auto',
      display: 'grid',
      justifyItems: 'center',
      ...sx
    }}
  >
    <Typography paragraph>Loading...</Typography>
    <JFCircularProgress />
  </Box>
);
