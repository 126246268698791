import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface props {
  message: string;
  error?: Boolean;
  className?: string;
}

export const LoadingPage = ({ message, error = false, className }: props) => (
  <Box textAlign='center' m='140px auto 0 auto' maxWidth='500px' className={className}>
    <Typography color='primary' style={{ fontSize: '2rem' }}>JustFund</Typography>
    <Box height='20px' />
    <Typography paragraph>{message}</Typography>
    { !error && (
      <Box mt='40px'>
        <CircularProgress />
      </Box>
    )}
  </Box>
);
