import { Grid, IconButton } from '@mui/material'
import { BackButtonTitle, BackIcon } from './styles'
import { useHistory } from 'react-router';

export const BackToProfileButton = ({ funderId }: { funderId: string }) => {

  const history = useHistory();

  const backToProfile = () => {
    if (funderId) {
      history.push(`/f/funder-profile/${funderId}`);
    }
    else {
      history.goBack();
    }
  }

  return <Grid item xs={12} mb={1}>
    <Grid item container alignItems="center" onClick={() => backToProfile()}>
      <Grid item>
        <IconButton
          size="small"
        >
          <BackIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item>
        <BackButtonTitle>Back to profile</BackButtonTitle>
      </Grid>
    </Grid>
  </Grid>
}