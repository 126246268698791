import * as React from "react";

function SvgLgbtqSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="lgbtq-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#lgbtq-small_svg__a)">
        <circle cx="12" cy="12" r="10" fill="white" />
        <g data-name="Group 3" fill="#bf3088">
          <path
            data-name="Path 35"
            d="M12.047 9.185a2.575 2.575 0 102.53 2.62 2.58 2.58 0 00-2.53-2.62z"
          />
          <path
            data-name="Path 36"
            d="M12.209.002A12 12 0 1023.998 12.21 12 12 0 0012.209.002zm5.063 9.112a.432.432 0 01-.863-.015l.017-.964-1.655 1.6a3.435 3.435 0 01-2.4 5.45l-.021 1.254.85.015a.431.431 0 11-.015.862l-.851-.015-.014.829a.431.431 0 11-.862-.016l.014-.828-.85-.014a.432.432 0 11.015-.863l.85.015.022-1.254A3.425 3.425 0 019.33 9.616l-.024.023-.368-.38-.14.135a.374.374 0 01-.52-.539l.14-.135-.709-.734-.017.964a.431.431 0 01-.862-.015l.035-2.008a.431.431 0 01.439-.424l2.008.036a.431.431 0 01-.015.862l-.965-.017.71.734.113-.11a.374.374 0 11.52.539l-.113.11.366.38a3.416 3.416 0 014.24.075l1.655-1.6-.964-.017a.432.432 0 01.015-.863l2.008.036a.431.431 0 01.424.438z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLgbtqSmall;
