declare global {
  interface Window {
    hj: Function
  }
}

interface userAttributes {
  email?: string,
  user_type_grantee?: boolean,
  user_type_funder?: boolean
}

class HotjarService {

  private _userId: number | null = null;

  constructor(private hotjarClient = window.hj!) {}

  private updateUserAttributes(attributes: userAttributes): void {
    this.hotjarClient('identify', this._userId, attributes);
  }

  public set userId(id: number) {
    this._userId = id;
  }

  public set userEmail(email: string) {
    this.updateUserAttributes({ email });
  }

  public set hasGranteeAssociations(hasGranteeAssociations: boolean) {
    this.updateUserAttributes({ user_type_grantee: hasGranteeAssociations });
  }

  public set hasFunderAssociations(hasFunderAssociations: boolean) {
    this.updateUserAttributes({ user_type_funder: hasFunderAssociations })
  }
}

export const hotjarService = new HotjarService();