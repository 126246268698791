import * as React from "react";

function SvgEconomicSmall(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <clipPath id="economic-small_svg__a">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#economic-small_svg__a)">
        <g data-name="Layer 2">
          <circle cx="12" cy="12" r="10" fill="white" />
          <g data-name="Layer 1" fill="#62ce9c">
            <path
              data-name="Path 15"
              d="M12.35 11.58h-.7a.66.66 0 01-.66-.66.68.68 0 01.66-.66H13a.46.46 0 000-.91h-.59v-.49a.46.46 0 00-.91 0v.49a1.54 1.54 0 00.07 3.07h.77a.66.66 0 01.66.66.68.68 0 01-.66.66H11a.46.46 0 000 .91h.59v.49a.46.46 0 00.91 0v-.49a1.53 1.53 0 001.5-1.53 1.63 1.63 0 00-1.65-1.54z"
            />
            <path
              data-name="Path 16"
              d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 17.58A5.58 5.58 0 1117.58 12 5.58 5.58 0 0112 17.58z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEconomicSmall;
